import {pickUsingStringAsIndex} from '@lib/utils';
import {MapViewUser} from 'src/components/molecules/MapView/types';
import {DefaultTheme} from 'styled-components';

export const toMapViewUser = (user: {email: string; initials: string; imageUrl?: string}, theme: DefaultTheme) => ({
  color: pickUsingStringAsIndex(user.email, theme.avatar.colors),
  initials: user.initials,
  picture: user.imageUrl ?? '',
});

export const mapToMapViewUser = (
  users: {email: string; initials: string; imageUrl?: string}[],
  theme: DefaultTheme,
): MapViewUser[] => {
  return users.map((u) => toMapViewUser(u, theme));
};
