import {createContext, useState} from 'react';
import {FilterGroupProviderProps, FiltersGroupContextProps} from './types';
import {FilterType, Sections} from '@organisms';
import {HaNRoomsCardFiltersType} from 'src/components/organisms/hereAndNow/HaNRoomsCardFilters/types';
import {CreateEventManualRoomSelectionCardFilterType} from 'src/components/organisms/createEvent/CreateEventManualRoomSelectionCard/types';

const initFiltersGroup: FiltersGroupContextProps<any> = {
  filters: [],
  isLoading: false,
  setFilters: () => void 0,
  setIsLoading: () => void 0,
  workspaceSections: {},
  setWorkspaceSections: () => void 0,
};

export const FiltersGroupContext = createContext(initFiltersGroup);

export const FilterGroupProvider = ({children}: FilterGroupProviderProps) => {
  const [filters, setFilters] = useState<
    FilterType<CreateEventManualRoomSelectionCardFilterType | HaNRoomsCardFiltersType>[]
  >([]);

  const [workspaceSections, setWorkspaceSections] = useState<Sections>({});

  const [isLoading, setIsLoading] = useState(false);

  return (
    <FiltersGroupContext.Provider
      value={{
        filters,
        setFilters,
        isLoading,
        setIsLoading,
        workspaceSections,
        setWorkspaceSections,
      }}>
      {children}
    </FiltersGroupContext.Provider>
  );
};
