import {useAppSelector} from '@hooks';
import {
  Area,
  getBuildingById,
  getFloorById,
  getFloorHasMap,
  getConnectedUsersByDate,
  getSensorLastUpdatedText,
  getAreaSensorInfo,
  getAreaById,
  getNodeInformationByNodeId,
  getWorkspaceOccupancyForArea,
} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {StyledParagraph, StyledSubtitle, StyledUserCell, WorkspaceInformationWrapper} from './styles';
import {HaNModalHeader} from '@organisms';
import {StyledBody} from '../HaNRoomInformation/styles';
import {CapacityIndicator, MapView} from '@molecules';
import {createState, getDeskAmenityIcon, pxToRem} from '@utils';
import {HaNWorkspaceIcon} from '@organisms';
import {useMemo} from 'react';
import {FlexCol, FlexRow, H1, H2, H4, P, Subtitle} from '@quarks';
import {Icon} from '@atoms';

type HaNWorkspaceInformationProps = {
  onClose: () => void;
  workspace: Area;
};

export const HaNWorkspaceInformation = ({workspace, onClose}: HaNWorkspaceInformationProps) => {
  const {t} = useTranslation();
  const date = new Date();
  const floorHasMap = useAppSelector(getFloorHasMap(workspace.floorId));
  const {name, buildingId, floorId, capacity, activityTypes, deskAmenities, id: areaId} = workspace;
  const selectedAreaState = useMemo(() => createState(workspace ? [workspace.id] : [], 'selected'), [workspace]);
  const area = useAppSelector((state) => getAreaById(state, areaId))!;
  const building = useAppSelector((state) => getBuildingById(state, buildingId));
  const floor = useAppSelector((state) => getFloorById(state, floorId));
  const connections = useAppSelector((state) =>
    getConnectedUsersByDate(state, {date: date.toISOString(), nodeId: areaId}),
  );
  const selectorProps = useMemo(() => ({nodeId: areaId, date: new Date()}), [areaId]);
  const areaInformation = useAppSelector((state) => getNodeInformationByNodeId(state, selectorProps));

  const sensorData = useAppSelector((state) => getWorkspaceOccupancyForArea(state, workspace.buildingId, workspace.id));

  const updateTimeLabelText = getSensorLastUpdatedText(t, sensorData?.stateTimestamp);
  const areaBusinessInfo = getAreaSensorInfo(t, area, sensorData);
  const deskFullyBooked = areaInformation.capacity - areaInformation.occupation <= 0;

  return (
    <WorkspaceInformationWrapper data-testid="HaNWorkspaceInformation-MainWrapper">
      <HaNModalHeader
        onClose={onClose}
        title={t('workplace:Area')}
      />
      <StyledBody as="section">
        {building?.name || floor?.name ? (
          <FlexCol
            gap={16}
            data-testid="HaNWorkspaceInfromation-BuildingAndFloorNameWrapper">
            <H1 as="h2">{name}</H1>
            <P>{[t('meeting:MeetingRoomSeat', {count: capacity}), building?.name, floor?.name].join(', ')}</P>
          </FlexCol>
        ) : null}
        <FlexCol gap={16}>
          <H2 as="h3">{t('screen:InviteesAvailability')}</H2>

          {areaBusinessInfo && (
            <FlexRow
              gap={16}
              alignItems="center"
              justifyContent="flex-start">
              <HaNWorkspaceIcon iconType={areaBusinessInfo.status} />
              <FlexCol>
                <H4>{areaBusinessInfo?.title}</H4>
                <Subtitle>{updateTimeLabelText}</Subtitle>
              </FlexCol>
            </FlexRow>
          )}

          <FlexRow
            gap={16}
            alignItems="center"
            justifyContent="flex-start">
            <HaNWorkspaceIcon iconType="desk" />
            <FlexCol>
              <H4>{t('screen:DeskDetail')}</H4>
              <FlexRow gap={8}>
                <CapacityIndicator
                  capacity={areaInformation.capacity}
                  occupation={areaInformation.occupation}
                  displayDescription={false}
                />
                <StyledSubtitle $alertState={deskFullyBooked}>
                  {!deskFullyBooked
                    ? `${areaInformation.capacity - areaInformation.occupation}/${areaInformation.capacity} ${t(
                        'workplace:WorkspaceGroupAvailable',
                      )}`
                    : `${t('translation:FullyBooked')}`}
                </StyledSubtitle>
              </FlexRow>
            </FlexCol>
          </FlexRow>
        </FlexCol>

        {activityTypes.length ? (
          <FlexCol
            gap={16}
            data-testid="HaNWorkspaceInformation-ActivityTypesWrapper">
            <H2 as="h3">{t('workplace:ActivityTypesFilterSectionTitle')}</H2>
            <StyledParagraph>
              {activityTypes.map((activity) => t(`activityTypes:activityType`, {context: activity})).join(', ')}
            </StyledParagraph>
          </FlexCol>
        ) : null}

        {deskAmenities.length ? (
          <FlexCol
            gap={16}
            data-testid="HaNWorkspaceInformation-DeskAmenitiesWrapper">
            <H2 as="h3">{t('deskAmenities:deskAmenity_plural')}</H2>
            <P>{t('workplace:DeskAmenities')}</P>
            {deskAmenities.map((amenity) => {
              return (
                <FlexRow
                  gap={8}
                  key={amenity}
                  alignItems="center"
                  justifyContent="flex-start">
                  <Icon
                    weight="thin"
                    size={pxToRem(24)}
                    icon={getDeskAmenityIcon(amenity)}></Icon>
                  <P>{t(`deskAmenities:deskAmenity`, {context: amenity})}</P>
                </FlexRow>
              );
            })}
          </FlexCol>
        ) : null}

        {floorHasMap ? (
          <FlexCol
            gap={16}
            display="none"
            sm={{display: 'flex'}}>
            <H2 as="h3">{t('translation:LocationTitle')}</H2>
            <MapView
              buildingId={buildingId}
              floorId={floorId}
              buildingNodeStates={selectedAreaState}
              borderRadius={8}
              fullView={false}
              highlights={[
                {
                  type: 'area',
                  nodeId: workspace.id,
                  available: workspace.capacity,
                  connections: [],
                },
              ]}
              disablePointerEvents={true}
              data-testid="HaNWorkspaceInformation-Map"
            />
          </FlexCol>
        ) : null}

        {connections.length ? (
          <FlexCol gap={16}>
            <H2 as="h3">{t('connection:ConnectionsWorkingHere')}</H2>
            {connections.map((connection) => (
              <StyledUserCell
                user={connection}
                key={JSON.stringify(connection)}
              />
            ))}
          </FlexCol>
        ) : null}
      </StyledBody>
    </WorkspaceInformationWrapper>
  );
};
