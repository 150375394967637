import {Area} from '@lib/store';
import {HaNWorkspaceInformation} from '@organisms';

type HaNWorkspaceModalProps = {
  onClose: () => void;
  workspace: Area;
};

export const HaNWorkspaceModal = ({onClose, workspace}: HaNWorkspaceModalProps) => {
  return (
    <>
      <HaNWorkspaceInformation
        onClose={onClose}
        workspace={workspace}
      />
    </>
  );
};
