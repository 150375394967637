import {useAppSelector} from '@hooks';
import {MapView, MapViewHighlight, NoMapPlaceholder} from '@molecules';
import {
  type Area,
  type Floor,
  type MeetingRoom,
  getFloorHasMap,
  getMarkerState,
  getMeetingRoomSuggestionDataEntities,
} from '@lib/store';
import {createState, mergeState} from '@utils';
import {RoomEventTarget} from '../../../../../../../submodules/map/mapiq-map/EventTarget';
import {useMemo} from 'react';
import {AbsoluteViewportRestriction} from '../../../../../../../submodules/map/mapiq-map/AbsoluteViewportRestriction';

type HaNRoomsMapProps = {
  rooms: MeetingRoom[];
  floorId: string | null;
  floorsWithMap: Floor[];
  buildingId: string;
  onRoomClick: (e: RoomEventTarget) => void;
  hoverRoom: MeetingRoom | null;
  selectedRoom: MeetingRoom | Area | null;
  viewportRestrictions?: AbsoluteViewportRestriction;
};

export const HaNRoomsMap = ({
  rooms,
  buildingId,
  floorId,
  hoverRoom,
  onRoomClick,
  viewportRestrictions,
  floorsWithMap,
  selectedRoom,
}: HaNRoomsMapProps) => {
  const floorHasMap = useAppSelector(getFloorHasMap(floorId));

  const roomSuggestionDataEntities = useAppSelector(getMeetingRoomSuggestionDataEntities);
  const showMap = floorHasMap && floorsWithMap.length && floorId && rooms.length;
  const highlights: MapViewHighlight[] = rooms
    .filter((room) => room.floorId === floorId)
    .map((room) => ({type: 'room', nodeId: room.id}));

  const highlightedRooms = useMemo(
    () =>
      rooms.filter(
        (room) =>
          room.id !== selectedRoom?.id &&
          room.suggestionData &&
          getMarkerState(roomSuggestionDataEntities[room.suggestionData]?.state) === 'highlighted',
      ),
    [rooms, roomSuggestionDataEntities, selectedRoom],
  );

  const dimmedRooms = useMemo(
    () =>
      rooms.filter(
        (room) =>
          room.suggestionData && getMarkerState(roomSuggestionDataEntities[room.suggestionData]?.state) === 'dimmed',
      ),
    [rooms, roomSuggestionDataEntities],
  );

  const availableRoomState = createState(
    highlightedRooms.filter((room) => room.id !== selectedRoom?.id).map((room) => room.id),
    'highlighted',
  );

  const dimmedRoomStates = createState(
    dimmedRooms.filter((room) => room.id !== selectedRoom?.id).map((room) => room.id),
    'dimmed',
  );

  const selectedRoomState = useMemo(
    () => createState(selectedRoom ? [selectedRoom.id] : [], 'selected'),
    [selectedRoom],
  );

  const allRooms = [dimmedRoomStates, availableRoomState, selectedRoomState].reduce(mergeState);

  const handleClick = (e: RoomEventTarget) => {
    if (e.type === 'room') {
      onRoomClick(e);
    }
  };

  const elementsToKeepInView = useMemo(() => (selectedRoom ? [selectedRoom.id] : undefined), [selectedRoom]);

  return showMap ? (
    <MapView
      borderRadius={0}
      floorId={floorId}
      buildingId={buildingId}
      highlights={highlights}
      fullView={true}
      elementsToKeepInView={elementsToKeepInView}
      disablePointerEvents={false}
      buildingNodeStates={allRooms}
      viewportRestrictions={viewportRestrictions}
      onClick={(e) => handleClick(e as RoomEventTarget)}
      spotlightId={hoverRoom?.floorId === floorId ? hoverRoom.id : null}
    />
  ) : (
    <NoMapPlaceholder
      floorId={floorId}
      floorsWithMap={floorsWithMap}
      arrayLength={rooms.length}
    />
  );
};
