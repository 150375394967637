import {MapView, NoMapPlaceholder} from '@molecules';
import {HereAndNowWorkspacesMapProps} from './types';
import {useAppSelector} from '@hooks';
import {getFloorHasMap, getWorkspaceOccupancyForBuildingAndFloorId} from '@lib/store';
import {useMemo} from 'react';
import {createState, mergeState} from '@utils';
import {AreaEventTarget} from '../../../../../../../submodules/map/mapiq-map/EventTarget';
import {createSensorHighlightsForAreas} from '@lib/utils';
import {AreaMarkerData} from '../../../../../../../submodules/map/mapiq-map/markers/NodeMarkerData';

export const HaNWorkspacesMap = ({
  // TODO: why is floorId nullable?
  floorId,
  floorsWithMap,
  viewportRestrictions,
  buildingId,
  onAreaClick,
  selectedArea,
  hoverArea,
  workspaces,
}: HereAndNowWorkspacesMapProps) => {
  const floorHasMap = useAppSelector(getFloorHasMap(floorId));

  // Sensor highlights might not be available if (a) FF is turned off, or (b) there are just not any sensors
  const sensorData = useAppSelector((state) => getWorkspaceOccupancyForBuildingAndFloorId(state, buildingId, floorId));

  // TODO: shouldn't this happen one layer up?
  const workspacesOnFloor = useMemo(() => workspaces.filter((a) => a.floorId === floorId), [floorId, workspaces]);

  const sensorHighlights = useMemo(() => {
    if (sensorData?.status !== 'loaded') return [];

    return createSensorHighlightsForAreas(workspacesOnFloor, sensorData.states);
  }, [sensorData, workspacesOnFloor]);

  // Area highlights are used if no sensors are found on the floor
  const areaHighlights: AreaMarkerData[] = useMemo(
    () =>
      workspacesOnFloor.map((area) => ({
        type: 'area',
        nodeId: area.id,
        available: area.capacity,
        connections: [],
      })),
    [workspacesOnFloor],
  );

  // When the sensor data is loaded but there's nothing with a state, we fall back to just regular area markers.
  const doNotShowSensorData =
    sensorData?.status === 'failed' || (sensorData?.status === 'loaded' && sensorData.states.length === 0);
  const highlights = doNotShowSensorData ? areaHighlights : sensorHighlights;

  const showMap = floorHasMap && floorsWithMap.length !== 0 && floorId && workspaces.length !== 0;

  const areaStates = useMemo(() => {
    const highlightState = createState(
      workspacesOnFloor.map((a) => a.id),
      'highlighted',
    );

    const selectionState = createState(selectedArea ? [selectedArea.id] : [], 'selected');

    return mergeState(highlightState, selectionState);
  }, [workspacesOnFloor, selectedArea]);

  const handleClick = (e: AreaEventTarget) => {
    if (e.areaId) {
      onAreaClick(e);
    }
  };

  const elementsToKeepInView = useMemo(() => (selectedArea ? [selectedArea.id] : undefined), [selectedArea]);

  return showMap ? (
    <MapView
      borderRadius={0}
      floorId={floorId}
      buildingId={buildingId}
      highlights={highlights}
      fullView={true}
      elementsToKeepInView={elementsToKeepInView}
      disablePointerEvents={false}
      buildingNodeStates={areaStates}
      viewportRestrictions={viewportRestrictions}
      onClick={(e) => handleClick(e as AreaEventTarget)}
      spotlightId={hoverArea?.floorId === floorId ? hoverArea.id : null}
    />
  ) : (
    <NoMapPlaceholder
      floorId={floorId}
      floorsWithMap={floorsWithMap}
      arrayLength={workspaces.length}
    />
  );
};
