import {BREAKPOINTS} from '@constants';
import {Button, Chip} from '@molecules';
import styled from 'styled-components';

export const StyledChip = styled(Chip)`
  max-width: calc(50% - 4px);

  ${BREAKPOINTS.small`
    max-width: 100%;
  `}
`;

export const StyledButton = styled(Button)`
  flex-shrink: 0;
`;
