import {FilterGroupProvider} from './Filters';
import {ContextsProps} from './types';

import {DialogProvider, ModalProvider, ThemeProvider, SidePanelProvider, GlobalImageCacheProvider} from '@contexts';

export const Contexts = ({children}: ContextsProps) => {
  return (
    <ThemeProvider>
      <GlobalImageCacheProvider>
        <DialogProvider>
          <ModalProvider>
            <SidePanelProvider>
              <FilterGroupProvider>{children}</FilterGroupProvider>
            </SidePanelProvider>
          </ModalProvider>
        </DialogProvider>
      </GlobalImageCacheProvider>
    </ThemeProvider>
  );
};
