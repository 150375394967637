import {AppDispatch} from '@lib/store';
import {INotificationService} from '@lib/infrastructure';

export const notificationService = (): INotificationService<AppDispatch> => ({
  init: (_dispatch, _localStorage) => {},
  checkPermissions: () => {
    return Promise.resolve({alert: true, badge: true, sound: true});
  },
  requestPermissions: () => {
    return Promise.resolve({alert: true, badge: true, sound: true});
  },
  cancelAll: () => {
    return;
  },
  abandonPermissions: () => {
    return;
  },
  setApplicationBadgeNumber: (_badgeNumber: number) => {
    return;
  },
  removeAllDeliveredNotifications: () => {
    return;
  },
});
