import styled from 'styled-components';
import {FlexCol} from '@quarks';
import {StyledMapViewProps} from './types';
import {BREAKPOINTS} from '@constants';

export const StyledMapView = styled(FlexCol)<StyledMapViewProps>`
  position: relative;
  height: ${({$fullView}) => ($fullView ? '100%' : '300px')};
  width: 100%;
  border-radius: ${({$borderRadius}) => $borderRadius && `${$borderRadius}px`};
  overflow: hidden;

  * {
    pointer-events: ${({$disablePointerEvents}) => $disablePointerEvents && 'none !important'};
  }

  .Toggle2d3d * {
    pointer-events: all !important;
  }

  .Toggle2d3d {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 0.5rem;
    padding: 2%;
    font-size: 14px;
    accent-color: black;
  }

  .Toggle2d3d > label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  background: ${({$hasError, theme}) => ($hasError ? theme.card.red.background : 'transparent')};

  ${BREAKPOINTS.small`
    height: ${({$fullView}) => ($fullView ? '100%' : '200px')}
  `}
`;

export const StyledMapWrapper = styled.div`
  height: 100%;
  width: 100%;
  align-self: stretch;
`;
