import {createContext, useRef, useState} from 'react';
import {GlobalImageCacheContextProps, GlobalImageCacheProviderProps, ImageCache, ImageCachePromise} from './types';

export const GlobalImageCacheContext = createContext<GlobalImageCacheContextProps>(null!);

export const GlobalImageCacheProvider = ({children}: GlobalImageCacheProviderProps) => {
  const globalImageCacheRef = useRef<ImageCache>({});
  const [_, setInternalForceUpdate] = useState(0);

  const getGlobalImageCachePromise = (key: string) => globalImageCacheRef.current[key]?.promise ?? null;

  const getGlobalImageCacheUrl = (key: string) => globalImageCacheRef.current[key]?.blobURL ?? '';

  const setGlobalImageCachePromise = (key: string, promise: ImageCachePromise) => {
    const currentPromise = globalImageCacheRef.current[key]?.promise;

    if (!!currentPromise) return currentPromise;

    Object.assign(globalImageCacheRef.current, {
      [key]: {
        ...(globalImageCacheRef.current[key] ?? {blobURL: ''}),
        promise,
      },
    });

    return globalImageCacheRef.current[key].promise.then((value) => {
      setGlobalImageCacheUrl(key, value ?? '');

      // React Black magic. Not sure why, but it seems that updating a state triggers rerenders for the components that make use of the values of globalImageCacheRef
      setInternalForceUpdate((p) => p + 1);
      return value;
    });
  };

  const setGlobalImageCacheUrl = (key: string, blobURL: string) => {
    if (globalImageCacheRef.current[key]?.blobURL !== blobURL) {
      Object.assign(globalImageCacheRef.current, {
        [key]: {
          ...globalImageCacheRef.current[key],
          blobURL,
        },
      });
    }
  };

  return (
    <GlobalImageCacheContext.Provider
      value={{
        setGlobalImageCachePromise,
        getGlobalImageCachePromise,
        getGlobalImageCacheUrl,
      }}>
      {children}
    </GlobalImageCacheContext.Provider>
  );
};
