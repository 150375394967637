import {useAppSelector, useModal} from '@hooks';
import {getAreaById, getFloorById, getBuildingById} from '@lib/store';
import {IconNames, TwoColumnCell} from '@atoms';
import {ModalCellItemIcon, ModalCell} from '@molecules';
import {MapSelectorCard} from '@organisms';
import {FlexCol, Span, Subtitle} from '@quarks';
import {useTranslation} from 'react-i18next';
import {BookingDetailsCardProps} from './types';
import {pxToRem} from '@utils';
import {StyledModalPage} from './styles';

export const MapDetailsModalPage = ({date, buildingId, areaId, floorId, groupSize}: BookingDetailsCardProps) => {
  const {t} = useTranslation();
  const {closeModal, modalPages, removePages} = useModal();

  const area = useAppSelector((state) => areaId && getAreaById(state, areaId)) || null;
  const floor = useAppSelector((state) => floorId && getFloorById(state, floorId)) || null;
  const building = useAppSelector((state) => getBuildingById(state, buildingId));
  const buildingName = building?.name || '';

  const title = area?.name || floor?.name || buildingName;
  const getSubtitle = () => {
    const _subtitle = area ? `${buildingName}, ${floor?.name}` : floor ? buildingName : '';

    if (area?.hasBookableDesks) {
      return `${_subtitle}, ${t('workplace:DeskWillBeAssigned', {
        count: groupSize,
      }).toLowerCase()}`;
    }

    const selectAvailableDesk = t('workplace:AnyAvailableDesk', {count: groupSize});
    return `${_subtitle}, ${selectAvailableDesk.toLowerCase()}`;
  };

  const icon: IconNames = area?.hasBookableDesks ? 'desk' : areaId ? 'area' : 'floor';

  return (
    <StyledModalPage
      title={t('screen:DeskMap')}
      onBack={modalPages.length > 1 ? () => removePages(1) : undefined}
      onClose={closeModal}>
      <FlexCol
        gap={16}
        height={'100%'}>
        <MapSelectorCard
          date={date}
          buildingId={buildingId}
          floorId={floorId}
          areaId={areaId}
          /* TODO: send users */
          users={[]}
          groupSize={groupSize}
        />
        <ModalCell>
          <TwoColumnCell
            padding="small"
            alignItems="flex-start"
            separator={false}
            left={
              <ModalCellItemIcon
                // backgroundColor={theme.label.blue.background}
                icon={icon}
              />
            }
            right={
              <FlexCol minHeight={pxToRem(48)}>
                <Span fontWeight="bold">{title}</Span>
                <Subtitle>{getSubtitle()}</Subtitle>
              </FlexCol>
            }
          />
        </ModalCell>
      </FlexCol>
    </StyledModalPage>
  );
};
