import {createPortal} from 'react-dom';
import {config, useTransition} from '@react-spring/web';

import {HaNModalProps} from './types';
import FocusTrap from 'focus-trap-react';
import {useWindowSize} from '@hooks';
import {BREAKPOINT_SIZES} from '@constants';
import {HaNRoomModal, HaNWorkspaceModal, ModalBackground} from '@organisms';
import {ModalWrapper} from './styles';
import {isMeetingRoom} from '../helpers';
import {useEffect} from 'react';

const modalRoot = document.getElementById('modal-portal')!;

const focusTrapOptions = {
  allowOutsideClick: true,
  clickOutsideDeactivates: false,
  escapeDeactivates: false,
};

/**
 * Modal specificly designed for Here and Now.
 * Do not use elsewhere.
 */

export const HaNModalContainer = ({
  show,
  onClose,
  entity,
  endDateTime,
  startDateTime,
  setWarnBeforeOpeningNewRoom,
  onBookNowCallback,
  duration,
}: HaNModalProps) => {
  const {width} = useWindowSize();
  const from =
    width <= BREAKPOINT_SIZES.small
      ? {transform: 'translate3d(0, 100px, 0)', opacity: 0}
      : {transform: 'translate3d(-100px, 0, 0)', opacity: 0};
  const enter = {transform: 'translate3d(0px, 0px, 0)', opacity: 1};
  const transition = useTransition(show && entity, {
    from: from,
    enter: enter,
    leave: from,
    config: {...config.stiff, clamp: true},
    keys: (item) => `${item}-key`,
  });

  useEffect(
    function listenForEsc() {
      const listener = (e: KeyboardEvent) => {
        if (show && e.key === 'Escape') onClose?.();
      };

      window.addEventListener('keydown', listener);
      return () => window.removeEventListener('keydown', listener);
    },
    [show, onClose],
  );

  return createPortal(
    <>
      {width <= BREAKPOINT_SIZES.small && (
        <ModalBackground
          clickable={false}
          hideOnSmallScreen={false}
          open={show}
        />
      )}
      {transition(
        (style, item) =>
          item && (
            <FocusTrap focusTrapOptions={focusTrapOptions}>
              <ModalWrapper style={style}>
                {isMeetingRoom(item) ? (
                  <HaNRoomModal
                    key={item.id}
                    onClose={onClose}
                    room={item}
                    endDateTime={endDateTime}
                    startDateTime={startDateTime}
                    onBookNowCallback={onBookNowCallback}
                    duration={duration}
                    setWarnBeforeOpeningNewRoom={setWarnBeforeOpeningNewRoom}
                  />
                ) : (
                  <HaNWorkspaceModal
                    onClose={onClose}
                    workspace={item}
                  />
                )}
              </ModalWrapper>
            </FocusTrap>
          ),
      )}
    </>,
    modalRoot,
  );
};
