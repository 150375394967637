import {IconNames} from '@atoms';

export const getDeskAmenityIcon = (id: string) => {
  const iconsNames: Record<string, IconNames> = {
    desk: 'desk',
    monitor: 'monitor',
    dualMonitor: 'dualMonitor',
    mouse: 'mouse',
    keyboard: 'keyboard',
    webcam: 'webcam',
    laptop: 'laptop',
    chair: 'chair',
    plugs: 'plugs',
  };

  return (
    {
      AdjustableDesk: iconsNames.desk,
      Cubicle: iconsNames.desk,
      SingleMonitor: iconsNames.monitor,
      DualMonitor: iconsNames.dualMonitor,
      LargeMonitor: iconsNames.monitor,
      DockingStation: iconsNames.plugs,
      HDMIAdaptor: iconsNames.plugs,
      USBCAdaptor: iconsNames.plugs,
      ErgonomicChair: iconsNames.chair,
      Keyboard: iconsNames.keyboard,
      LaptopStand: iconsNames.laptop,
      Mouse: iconsNames.mouse,
      Webcam: iconsNames.webcam,
    }[id] || iconsNames.desk
  );
};
