import axios, {AxiosRequestConfig} from 'axios';
export type ImageApiVersion = '1.0' | '2.0';

export const getImage = async (source: string, token: string, apiVersion: ImageApiVersion = '1.0') => {
  const config: AxiosRequestConfig = {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
      'x-api-version': apiVersion,
    },
  };

  try {
    const response = await axios.get(source, config);
    const blob = await response.data;

    if (blob instanceof Blob) {
      const blobURL = URL.createObjectURL(blob);
      return blobURL;
    }
  } catch (e) {
    console.error('Error: ', e);
  }
};
