import {Illustration} from '@atoms';
import {NoMapContainer} from './styles';
import {P} from '@quarks';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '@hooks';
import {getFloorHasMap} from '@lib/store';
import {NoMapPlaceholderProps} from './types';

export const NoMapPlaceholder = ({floorId, floorsWithMap, arrayLength}: NoMapPlaceholderProps) => {
  const {t} = useTranslation();
  const floorHasMap = useAppSelector(getFloorHasMap(floorId));
  const noMapPresentMessage = !floorsWithMap.length
    ? t('hereAndNow:YourOfficeHasNoMap')
    : !arrayLength
    ? t('hereAndNow:NothingToShow')
    : !floorHasMap
    ? t('hereAndNow:NoMapForFloor')
    : '';

  return (
    <NoMapContainer>
      <Illustration illustration="map" />
      <P fontSize="md">{noMapPresentMessage}</P>
    </NoMapContainer>
  );
};
