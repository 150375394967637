import styled from 'styled-components';
import {ModalPage} from '../../ModalPage';

export const StyledModalPage = styled(ModalPage)`
  --modal-padding-x: 24px;
  --modal-padding-y: 24px;
  --padding-x: 24px;
  --padding-y: 24px;
  background: ${({theme}) => theme.background.white};

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid ${({theme}) => theme.brand};
  }
`;
