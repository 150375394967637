import {SensorIcon} from '@map/logic';
import {StyledContainer} from './style';
import {Icon} from '@atoms';

type HaNWorkspaceIcon = {
  iconType: SensorIcon | 'desk';
};

export const HaNWorkspaceIcon = ({iconType}: HaNWorkspaceIcon) => {
  const width = '17';
  const height = '11px';

  return (
    <StyledContainer $iconType={iconType}>
      {iconType === 'quiet' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 17 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3ZM1.5 4C0.671573 4 0 4.67157 0 5.5V11H3V5.5C3 4.67157 2.32843 4 1.5 4Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 3C6.82843 3 7.5 2.32843 7.5 1.5C7.5 0.671573 6.82843 0 6 0C5.17157 0 4.5 0.671573 4.5 1.5C4.5 2.32843 5.17157 3 6 3ZM6 4C5.17157 4 4.5 4.67157 4.5 5.5V11H7.5V5.5C7.5 4.67157 6.82843 4 6 4ZM10.5 4C9.67157 4 9 4.67157 9 5.5V11H12V5.5C12 4.67157 11.3284 4 10.5 4ZM13.5 5.5C13.5 4.67157 14.1716 4 15 4C15.8284 4 16.5 4.67157 16.5 5.5V11H13.5V5.5ZM12 1.5C12 2.32843 11.3284 3 10.5 3C9.67157 3 9 2.32843 9 1.5C9 0.671573 9.67157 0 10.5 0C11.3284 0 12 0.671573 12 1.5ZM15 3C15.8284 3 16.5 2.32843 16.5 1.5C16.5 0.671573 15.8284 0 15 0C14.1716 0 13.5 0.671573 13.5 1.5C13.5 2.32843 14.1716 3 15 3Z"
            fill="#9AC6BB"
          />
        </svg>
      ) : iconType === 'lively' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 17 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM1.5 4C0.671573 4 0 4.67157 0 5.5V11H3V5.5C3 4.67157 2.32843 4 1.5 4ZM6 4C5.17157 4 4.5 4.67157 4.5 5.5V11H7.5V5.5C7.5 4.67157 6.82843 4 6 4ZM6 3C6.82843 3 7.5 2.32843 7.5 1.5C7.5 0.671573 6.82843 0 6 0C5.17157 0 4.5 0.671573 4.5 1.5C4.5 2.32843 5.17157 3 6 3Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 1.5C12 2.32843 11.3284 3 10.5 3C9.67157 3 9 2.32843 9 1.5C9 0.671573 9.67157 0 10.5 0C11.3284 0 12 0.671573 12 1.5ZM10.5 4C9.67157 4 9 4.67157 9 5.5V11H12V5.5C12 4.67157 11.3284 4 10.5 4ZM15 4C14.1716 4 13.5 4.67157 13.5 5.5V11H16.5V5.5C16.5 4.67157 15.8284 4 15 4ZM15 3C15.8284 3 16.5 2.32843 16.5 1.5C16.5 0.671573 15.8284 0 15 0C14.1716 0 13.5 0.671573 13.5 1.5C13.5 2.32843 14.1716 3 15 3Z"
            fill="#F9E58D"
          />
        </svg>
      ) : iconType === 'full' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 17 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.5 5.5C4.5 4.67157 5.17157 4 6 4C6.82843 4 7.5 4.67157 7.5 5.5V11H4.5V5.5Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM0 5.5C0 4.67157 0.671573 4 1.5 4C2.32843 4 3 4.67157 3 5.5V11H0V5.5ZM9 5.5C9 4.67157 9.67157 4 10.5 4C11.3284 4 12 4.67157 12 5.5V11H9V5.5ZM15 4C14.1716 4 13.5 4.67157 13.5 5.5V11H16.5V5.5C16.5 4.67157 15.8284 4 15 4ZM6 3C6.82843 3 7.5 2.32843 7.5 1.5C7.5 0.671573 6.82843 0 6 0C5.17157 0 4.5 0.671573 4.5 1.5C4.5 2.32843 5.17157 3 6 3ZM12 1.5C12 2.32843 11.3284 3 10.5 3C9.67157 3 9 2.32843 9 1.5C9 0.671573 9.67157 0 10.5 0C11.3284 0 12 0.671573 12 1.5ZM15 3C15.8284 3 16.5 2.32843 16.5 1.5C16.5 0.671573 15.8284 0 15 0C14.1716 0 13.5 0.671573 13.5 1.5C13.5 2.32843 14.1716 3 15 3Z"
            fill="white"
          />
        </svg>
      ) : iconType === 'desk' ? (
        <Icon
          icon="desk"
          size="15px"
        />
      ) : iconType === 'busy' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 17 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3ZM1.5 4C0.671573 4 0 4.67157 0 5.5V11H3V5.5C3 4.67157 2.32843 4 1.5 4ZM6 4C5.17157 4 4.5 4.67157 4.5 5.5V11H7.5V5.5C7.5 4.67157 6.82843 4 6 4ZM9 5.5C9 4.67157 9.67157 4 10.5 4C11.3284 4 12 4.67157 12 5.5V11H9V5.5ZM7.5 1.5C7.5 2.32843 6.82843 3 6 3C5.17157 3 4.5 2.32843 4.5 1.5C4.5 0.671573 5.17157 0 6 0C6.82843 0 7.5 0.671573 7.5 1.5ZM10.5 3C11.3284 3 12 2.32843 12 1.5C12 0.671573 11.3284 0 10.5 0C9.67157 0 9 0.671573 9 1.5C9 2.32843 9.67157 3 10.5 3Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 3C15.8284 3 16.5 2.32843 16.5 1.5C16.5 0.671573 15.8284 0 15 0C14.1716 0 13.5 0.671573 13.5 1.5C13.5 2.32843 14.1716 3 15 3ZM15 4C14.1716 4 13.5 4.67157 13.5 5.5V11H16.5V5.5C16.5 4.67157 15.8284 4 15 4Z"
            fill="#EEAA6B"
          />
        </svg>
      ) : (
        <svg
          width={width}
          height={height}
          viewBox="0 0 17 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM1.5 4C0.671573 4 0 4.67157 0 5.5V11H3V5.5C3 4.67157 2.32843 4 1.5 4ZM6 4C5.17157 4 4.5 4.67157 4.5 5.5V11H7.5V5.5C7.5 4.67157 6.82843 4 6 4ZM9 5.5C9 4.67157 9.67157 4 10.5 4C11.3284 4 12 4.67157 12 5.5V11H9V5.5ZM15 4C14.1716 4 13.5 4.67157 13.5 5.5V11H16.5V5.5C16.5 4.67157 15.8284 4 15 4ZM6 3C6.82843 3 7.5 2.32843 7.5 1.5C7.5 0.671573 6.82843 0 6 0C5.17157 0 4.5 0.671573 4.5 1.5C4.5 2.32843 5.17157 3 6 3ZM12 1.5C12 2.32843 11.3284 3 10.5 3C9.67157 3 9 2.32843 9 1.5C9 0.671573 9.67157 0 10.5 0C11.3284 0 12 0.671573 12 1.5ZM15 3C15.8284 3 16.5 2.32843 16.5 1.5C16.5 0.671573 15.8284 0 15 0C14.1716 0 13.5 0.671573 13.5 1.5C13.5 2.32843 14.1716 3 15 3Z"
            fill="#B3B3B3"
          />
        </svg>
      )}
    </StyledContainer>
  );
};
