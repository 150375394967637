import {IconProps} from './types';
import {icons} from './icons';
import {StyledIcon} from './styles';
import {useTheme} from 'styled-components';

export const Icon = ({
  className,
  color,
  duotone = false,
  icon,
  size = '3rem',
  style,
  weight = 'light',
  ...props
}: IconProps) => {
  const theme = useTheme();

  return (
    <StyledIcon
      $size={size}
      aria-label={props['aria-label'] || icon}
      className={className}
      height="48px"
      role={props.role || 'img'}
      style={style}
      viewBox="0 0 24 24"
      width="48px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      {icons[icon]({$color: color || theme.text.color.body, $weight: weight, $duotone: duotone})}
    </StyledIcon>
  );
};
