import {CardProps} from '@atoms';
import {useTranslation} from 'react-i18next';
import {
  CalendarSyncCell,
  DefaultOfficeCell,
  LanguageCell,
  ThemeCell,
  EmailNotificationCell,
  WorkingHoursCell,
  LicensePlateCell,
} from '@organisms';
import {H2} from '@quarks';
import {CellWrapper, ProfileCard} from '../styles';

export const PreferencesCard = ({className, style, ...props}: CardProps) => {
  const {t} = useTranslation('profile');

  return (
    <ProfileCard
      as="section"
      className={className}
      style={style}
      {...props}>
      <H2>{t('Preferences')}</H2>
      <CellWrapper>
        <WorkingHoursCell />
        <DefaultOfficeCell />
        <LanguageCell />
        <LicensePlateCell />
        <ThemeCell />
        <CalendarSyncCell />
        <EmailNotificationCell />
      </CellWrapper>
    </ProfileCard>
  );
};
