import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const HeaderLayout = styled.header`
  align-items: stretch;
  display: flex;
  border-bottom: ${({theme}) => `1px solid ${theme.divider.grey}`};
  grid-area: header / fullbleed;
  justify-content: space-between;
  padding: 16px;

  ${BREAKPOINTS.small`
    display: none;
  `}
`;
