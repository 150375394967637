import {useTranslation} from 'react-i18next';
import {StyledButton} from './styles';
import {HaNWorkspacesFiltersProps} from './types';
import {StyledSwiperHorizontal} from '../HaNRoomListHorizontal/styles';

export const HaNWorkspacesFilters = ({areFiltersTransparent}: HaNWorkspacesFiltersProps) => {
  const {t} = useTranslation();

  return (
    <StyledSwiperHorizontal>
      <StyledButton
        $hasSolidBackground={!areFiltersTransparent}
        size="small"
        button="secondary"
        iconRight="filter"
        onClick={() => {}}>
        {t('workplace:Filter')}
      </StyledButton>
    </StyledSwiperHorizontal>
  );
};
