import {FlexRow} from '@quarks';
import {StyledFlexRow} from './styles';
import {useTranslation} from 'react-i18next';
import {ChipFilter} from '@molecules';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {ConnectionFilterModalPage} from '../../bookWorkspace';
import {getSelectedCalendarFilterConnections, removeSelectedCalendarConnectionFilterIds} from '@lib/store';

export const CalendarOverviewConnectionFilter = () => {
  const {t} = useTranslation();
  const {openModal, setModalPages} = useModal();
  const dispatch = useAppDispatch();

  const selectedConnections = useAppSelector(getSelectedCalendarFilterConnections);

  const handleClick = () => {
    setModalPages([<ConnectionFilterModalPage />]);
    openModal();
  };

  return (
    <StyledFlexRow>
      <FlexRow
        sm={{flexWrap: 'nowrap'}}
        flexWrap="wrap"
        justifyContent="flex-start"
        width="100%"
        gap={8}>
        <ChipFilter
          chips={selectedConnections.map((connection) => ({
            id: connection.id,
            label: connection.name,
            onDelete: () => dispatch(removeSelectedCalendarConnectionFilterIds([connection.id!])),
          }))}
          onClick={handleClick}
          buttonLabel={t('connection:Filter')}
        />
      </FlexRow>
    </StyledFlexRow>
  );
};
