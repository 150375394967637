import {useAppDispatch, useAppSelector, useUserProfilePics} from '@hooks';
import {
  Desk,
  DeskBookingStatus,
  ReservationMember,
  getAreaById,
  getFacilitiesByFloorId,
  getWorkspaceReservationByDate,
  getWorkspaceReservationsForConnectionsByDate,
  loadDesksByAreaId,
  loadFacilities,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {CenteredLoader, StyledCard} from './styles';
import {MapSelectorCardProps, ToastData} from './types';
import {useEffect, useMemo, useRef, useState} from 'react';
import {EventTarget} from '../../../../../../../submodules/map/mapiq-map/EventTarget';
import {mergeState, desksState, toMapViewUser, selectedAreaState, anonymousSelectedAreaState} from '@utils';
import {MapToast, MapView, MapViewUser} from '@molecules';
import {useTheme} from 'styled-components';
import {parseISO} from 'date-fns';
import {BuildingNodeState} from '../../../../../../../submodules/map/mapiq-map/MapState';

// This component takes location ids and optional users and renders them in context.
// If provided with an onClick handler, it forwards map clicks to that handler.
export const MapSelectorCard = ({
  buildingId,
  floorId,
  areaId,
  deskIds,
  users,
  groupSize,

  onClick,
  date,
  toasterData,
}: MapSelectorCardProps) => {
  const theme = useTheme();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const areaNode = useAppSelector((state) => getAreaById(state, areaId));
  const [toastToBeShown, setToastToBeShown] = useState<ToastData | null>(null);
  const dispatch = useAppDispatch();

  const buildingNodeTypeStates = new Map([['facility', 'dimmed']]) as ReadonlyMap<
    'area' | 'desk' | 'room' | 'facility',
    BuildingNodeState
  >;

  const deskBookingRequired = Boolean(areaNode?.hasBookableDesks);

  const currentWorkspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parseISO(date)));
  const facilitiesData = useAppSelector(getFacilitiesByFloorId(floorId));

  // Note: I don't trust the store to have desks that are up to date... maybe I should?
  //       Opted to handle the requests in the component instead
  const [loading, setLoading] = useState(true);
  const [desks, setDesks] = useState<Desk[]>([]);
  const [bookedDesksById, setBookedDesksById] = useState<DeskBookingStatus>({});

  // This takes out desk ids that appear in our own reservation
  const deskIdsBookedByOthers: DeskBookingStatus = {
    ...bookedDesksById,
    ...Object.fromEntries(currentWorkspaceReservation?.members.map((m) => [m.location.deskId, false]) ?? []),
  };

  // This seems to be required to ensure the cached selector caches
  const nodeId = areaId || floorId;
  const connectionParams = useMemo(
    () => ({
      date,
      nodeId,
    }),
    [date, nodeId],
  );

  // To render connections (outside of our users) on the map:
  const connectionShifts = useAppSelector((state) =>
    // Note: we could choose to show all connections on the floor instead of just the area
    getWorkspaceReservationsForConnectionsByDate(state, connectionParams),
  );

  const [connections, setConnections] = useState<ReservationMember[]>([]);

  const connectionProfileImages = useUserProfilePics(
    connections.map((_member) => ({
      userId: _member.userId,
      imageHash: _member.user.imageHash ?? '',
    })),
  );

  const connectionsByDeskId = useMemo(() => {
    return new Map<string, MapViewUser>(
      connectionShifts.flatMap((r) => {
        if (!r.deskId) return [];
        const conMember = r.members.find((m) => m.userId === r.userId);
        if (!conMember) return [];

        setConnections((prev) => [...prev, conMember]);

        const connection = toMapViewUser(
          {
            email: conMember.user.email,
            imageUrl: connectionProfileImages[conMember.userId],
            initials: conMember.user.initials,
          },
          theme,
        );

        return [[conMember.location.deskId!, connection]];
      }),
    );
  }, [connectionShifts, theme, connectionProfileImages, setConnections]);

  const mapUsers = users.map((user) => toMapViewUser(user, theme));

  useEffect(
    function autoLoadDesks() {
      let canceled = false;

      const getInfo = async () => {
        const info = await dispatch(withAsyncThunkErrorHandling(() => loadDesksByAreaId({areaId, date})));
        if (canceled) return;

        if (info.success && info.result) {
          setDesks(info.result.desks);
          setBookedDesksById(info.result.bookedById);
        } else {
          setDesks([]);
          setBookedDesksById({});
        }

        setLoading(false);
      };

      if (areaId && deskBookingRequired) {
        setLoading(true);
        getInfo();
      } else {
        setLoading(false);
      }

      return () => {
        canceled = true;
      };
    },
    [dispatch, areaId, date, deskBookingRequired],
  );

  const onMapClickHandler = (target: EventTarget) => {
    if (toasterData && deskBookingRequired && target.nodeId && toasterData[target.nodeId]) {
      setToastToBeShown(toasterData[target.nodeId]);

      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        setToastToBeShown(null);
      }, 3000);
    }

    onClick?.(target);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (facilitiesData.status === 'NotLoaded') {
      dispatch(withAsyncThunkErrorHandling(() => loadFacilities({buildingId, floorId})));
    }
  }, [buildingId, dispatch, facilitiesData.status, floorId]);

  const areaState = mapUsers.length
    ? selectedAreaState(areaId, mapUsers)
    : anonymousSelectedAreaState(areaId, groupSize ?? 1);

  const {highlights, buildingNodeStates} =
    deskIds === undefined
      ? {highlights: areaState.markers, buildingNodeStates: areaState.state}
      : constructMapState(desks, deskIdsBookedByOthers, connectionsByDeskId, areaId, deskIds, mapUsers, onClick);

  return (
    <StyledCard>
      {loading && facilitiesData.status !== 'Loaded' ? (
        <CenteredLoader />
      ) : (
        <MapView
          buildingId={buildingId}
          floorId={floorId}
          highlights={highlights.concat(
            facilitiesData.status === 'Loaded'
              ? facilitiesData.facilities.map((facility) => {
                  return {
                    nodeId: facility.id,
                    subType: facility.facilityType.id,
                    type: 'facility',
                  };
                })
              : [],
          )}
          buildingNodeStates={buildingNodeStates}
          buildingNodeTypeStates={buildingNodeTypeStates}
          onClick={onMapClickHandler}
          disablePointerEvents={false}
          fullView={true}
        />
      )}

      {toastToBeShown && <MapToast toast={toastToBeShown} />}
    </StyledCard>
  );
};

const constructMapState = (
  desks: Desk[],
  bookedDesksById: DeskBookingStatus,
  connectionsByDeskId: Map<string, MapViewUser>,
  selectedAreaId: string,
  selectedDeskIds: string[],
  mapUsers: MapViewUser[],
  onClick?: (target: EventTarget) => void,
) => {
  // Construct our states and markers
  // 1) selections go first; they are managed outside of this component and passed through props
  const {state: selectionState, markers: selectionMarkers} = desksState(selectedDeskIds, mapUsers, 'selected');

  // 2) desks not in the selection get a marker by us
  const unselectedDesks = desks.filter((d) => d.isEnabled && !selectionState.has(d.id));

  // 2a) available ones
  const availableDeskIds = unselectedDesks.filter((d) => bookedDesksById[d.id] === false).map((d) => d.id);
  const {state: availableState, markers: availableMarkers} = desksState(
    availableDeskIds,
    [],
    onClick ? 'highlighted' : 'dimmed',
  );

  // 2b) occupied ones
  const bookedDeskIds = unselectedDesks.filter((d) => bookedDesksById[d.id] === true).map((d) => d.id);
  const withConnections = bookedDeskIds.filter((id) => connectionsByDeskId.has(id));
  const withoutConnections = bookedDeskIds.filter((id) => !connectionsByDeskId.has(id));

  // 2b1) by connections
  const {state: connectionsState, markers: connectionsMarkers} = desksState(
    withConnections,
    // TODO: type this better
    withConnections.flatMap((deskId) => {
      const connection = connectionsByDeskId.get(deskId);

      // Note: this should not happen because of the filter used to create `withConnections`
      if (!connection) return [];

      return [connection];
    }),
    'dimmed',
  );

  // 2b2 by anonymous colleagues
  const {state: bookedState, markers: bookedMarkers} = desksState(withoutConnections, [], 'dimmed', false);

  // Combine states
  const highlights = [...selectionMarkers, ...connectionsMarkers, ...availableMarkers, ...bookedMarkers];
  const buildingNodeStates = [bookedState, connectionsState, availableState, selectionState].reduce(mergeState);

  // Select the area as well
  buildingNodeStates.set(selectedAreaId, 'selected');

  return {
    highlights,
    buildingNodeStates,
  };
};
