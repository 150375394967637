import {useTranslation} from 'react-i18next';
import {CreateEventRoomSuggestionsListProps} from './types';

import {Loader} from '@atoms';
import {IconButton, Option, ModalCell, ModalCellButton, SwiperHorizontal, EventRoom} from '@molecules';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {parseISO} from 'date-fns';
import {
  getAllBuildings,
  getCapacityFilteredAvailableMeetingRooms,
  getCapacityFilteredAvailableMeetingRoomsLoadingStatus,
  getUserDirectoryObjectId,
  loadAvailableMeetingRoomsWithCapacityFilter,
  MeetingInviteeSearchResult,
  withAsyncThunkErrorHandling,
  getFallbackBuildingForNewMeeting,
  MeetingRoom,
} from '@lib/store';
import {useEffect, useState} from 'react';
import {FlexCol, FlexRow, H2, P} from '@quarks';
import {CreateEventManualRoomSelectionCard, CreateEventRoomSuggestionCell} from '@organisms';
import {NoRoomsAvailableWrapper, StyledSelect} from './styles';
import {SelectValue} from 'src/components/molecules/Select/types';

export const CreateEventRoomSuggestionsList = ({
  selectedStartDateTime,
  selectedEndDateTime,
  rooms,
  onSelectRoom,
  onClose,
  attendees,
}: CreateEventRoomSuggestionsListProps) => {
  const dispatch = useAppDispatch();
  const {addPages, removePages} = useModal();
  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [selectedRooms, setSelectedRooms] = useState<MeetingRoom[]>([]);
  const isRoomSuggestionLoading = useAppSelector(getCapacityFilteredAvailableMeetingRoomsLoadingStatus) === 'Loading';
  const organizerDirectoryObjectId = useAppSelector(getUserDirectoryObjectId);
  const fallbackBuilding = useAppSelector((state) =>
    getFallbackBuildingForNewMeeting(state, selectedStartDateTime === '' ? undefined : selectedStartDateTime),
  );
  const buildings = useAppSelector(getAllBuildings);

  const [selectedBuilding, setSelectedBuilding] = useState(fallbackBuilding?.id);
  const suggestedRooms = useAppSelector(getCapacityFilteredAvailableMeetingRooms);

  const {t} = useTranslation();

  const countAttendeesWithoutDuplicates = (
    loggedInUserDirectoryObjectId: string,
    requestedAttendees: MeetingInviteeSearchResult[],
  ) => requestedAttendees.filter((attendee) => attendee.directoryObjectId !== loggedInUserDirectoryObjectId).length + 1;

  useEffect(() => {
    const loadRoomSuggestions = async (requestAttendees: MeetingInviteeSearchResult[]) => {
      if (selectedStartDateTime === '' || selectedEndDateTime === '') return;

      const startDateTime = parseISO(selectedStartDateTime);
      const endDateTime = parseISO(selectedEndDateTime);
      if (!selectedBuilding) {
        return;
      }
      await dispatch(
        withAsyncThunkErrorHandling(() =>
          loadAvailableMeetingRoomsWithCapacityFilter({
            startDateTime,
            endDateTime,
            buildingId: selectedBuilding,
            attendeeCount: countAttendeesWithoutDuplicates(organizerDirectoryObjectId, requestAttendees),
          }),
        ),
      );
    };

    if (shouldShowSuggestions) {
      loadRoomSuggestions(attendees);
    }
  }, [
    selectedBuilding,
    attendees,
    selectedEndDateTime,
    selectedStartDateTime,
    dispatch,
    organizerDirectoryObjectId,
    shouldShowSuggestions,
  ]);

  const handleOnClick = (room: MeetingRoom) => {
    setShouldShowSuggestions(false);
    setSelectedRooms([...selectedRooms, room]);
    onSelectRoom([...rooms, room.email]);
  };

  const removeRoom = (room: MeetingRoom) => {
    const filteredRooms = selectedRooms.filter((item) => item.id !== room.id);
    setSelectedRooms(filteredRooms);
    onSelectRoom(rooms.filter((item) => item !== room.email));
    if (filteredRooms.length === 0) setShouldShowSuggestions(true);
  };

  const manuallyAddRoom = () =>
    addPages([
      <CreateEventManualRoomSelectionCard
        defaultBuildingId={selectedBuilding || fallbackBuilding?.id!}
        defaultRooms={suggestedRooms}
        endDateTime={selectedEndDateTime}
        onClose={onClose}
        onSelect={(room) => {
          handleOnClick(room);
          removePages(1);
        }}
        selectedRooms={rooms}
        startDateTime={selectedStartDateTime}
        title="meeting:CreateMeetingAddManualRoomLabel"
      />,
    ]);

  return (
    <FlexCol gap={1}>
      <ModalCell>
        <H2>{t('meeting:CreateMeetingSelectRoomText')}</H2>
      </ModalCell>
      {selectedStartDateTime === '' || selectedEndDateTime === '' ? (
        <ModalCell>
          <P
            paddingBottom={16}
            paddingTop={16}>
            {t('meeting:CreateMeetingRoomEmptyNoTimeSelected')}
          </P>
        </ModalCell>
      ) : (
        <>
          {selectedRooms.length > 0 ? (
            selectedRooms.map((room) => (
              <ModalCell key={room.id}>
                <FlexRow>
                  <EventRoom room={room} />
                  <IconButton
                    icon="trash"
                    size="default"
                    iconButton="tertiary"
                    data-testid={`organisms-createEvent-CreateEvenrRoomSuggestionsList-selectedRooms_delete-${room.displayName}`}
                    aria-label={t('meeting:CreateMeetingRoomDeleteAriaLabel')}
                    onClick={() => removeRoom(room)}
                  />
                </FlexRow>
              </ModalCell>
            ))
          ) : (
            <ModalCellButton
              onClick={manuallyAddRoom}
              data-testid={`organisms-createEvent-CreateEventRoomSuggestionsList_selectManual`}>
              <P>{t('meeting:CreateMeetingAddManualRoomLabel')}</P>
            </ModalCellButton>
          )}
          {shouldShowSuggestions ? (
            <>
              <ModalCell>
                <FlexRow
                  alignItems="center"
                  gap={24}>
                  <p>{t('meeting:CreateMeetingSuggestionsTitle')}</p>
                  <StyledSelect
                    value={selectedBuilding ?? ''}
                    setValue={setSelectedBuilding as (value: SelectValue) => void}
                    style={{minWidth: 0}}>
                    {buildings.map((item) => (
                      <Option
                        key={item.id}
                        value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </StyledSelect>
                </FlexRow>
              </ModalCell>
              <ModalCell>
                {isRoomSuggestionLoading ? (
                  <FlexRow
                    alignItems="center"
                    justifyContent="center">
                    <Loader />
                  </FlexRow>
                ) : (
                  <SwiperHorizontal>
                    {suggestedRooms.map((item, index) => (
                      <CreateEventRoomSuggestionCell
                        key={item.id}
                        suggestionsLength={suggestedRooms.length}
                        room={item}
                        onClose={onClose}
                        id={index.toString()}
                        selectedId={selectedId}
                        setSelectedId={() => setSelectedId(index.toString())}
                        onClick={(room) => handleOnClick(room)}
                      />
                    ))}
                    {suggestedRooms.length === 0 && (
                      <NoRoomsAvailableWrapper>
                        <P fontWeight="bold">{t('meeting:CreateMeetingEmptyStateTitle')}</P>
                        <P>{t('meeting:CreateMeetingEmptyStateMessage')}</P>
                      </NoRoomsAvailableWrapper>
                    )}
                  </SwiperHorizontal>
                )}
              </ModalCell>
            </>
          ) : (
            <ModalCellButton onClick={manuallyAddRoom}>
              <P data-testid="organisms-createEvent-CreateEventRoomSuggestionsList_selectManual-extra">
                {t('meeting:CreateMeetingAddExtraManualRoomLabel')}
              </P>
            </ModalCellButton>
          )}
        </>
      )}
    </FlexCol>
  );
};
