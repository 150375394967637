import {PageHeaderProps} from './types';
import {PageHeaderWrapper} from './styles';

import {Div, H1} from '@quarks';
import {Button, IconButton} from '@molecules';

export const PageHeader = ({
  buttonIcon,
  buttonLabel,
  className,
  onClick,
  showButton,
  style,
  title,
  ...props
}: PageHeaderProps) => {
  return (
    <PageHeaderWrapper
      className={className}
      style={style}
      {...props}>
      <H1
        sm={{
          gridArea: 'title',
        }}>
        {title}
      </H1>
      {(showButton === 'all' || showButton === 'desktop') && (
        <Div sm={{display: 'none'}}>
          <Button
            button="tertiary"
            iconRight={buttonIcon}
            onClick={onClick}>
            {buttonLabel}
          </Button>
        </Div>
      )}
      {(showButton === 'all' || showButton === 'mobile') && (
        <Div
          display="none"
          sm={{display: 'block', gridArea: 'button', justifySelf: 'flex-end'}}>
          {buttonIcon && buttonLabel && (
            <IconButton
              aria-label={buttonLabel}
              icon={buttonIcon}
              iconButton="tertiary"
              onClick={() => onClick?.()}
            />
          )}
        </Div>
      )}
    </PageHeaderWrapper>
  );
};
