import {getMeetingRoomSuggestionDataById, MeetingRoom} from '@lib/store';
import {useAppSelector} from './useAppSelector';
import {RoomState} from '@lib/infrastructure';
import {SensorStatus, useRoomSensorStatus} from './useRoomSensorStatus';
import {ScheduleStatus, useRoomScheduleStatus} from './useRoomScheduleStatus';

export type ScheduleAndSensorStatuses = Partial<SensorStatus> &
  Partial<ScheduleStatus> & {
    sublineText?: string;
  };

export const useScheduleAndSensorStatuses = (
  room: MeetingRoom,
  startDateTime: string,
  endDateTime: string,
): ScheduleAndSensorStatuses => {
  const {state: roomState} =
    useAppSelector((state) => getMeetingRoomSuggestionDataById(state, room.suggestionData ?? '')) ?? {};

  const sensorData = useRoomSensorStatus(room);

  const scheduleData = useRoomScheduleStatus(room, startDateTime, endDateTime);
  const titleFromSensor = sensorData?.sensorTitle;
  const {scheduleTitle, scheduleSubtitle} = scheduleData ?? {};

  const getSublineText = (): string | undefined => {
    switch (roomState) {
      case RoomState.InUse:
      case RoomState.NotBookedAndInUse:
      case RoomState.BookedAndInUse:
      case RoomState.BookingAboutToStartAndInUse:
      case RoomState.BookingAboutToEndAndInUse:
        return titleFromSensor;
      case RoomState.Untraceable:
      case RoomState.NotBooked:
        return titleFromSensor;

      case RoomState.BookedAndUnknownSensor:
      case RoomState.BookedAndFree:
      case RoomState.Booked:
        return scheduleSubtitle; // booked until
      case RoomState.BookingAboutToStartAndUnknownSensor:
      case RoomState.BookingAboutToStartAndFree:
      case RoomState.BookingAboutToStart:
        return scheduleTitle; // available for
      case RoomState.BookingAboutToEndAndUnknownSensor:
      case RoomState.BookingAboutToEndAndFree:
      case RoomState.BookingAboutToEnd:
        return scheduleTitle; // available in
      case RoomState.Free:
      case RoomState.NotBookedAndUnknownSensor:
      case RoomState.UnknownSensor:
      case RoomState.NotBookedAndFree:
      default:
        return;
    }
  };

  return {
    ...sensorData,
    ...scheduleData,
    sublineText: getSublineText(),
  };
};
