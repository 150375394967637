import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ManualTimeSlotSelectionCardProps} from './types';

import {isMeetingDateTimeRangeValid} from '@lib/store';
import {useLoadAvailableInvitees, useModal} from '@hooks';
import {addMinutes, isAfter, parseISO, roundToNearestMinutes} from 'date-fns';

import {DEFAULT_NEW_EVENT_DURATION} from '@constants';
import {FlexCol, FlexRow, Grid, P} from '@quarks';
import {Loader} from '@atoms';
import {AvatarStack, DatePicker, ModalCell, ModalCellButton, TimePicker} from '@molecules';
import {AvailabilityDetailsCard, ModalPageBottom} from '@organisms';
import {StyledModalPage} from './styles';

export const ManualTimeSlotSelectionCard = ({
  onSave,
  endDateTime,
  onClose,
  startDateTime,
  date,
  attendees,
}: ManualTimeSlotSelectionCardProps) => {
  const {removePages, closeModal, addPages} = useModal();
  const [now] = useState<string>(
    roundToNearestMinutes(new Date(), {nearestTo: 10, roundingMethod: 'ceil'}).toISOString(),
  );

  const {t} = useTranslation();

  const [localStartDateTime, setLocalStartDateTime] = useState<string>(now);
  const [localEndDateTime, setLocalEndDateTime] = useState<string>(
    addMinutes(parseISO(now), DEFAULT_NEW_EVENT_DURATION).toISOString(),
  );

  const isDateRangeValid = isMeetingDateTimeRangeValid(parseISO(localStartDateTime), parseISO(localEndDateTime));

  const attendeeEmails = useMemo(() => attendees.map((attendee) => attendee.email), [attendees]);

  const {attendeeAvailabilities, loading: isLoadingAvailability} = useLoadAvailableInvitees({
    startDate: localStartDateTime,
    endDate: localEndDateTime,
    attendeeEmails,
  });

  const saveDateTimeValues = () => {
    if (isDateRangeValid) {
      onSave(localStartDateTime, localEndDateTime, attendeeAvailabilities);
      removePages(1);
    }
  };

  useEffect(() => {
    // If endtime is not after starttime we change endtime
    if (!isAfter(parseISO(localEndDateTime), parseISO(localStartDateTime))) {
      setLocalEndDateTime(addMinutes(parseISO(localStartDateTime), DEFAULT_NEW_EVENT_DURATION).toISOString());
    }

    // If starttime is not after our fallback (now) we change starttime
    if (!isAfter(parseISO(localStartDateTime), parseISO(now))) {
      setLocalStartDateTime(now);
    }
  }, [localEndDateTime, localStartDateTime, now]);

  useEffect(() => {
    if (startDateTime) setLocalStartDateTime(startDateTime);
    if (endDateTime) setLocalEndDateTime(endDateTime);
  }, [endDateTime, startDateTime]);

  const isButtonDisabled = isAfter(parseISO(localStartDateTime), parseISO(localEndDateTime));

  return (
    <StyledModalPage
      onBack={() => removePages(1)}
      onClose={() => closeModal()}
      bottom={
        <ModalPageBottom
          data-testid="organisms-createEvent-ManualTimeSlotSelectionCard-ModalPageBottom"
          buttonLabel={t('translation:Confirm')}
          buttonDisabled={isButtonDisabled}
          onClick={saveDateTimeValues}
        />
      }
      title={t('screen:NewEventManualTimeSelection')}>
      <FlexCol
        as="section"
        gap={2}>
        <ModalCell style={{gap: 10, display: 'flex', flexDirection: 'column'}}>
          <Grid
            gridTemplateColumns="1fr 1fr 1fr"
            alignItems="center"
            justifyItems="center">
            <P justifySelf="flex-start">{t('meeting:CreateMeetingManualTimeStartLabel')}</P>
            <DatePicker
              date={localStartDateTime}
              minDate={now}
              setDate={setLocalStartDateTime}
              data-testid="organisms-createEvent-ManualTimeSlotSelectionCard_start-date"
            />
            <TimePicker
              date={localStartDateTime}
              minDate={now}
              setDate={setLocalStartDateTime}
              data-testid="organisms-createEvent-ManualTimeSlotSelectionCard_start-time"
            />
          </Grid>
          <Grid
            gridTemplateColumns="1fr 1fr 1fr"
            alignItems="center"
            justifyItems="center">
            <P justifySelf="flex-start">{t('meeting:CreateMeetingManualTimeEndLabel')}</P>
            <DatePicker
              date={localEndDateTime}
              minDate={localStartDateTime}
              setDate={setLocalEndDateTime}
            />
            <TimePicker
              date={localEndDateTime}
              minDate={localStartDateTime}
              setDate={setLocalEndDateTime}
              data-testid="organisms-createEvent-ManualTimeSlotSelectionCard_end-time"
            />
          </Grid>
        </ModalCell>
        {isLoadingAvailability ? (
          <FlexRow
            padding={16}
            alignItems="center"
            justifyContent="center">
            <Loader />
          </FlexRow>
        ) : (
          <ModalCellButton
            data-testid="organisms-createEvent-ManualTimeSlotSelectionCard_invitees-button"
            onClick={() =>
              addPages([
                <AvailabilityDetailsCard
                  date={localStartDateTime}
                  source="manual"
                  onClose={onClose}
                  isDateRangeValid={isDateRangeValid}
                />,
              ])
            }>
            <AvatarStack
              date={date}
              overlap={false}
              tooltipId="ManualTimeSlotSelectionCard"
              users={attendeeAvailabilities.map((attendee) => ({
                ...attendee,
                name: attendee.displayName,
                id: attendee.directoryObjectId,
                email: attendee.email,
                imageHash: attendee.imageHash,
                initials: attendee.initials,
                inviteResponse:
                  attendee.availability === 'Available'
                    ? 'Accepted'
                    : attendee.availability === 'Unavailable'
                    ? 'Declined'
                    : 'Tentative',
              }))}
            />
          </ModalCellButton>
        )}
      </FlexCol>
    </StyledModalPage>
  );
};
