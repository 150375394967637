import {useTranslation} from 'react-i18next';
import {RoomDetailsCardProps} from './types';

import {useAppSelector, useFeatureToggle, useModal, useRoomScheduleStatus, useRoomSensorStatus} from '@hooks';

import {
  HaNAvailabilityStatusMoreInfo,
  HaNBookARoom,
  MeetingRoomActivities,
  MeetingRoomEquipment,
  MeetingRoomImage,
  MeetingRoomInfo,
  MeetingRoomMap,
} from '@organisms';
import {ModalPage, MeetingRoomDetailsCardBottom} from '@organisms';
import {FlexCol} from '@quarks';
import {addMinutes, parseISO} from 'date-fns';
import {getFloorHasMap, getDynamicFreeTimeslot, getIsMeetingsCalendarActive} from '@lib/store';
import {MeetingRoomData} from '../MeetingRoomData';
import {StyledContainer} from './styles';

export const MeetingRoomDetailsCard = ({
  room,
  startDateTime = new Date().toISOString(),
  duration = 0,
  displayForHereAndNow = false,
  onClose,
}: RoomDetailsCardProps) => {
  const {t} = useTranslation();
  const {removePages, addPages} = useModal();

  const endDateTime = addMinutes(parseISO(startDateTime), duration).toISOString();
  const scheduleStatus = useRoomScheduleStatus(room, startDateTime, endDateTime);
  const sensorStatus = useRoomSensorStatus(room);
  const floorHasMap = useAppSelector(getFloorHasMap(room.floorId));
  const {MeetingRoomMapThumbnail: miniMapEnabled} = useFeatureToggle();
  const showMap = miniMapEnabled && floorHasMap;
  const {start: dynamicStart, end: dynamicEnd} =
    useAppSelector((state) => getDynamicFreeTimeslot(state, room.id, startDateTime, {minutes: duration})) ?? {};
  const dynamicStartString = dynamicStart && dynamicStart.toISOString();
  const dynamicEndString = dynamicEnd && dynamicEnd.toISOString();
  const isMeetingsCalendarActive = useAppSelector(getIsMeetingsCalendarActive);
  const isRoomBookable = !!room?.email && isMeetingsCalendarActive;

  return (
    <ModalPage
      {...(displayForHereAndNow && isRoomBookable
        ? {
            bottom: (
              <StyledContainer>
                <MeetingRoomDetailsCardBottom
                  room={room}
                  startDateTime={startDateTime}
                  duration={duration}
                  onBookingDetailsPress={() =>
                    addPages([
                      <HaNBookARoom
                        room={room}
                        startDateTime={dynamicStartString ?? ''}
                        endDateTime={dynamicEndString ?? ''}
                      />,
                    ])
                  }
                />
              </StyledContainer>
            ),
          }
        : {
            onBack: () => removePages(1),
          })}
      // The code above is a bit of a tough one, but it just conditionally passes the prop
      onClose={onClose}
      title={displayForHereAndNow ? t('screen:BookRoomNow') : t('screen:MeetingRoomDetails')}>
      <FlexCol
        as="section"
        gap={32}>
        <MeetingRoomInfo
          roomName={room.displayName}
          buildingName={room.buildingName}
          floorName={room.floorName}
          capacity={room.capacity}
        />
        <MeetingRoomImage
          displayName={room.displayName}
          email={room.email}
          roomPictureHash={room.roomPictureHash}
          buildingId={room.buildingId}
        />
        {displayForHereAndNow ? (
          <MeetingRoomData
            sensorStatus={sensorStatus}
            scheduleStatus={scheduleStatus}
            onLearnMorePress={() => addPages([<HaNAvailabilityStatusMoreInfo />])}
          />
        ) : null}
        <MeetingRoomActivities activities={room.activities} />
        <MeetingRoomEquipment equipment={room.equipment} />
        {showMap && <MeetingRoomMap room={room} />}
      </FlexCol>
    </ModalPage>
  );
};
