import {getAccessToken} from '@lib/store';
import {useAppSelector} from './useAppSelector';
import {useImages} from './useImages';
/**
 * @param imageHash string
 * @returns URL to profile picture as string
 */

type UserImageHash = {
  userId: string;
  imageHash: string;
};

export const useUserProfilePics = (userImageHashes: UserImageHash[], size = 64) => {
  const token = useAppSelector(getAccessToken) || '';

  const keyedEndpoints = userImageHashes?.reduce(
    (acc, {userId, imageHash}) =>
      imageHash
        ? {
            ...acc,
            [userId]: `shifts/profilepictures/${imageHash}?size=${size}`,
          }
        : acc,
    {},
  );

  const userProfilePics = useImages(token, keyedEndpoints, '2.0');

  return userProfilePics;
};
