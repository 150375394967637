import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';
import {Avatar} from '@molecules';
import {AvatarProps} from 'src/components/molecules/Avatar/types';

type StyledNavAvatarProps = AvatarProps & {$active: boolean};

const NavItemStyles = css<{$footer: boolean}>`
  align-items: center;
  background: none;
  border: none;
  border-radius: 2px;
  display: flex;
  flex: none;
  height: ${({$footer}) => $footer && '2.5rem'};
  justify-content: center;
  position: relative;
  width: ${({$footer}) => $footer && '2.5rem'};

  & > svg > * {
    transition: stroke 0.2s;
  }

  @media (hover) {
    &:hover > svg > * {
      stroke: ${({theme}) => theme.icon.active};
    }
  }

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 2px;
  }
`;

export const StyledNavLink = styled(NavLink)<{$footer: boolean}>`
  ${NavItemStyles}
`;

export const StyledButton = styled.button<{$footer: boolean}>`
  ${NavItemStyles}
`;

export const StyledNavAvatar = styled(Avatar).attrs<StyledNavAvatarProps>(({theme, $active, ...attrs}) => ({
  ...attrs,
  disabled: !$active,
}))<StyledNavAvatarProps>`
  border: ${({$active}) => $active && `2px solid`};
  @media (hover) {
    :hover {
      ::before {
        transition: opacity 0.2s;
        opacity: 0;
      }
    }
  }
`;
