import {BREAKPOINTS} from '@constants';
import {animated} from 'react-spring';
import styled from 'styled-components';
import {FlexRow} from '../quarks/Flex';

export const ModalLayout = styled(animated.div)`
  display: grid;
  height: 80vh;
  width: clamp(30vw, 600px, calc(100vw - 32px));

  ${BREAKPOINTS.small`
    width: 100vw;
    height: calc(var(--100vh) - 30px);
  `}
`;

export const ModalCenterWrap = styled(FlexRow).attrs(({...attrs}) => ({
  alignItems: 'center',
  justifyContent: 'center',
  sm: {
    alignItems: 'flex-end',
  },
  ...attrs,
}))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--100vh, 100vh);
  display: flex;
`;
