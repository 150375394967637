import {RESERVATION_STATUS} from '@constants';
import {ReservationMember} from '@lib/store';

const getReservationStatusOrder = <Tstatus extends string = keyof typeof RESERVATION_STATUS>(status: Tstatus) => {
  const orderObject = {
    [RESERVATION_STATUS.Confirmed]: 2,
    [RESERVATION_STATUS.InvitationPending]: 1,
  } as Record<Tstatus, number>;

  return orderObject[status] || 0;
};

const compareOwner = (a: ReservationMember, b: ReservationMember) => Number(b.isOwner) - Number(a.isOwner);
const compareStatus = (a: ReservationMember, b: ReservationMember) =>
  getReservationStatusOrder(b.status) - getReservationStatusOrder(a.status);
const compareName = (a: ReservationMember, b: ReservationMember) => {
  const displayNameA = a.user.displayName || a.user.email;
  const displayNameB = b.user.displayName || b.user.email;

  return displayNameA.localeCompare(displayNameB);
};

export const sortMembersByResponse = (members: ReservationMember[]) => {
  return members.sort((a: any, b: any) => compareOwner(a, b) || compareStatus(a, b) || compareName(a, b));
};
