import {useAppDispatch, useModal} from '@hooks';
import {ConnectionFilterList, ModalPageBottom} from '@organisms';
import {FlexCol, P, Subtitle} from '@quarks';

import {
  getIsLoadingConnections,
  getConnectedUsersWithAndWithoutBookings,
  updateSelectedCalendarConnectionFilterIds,
  clearSelectedCalendarConnectionFilterIds,
  getSelectedCalendarConnectionFilterIds,
} from '@lib/store';
import {useAppSelector} from '@hooks';
import {Input} from '@molecules';
import {Loader} from '@atoms';
import {useTranslation} from 'react-i18next';

import {ChangeEvent, useState} from 'react';

import {filterByStringProperty, useDebounce} from '@lib/utils';
import {StyledModalPage} from './styles';

export const ConnectionFilterModalPage = () => {
  const {closeModal} = useModal();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const selectedCalendarConnectionFilterIds = useAppSelector(getSelectedCalendarConnectionFilterIds);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 300);
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedCalendarConnectionFilterIds);

  const isLoadingConnections = useAppSelector(getIsLoadingConnections);

  const {connectionsWithWorkdays, connectionsWithoutWorkdays} = useAppSelector(getConnectedUsersWithAndWithoutBookings);

  const applyFilter = () => {
    dispatch(updateSelectedCalendarConnectionFilterIds(selectedIds));
    closeModal();
  };

  const handleClear = () => {
    dispatch(clearSelectedCalendarConnectionFilterIds());
    setSelectedIds([]);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleCheckboxChange = (connectionId: string, selected: boolean) => {
    if (selected) {
      setSelectedIds((prev) => prev.filter((p) => p !== connectionId));
    } else {
      setSelectedIds((prev) => [...prev, connectionId]);
    }
  };

  const filtered = {
    connectionsWithWorkdays: filterByStringProperty(connectionsWithWorkdays, debouncedSearchQuery, ['name', 'email']),
    connectionsWithoutWorkdays: filterByStringProperty(connectionsWithoutWorkdays, debouncedSearchQuery, [
      'name',
      'email',
    ]),
  };

  const hasConnectionsWithWorkdays = !!connectionsWithWorkdays.length;
  const hasFilteredConnectionsWithWorkdays = !!filtered.connectionsWithWorkdays.length;
  const hasFilteredConnectionsWithoutWorkdays = !!filtered.connectionsWithoutWorkdays.length;

  return (
    <StyledModalPage
      title={t('connection:Filter')}
      onClose={closeModal}
      bottom={
        <ModalPageBottom
          buttonDisabled={false}
          buttonLabel={t('translation:Apply')}
          cancelButtonLabel={t('translation:Clear')}
          cancelButtonType="tertiary"
          onClick={applyFilter}
          onCancel={handleClear}
        />
      }>
      <FlexCol gap={16}>
        {isLoadingConnections ? (
          <Loader />
        ) : (
          <>
            <Input
              type="search"
              size="full"
              placeholder={t('connection:SearchConnectionPlaceholder')}
              onChange={handleChange}
              checked={true}
            />

            <P
              fontWeight="bold"
              paddingTop={16}>
              {t('connection:ConnectionsGoingToTheOffice')}
            </P>

            {hasFilteredConnectionsWithWorkdays ? (
              <ConnectionFilterList
                connections={filtered.connectionsWithWorkdays}
                selectedIds={selectedIds}
                onItemClick={handleCheckboxChange}
              />
            ) : (
              <Subtitle>
                {hasConnectionsWithWorkdays
                  ? t('connection:NoConnectionsFound')
                  : t('connection:NoConnectionsGoingToTheOffice')}
              </Subtitle>
            )}

            {hasFilteredConnectionsWithoutWorkdays && (
              <>
                <P
                  fontWeight="bold"
                  paddingTop={16}>
                  {t('connection:ConnectionsNotGoingToTheOffice')}
                </P>
                <ConnectionFilterList
                  connections={filtered.connectionsWithoutWorkdays}
                  selectedIds={selectedIds}
                  onItemClick={handleCheckboxChange}
                />
              </>
            )}
          </>
        )}
      </FlexCol>
    </StyledModalPage>
  );
};
