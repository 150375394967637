import {Icon} from '@atoms';
import {useAppDispatch, useAppSelector, useDialog, useModal} from '@hooks';
import {
  createWorkday,
  deleteWorkspaceReservation,
  getIsUserOwnerOfWorkspaceReservationByDate,
  getNodeInformationByNodeId,
  getParkingReservationByDate,
  getWorkdayByDate,
  getWorkspaceReservationByDate,
  removeParkingSpot,
  selectLocationBasedOnBuildingId,
  updateWorkdayAndReservations,
  withAsyncThunkErrorHandling,
  withThunkErrorHandling,
  Workday,
} from '@lib/store';
import {CapacityIndicator, ModalCellItem, ModalCellItemIcon} from '@molecules';
import {FlexCol, P} from '@quarks';
import {pxToRem} from '@utils';
import {parseISO} from 'date-fns';
import {IconNames} from 'mapiq-atoms';
import {useTranslation} from 'react-i18next';
import {BookingLocationCellProps} from './types';
import {StyledText} from './styles';
import {BookWorkspaceCard} from '../BookWorkspaceCard';

export const BookingLocationCellItem = ({building, date}: BookingLocationCellProps) => {
  const {t} = useTranslation();
  const {closeModal, addPages} = useModal();
  const dispatch = useAppDispatch();
  const {destructiveDialog} = useDialog();
  const workdayByDate = useAppSelector((state) => getWorkdayByDate(state, date));
  const {occupation, isClosed, isUnavailableForGroupBookingDueToDeskBookingOnly, hasInsufficientSpaceForGroupBooking} =
    useAppSelector((state) => getNodeInformationByNodeId(state, {date: parseISO(date), nodeId: building.id}));
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parseISO(date)));
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));
  const isUserOwnerOfWorkspaceReservation = useAppSelector((state) =>
    getIsUserOwnerOfWorkspaceReservationByDate(state, parseISO(date)),
  );

  const isDisabled = building.nodeAvailability === 'Unavailable';
  const isValidItem = building.name !== 'WorkingRemotely' && building.name !== 'NotWorking';
  const icon: IconNames =
    building.name === 'WorkingRemotely' ? 'remote' : building.name === 'NotWorking' ? 'calendarX' : 'office';

  const showDeleteBookingDialog = () =>
    destructiveDialog(
      t('workplace:DeleteYourBookings'),
      t('workplace:DeleteYourBookingsMessage', {buildingName: workdayByDate?.nodeName}),
      'reportProblem',
    );

  const showDeleteBookingDialogAndSave = async () => {
    const confirmation = await showDeleteBookingDialog();

    if (confirmation) {
      saveWorkday();
    }
  };

  const saveWorkday = () => {
    const newWorkday = {
      id: workdayByDate?.id,
      status: building.type,
      nodeId: building.id,
      nodeName: building.type === 'OfficeDay' ? building.name : undefined,
      endDate: date,
      startDate: date,
    } as Workday;

    newWorkday.id
      ? dispatch(withThunkErrorHandling(() => updateWorkdayAndReservations(newWorkday)))
      : dispatch(withAsyncThunkErrorHandling(() => createWorkday(newWorkday)));
    closeModal();
  };

  const deleteRelatedReservationsWithConfirm = async () => {
    const confirmation = await showDeleteBookingDialog();
    if (confirmation) {
      // The reservations must be deleted sequentially in this order to prevent concurrency errors from the BE
      if (parkingReservation) {
        await dispatch(withAsyncThunkErrorHandling(() => removeParkingSpot(parkingReservation.id)));
      }
      if (workspaceReservation) {
        await dispatch(withThunkErrorHandling(() => deleteWorkspaceReservation(workspaceReservation)));
      }
    }

    return confirmation;
  };

  const canSelectOtherWorkspace = () => !workdayByDate || !workspaceReservation || isUserOwnerOfWorkspaceReservation;

  const handleOnClick = async () => {
    if (workdayByDate) {
      if (workdayByDate.status === 'OfficeDay') {
        if (isSelected) return;
      } else {
        if (workdayByDate.status === building.type) return;
      }
    }

    if (!building.allowWorkdayWithoutWorkspace) {
      if (canSelectOtherWorkspace() || (await deleteRelatedReservationsWithConfirm())) {
        dispatch(selectLocationBasedOnBuildingId(building.id));
        addPages([<BookWorkspaceCard date={date} />]);
      }

      return;
    }

    if (!isDisabled) workspaceReservation || parkingReservation ? showDeleteBookingDialogAndSave() : saveWorkday();
  };

  const isSelected = workdayByDate?.status === building.type && !!building.id && workdayByDate?.nodeId === building.id;

  if (building.name === 'WorkingRemotely' || building.name === 'NotWorking') {
    const buildingName =
      building.name === 'WorkingRemotely' ? t('workplace:WorkingRemotely') : t('workplace:NotWorking');
    return (
      <ModalCellItem
        left={
          <ModalCellItemIcon
            icon={icon}
            selected={workdayByDate?.status === building.type}
          />
        }
        middle={
          <FlexCol>
            <P>{buildingName}</P>
          </FlexCol>
        }
        onClick={handleOnClick}
      />
    );
  }

  return (
    <ModalCellItem
      alignItems="flex-start"
      onClick={handleOnClick}
      disabled={isDisabled}
      left={
        <ModalCellItemIcon
          icon={icon}
          disabled={isDisabled}
          selected={isSelected}
        />
      }
      middle={
        <FlexCol
          paddingTop={8}
          gap={8}>
          <StyledText
            fontWeight="bold"
            data-testid="booking-location_name"
            $disabled={isDisabled}>
            {building.name}
          </StyledText>
          {!building.allowWorkdayWithoutWorkspace && (
            <StyledText $disabled={isDisabled}>{t('workplace:WorkspaceBookingRequired')}</StyledText>
          )}
          {isValidItem &&
            (isClosed ? (
              <StyledText
                $disabled
                fontSize="sm">
                {t('workplace:ClosedOffice')}
              </StyledText>
            ) : (
              <>
                <CapacityIndicator
                  occupation={occupation}
                  capacity={building.capacity}
                />
                {isUnavailableForGroupBookingDueToDeskBookingOnly && (
                  <P fontSize="sm">{t('workplace:NoGroupBooking')}</P>
                )}
                {hasInsufficientSpaceForGroupBooking && (
                  <P fontSize="sm">{t('workplace:InsufficientForGroupBooking')}</P>
                )}
              </>
            ))}
        </FlexCol>
      }
      right={
        !building.allowWorkdayWithoutWorkspace &&
        building.nodeAvailability !== 'Unavailable' && (
          <Icon
            icon="caretRight"
            size={pxToRem(24)}
          />
        )
      }
    />
  );
};
