import {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import {ModalProviderProps, ModalContextProps} from './types';

export const ModalContext = createContext<ModalContextProps>(null!);

export const ModalProvider = ({children}: ModalProviderProps) => {
  const [modalPages, setModalPages] = useState<ReactNode[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [onCloseCallback, setOnCloseCallback] = useState<(() => void) | (() => Promise<void>) | boolean>(false);

  /**
   * Remember to set the modalPages before opening the modal!
   */
  const openModal = useCallback(() => setIsModalOpen(true), []);

  /**
   * Will close the modal and clear the pages.
   * The clearing of the pages is taken care of in the Modal component.
   */
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  /**
   * @param pages An array of pages you want to add to the modal, the last page will be the one in view.
   */
  const addPages = useCallback((pages: ReactNode[]) => {
    setModalPages((prevModalPages) => [...prevModalPages, ...pages]);
  }, []);

  /**
   * @param pagesToRemove Number of pages to be removed, i.e. how far back the user will go in the array of pages.
   */
  const removePages = (pagesToRemove: number) => {
    setModalPages((prevModalPages) => prevModalPages.slice(0, -pagesToRemove));
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isModalOpen]);

  return (
    <ModalContext.Provider
      value={{
        addPages,
        closeModal,
        isModalOpen,
        modalPages,
        openModal,
        removePages,
        setModalPages,
        onCloseCallback,
        setOnCloseCallback,
      }}>
      {children}
    </ModalContext.Provider>
  );
};
