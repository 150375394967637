import {useTranslation} from 'react-i18next';
import {RoomDetailsCardBottomProps} from './types';

import {formatDuration, getDynamicFreeTimeslot, removeRoomFromAllRooms} from '@lib/store';
import {useAppSelector, useCreateEvent, useFormatDate} from '@hooks';

import {FlexCol, FlexRow, P, Span} from '@quarks';
import {ModalPageBottom} from '@organisms';
import {Button} from '@molecules';
import {StyledContainer, StyledText} from './styles';
import {intervalToDuration} from 'date-fns';
import {stripSecondsAndMilliseconds} from '@lib/utils';
import {useDispatch} from 'react-redux';

export const MeetingRoomDetailsCardBottom = ({
  room,
  startDateTime,
  duration,
  onBookingDetailsPress,
  onBookNowCallback,
}: RoomDetailsCardBottomProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {start: dynamicStart, end: dynamicEnd} =
    useAppSelector((state) => getDynamicFreeTimeslot(state, room.id, startDateTime, {minutes: duration})) ?? {};

  const dynamicTimeslotFound = !!dynamicStart && !!dynamicEnd;

  const dynamicStartString = dynamicStart && dynamicStart.toISOString();
  const dynamicEndString = dynamicEnd && dynamicEnd.toISOString();

  const start = useFormatDate(dynamicStartString ?? '', 'HH:mm');
  const end = useFormatDate(dynamicEndString ?? '', 'HH:mm');

  const handleOnSuccess = () => {
    dispatch(removeRoomFromAllRooms(room.id));
    onBookNowCallback && onBookNowCallback();
  };

  const {createEvent, isCreatingEvent} = useCreateEvent(handleOnSuccess);

  const durationFormated =
    dynamicStart && dynamicEnd
      ? intervalToDuration({
          start: stripSecondsAndMilliseconds(dynamicStart),
          end: dynamicEnd,
        })
      : undefined;

  const durationAsString = formatDuration(t, durationFormated, false);

  return (
    <StyledContainer>
      {dynamicTimeslotFound ? (
        <FlexCol
          justifyContent="center"
          gap={8}
          alignItems="flex-start">
          <FlexRow>
            <P>
              <Span fontWeight="bold">{`${start} - ${end} `}</Span>
              {`(${durationAsString})`}
            </P>
          </FlexRow>
          <Button
            onClick={onBookingDetailsPress}
            loading={isCreatingEvent}
            button={'text'}>
            {t('translation:BookingDetails')}
          </Button>
        </FlexCol>
      ) : (
        <StyledText alignSelf="center">{t('hereAndNow:RoomNotAvailable')}</StyledText>
      )}
      <ModalPageBottom
        buttonLabel={t('translation:BookNow')}
        buttonLoading={isCreatingEvent}
        buttonDisabled={!dynamicTimeslotFound}
        style={{padding: '0'}}
        onClick={() =>
          createEvent(
            {
              title: room.displayName,
              message: '',
              attendees: [],
              startDateTime: dynamicStartString ?? '',
              endDateTime: dynamicEndString ?? '',
              isOnlineMeeting: true,
              rooms: [room.email],
              isAllDay: false,
              organizerTimeZone: '',
              origin: 'BookRoomNow',
            },
            t('hereAndNow:RoomBookingSuccessMessage'),
          )
        }
      />
    </StyledContainer>
  );
};
