import {getIsWorkspacesOnHereAndNowEnabled, type Area, type MeetingRoom} from '@lib/store';
import {HaNRoomList, HaNWorkspacesList} from '@organisms';
import {isMeetingRoom} from '../helpers';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '@hooks';

export type HaNVerticalListContentProps = {
  rooms: MeetingRoom[];
  selectedObject: MeetingRoom | Area | null;
  onClick: (entity: MeetingRoom | Area) => void;
  onHover: (spotlightId: string | null) => void;
  startDateTime: string;
  endDateTime: string;
  areas: Area[];
};

export const HaNVerticalListContent = ({
  rooms,
  onClick,
  selectedObject,
  onHover,
  endDateTime,
  startDateTime,
  areas,
}: HaNVerticalListContentProps) => {
  const {tab} = useParams();
  const isWorkspacesOnHereAndNowEnabled = useAppSelector(getIsWorkspacesOnHereAndNowEnabled);

  if (
    (tab === 'rooms' || (!isWorkspacesOnHereAndNowEnabled && !tab)) &&
    (selectedObject === null || isMeetingRoom(selectedObject))
  ) {
    return (
      <HaNRoomList
        onClick={onClick}
        rooms={rooms}
        selectedRoom={selectedObject}
        onHover={onHover}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
      />
    );
  } else if (tab === 'workspaces' && (selectedObject === null || !isMeetingRoom(selectedObject))) {
    return (
      <HaNWorkspacesList
        areas={areas}
        onClick={onClick}
        onHover={onHover}
        selectedWorkspace={selectedObject}
      />
    );
  }
  return null;
};
