import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

type HereAndNowLayoutProps = {
  $displayCalendarSyncTile: boolean;
  $areWorkspacesEnabled: boolean | undefined;
};

export const HereAndNowLayout = styled.div<HereAndNowLayoutProps>`
  --filter-height: 40px;
  --controls-height: 40px;
  --tabs-height: ${({$areWorkspacesEnabled}) => ($areWorkspacesEnabled ? '60px' : '0px')};
  --title-height-minus-filters: 60px;
  --horizontal-room-list-height: 0px;
  --calendar-sync-tile-height: ${({$displayCalendarSyncTile: displayCalendarSyncTile}) =>
    displayCalendarSyncTile ? '176px' : '0px'};
  display: grid;
  grid-template-columns: [room-list-start title-start tabs-start] 350px [room-list-end title-end tabs-end map-start] 20px [filter-start controls-start room-information-start] 400px [room-information-end] 1fr [controls-end filter-end] 20px [map-end];
  grid-area: fullbody / fullbleed;
  grid-template-rows:
    [room-list-start map-start] 16px [filter-start] var(--filter-height)
    [filter-end] calc(var(--filter-height) - 16px)
    [title-start] var(--title-height-minus-filters) [title-end tabs-start] var(--tabs-height) [tabs-end room-information-start] 1fr [controls-start] var(
      --controls-height
    )
    [controls-end room-information-end] 20px [map-end room-list-end];

  ${BREAKPOINTS.large`
    grid-template-columns: [room-list-start room-information-start title-start tabs-start] 350px [ room-information-end room-list-end title-end tabs-end map-start] 20px [filter-start controls-start] 1fr [controls-end filter-end] 20px [map-end];
    grid-template-rows: [room-list-start map-start] 16px [filter-start] var(--filter-height)
    [filter-end] calc(var(--filter-height) - 16px) [ title-start room-information-start] var(--title-height-minus-filters) [title-end tabs-start] var(--tabs-height) [tabs-end] 1fr [controls-start] var(--controls-height) [controls-end] 20px [map-end room-information-end room-list-end];
  `}

  ${BREAKPOINTS.small`
    --horizontal-room-list-height: 100px;
    height: calc(var(--100vh, 100vh) - 64px - 60px);
    grid-template-columns: [calendar-sync-tile-start room-list-start room-information-start map-start room-list-horizontal-start tabs-start] 20px [filter-start controls-start] 1fr [controls-end filter-end] 20px [room-information-end room-list-end map-end room-list-horizontal-end calendar-sync-tile-end];
    grid-template-rows: [room-list-start calendar-sync-tile-start] var(--calendar-sync-tile-height) [calendar-sync-tile-end tabs-start] var(--tabs-height) [tabs-end map-start room-information-start] 16px [filter-start] var(--filter-height) [filter-end] calc(var(--100vh, 100vh) - var(--calendar-sync-tile-height) - var(--tabs-height) - 40px - 16px - 40px - 16px - 100px - 16px - 64px - 60px) [controls-start] var(--controls-height) [controls-end] 16px [room-list-horizontal-start] var(--horizontal-room-list-height, 100px) [room-list-horizontal-end] 16px [map-end room-information-end room-list-end];
    isolation: isolate;
   `}
`;
