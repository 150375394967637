import {useEffect, useState} from 'react';

/**
 * Tracks window size and returns an object containing width and height of window.
 */

export const useWindowSize = () => {
  const [size, setSize] = useState({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    const trackWindowSize = () => {
      setSize({width: window.innerWidth, height: window.innerHeight});
    };

    window.addEventListener('resize', trackWindowSize);

    return () => window.removeEventListener('resize', trackWindowSize);
  }, []);

  return size;
};
