import {Illustration, Loader} from '@atoms';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {
  getBuildingById,
  getRoomSeatFilterById,
  getFloorById,
  withAsyncThunkErrorHandling,
  loadAvailableMeetingRooms,
  getAvailableMeetingRooms,
  getMeetingUpdateLoadingStatus,
  MeetingRoom,
} from '@lib/store';
import {Button, Chip, SwiperHorizontal, SwiperRefType} from '@molecules';
import {CreateEventRoomCellItem, CreateEventRoomFiltersGroup, ModalPage} from '@organisms';
import {FlexCol, P} from '@quarks';
import {parseISO} from 'date-fns';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CreateEventManualRoomSelectionCardFilterType, CreateEventManualRoomSelectionCardProps} from './types';
import {byProp, numericCompareAsc} from '@lib/utils';

export const CreateEventManualRoomSelectionCard = ({
  defaultBuildingId,
  defaultRooms,
  endDateTime,
  onClose,
  onSelect,
  selectedRooms,
  startDateTime,
  title = 'screen:AdHocRoomSelection',
}: CreateEventManualRoomSelectionCardProps) => {
  const {removePages, addPages, modalPages} = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const ref = useRef<SwiperRefType | null>(null);

  const [floorId, setFloorId] = useState<string | undefined>();
  const [seatId, setSeatId] = useState<string | undefined>();
  const [buildingId, setBuildingId] = useState<string>(defaultBuildingId);

  const isUpdatingEvent = useAppSelector(getMeetingUpdateLoadingStatus) === 'Loading';
  const {name: buildingFilterLabel = ''} = useAppSelector((state) => getBuildingById(state, buildingId))!;

  const selectedSeatFilter = useAppSelector(getRoomSeatFilterById(seatId ?? ''));

  const {name: floorFilterLabel = t('meeting:FilterRoomsFloor')} =
    useAppSelector((state) => getFloorById(state, floorId ?? '')) ?? {};
  const {name: activeFilterSeats} = useAppSelector(getRoomSeatFilterById(seatId ?? '')) ?? {};

  const seatLabel = t('meeting:SeatFilterLabel', {rangeOfSeats: activeFilterSeats});

  const roomsFiltered = useAppSelector((state) => getAvailableMeetingRooms(state));

  const roomsDataSet = buildingId === defaultBuildingId && defaultRooms.length > 0 ? defaultRooms : roomsFiltered;

  const modalPageTitle = t(title);

  const selectableRooms = roomsDataSet
    .filter((meetingRoom) => !selectedRooms.includes(meetingRoom.email))
    .filter(
      (meetingRoom) =>
        (!selectedSeatFilter?.minSeats || meetingRoom.capacity >= selectedSeatFilter?.minSeats) &&
        (!selectedSeatFilter?.maxSeats || meetingRoom.capacity <= selectedSeatFilter?.maxSeats),
    )
    .filter((meetingRoom) => !floorId || meetingRoom.floorId === floorId)
    .sort(byProp('capacity', numericCompareAsc));

  useEffect(() => {
    const loadRooms = async () => {
      setIsLoading(true);
      await dispatch(
        withAsyncThunkErrorHandling(() =>
          loadAvailableMeetingRooms({
            startDateTime: parseISO(startDateTime),
            endDateTime: parseISO(endDateTime),
            buildingId: buildingId,
          }),
        ),
      );

      setIsLoading(false);
    };

    if (buildingId !== defaultBuildingId || defaultRooms.length === 0) loadRooms();
  }, [buildingId, dispatch, defaultBuildingId, defaultRooms, startDateTime, endDateTime]);

  const handleSelectRoom = (room: MeetingRoom) => {
    onSelect(room);
  };

  const applyFilters = (value: Map<CreateEventManualRoomSelectionCardFilterType, string | undefined>) => {
    removePages(1);
    setFloorId(value.get('floor'));
    setSeatId(value.get('seat'));
    setBuildingId(value.get('building')!);
    // Swiper won't update the translations properly because of the removePages, thus we need to update it
    // manually after the page change.
    setTimeout(() => {
      ref.current?.update();
    }, 200);
  };

  return (
    <ModalPage
      onBack={modalPages.length === 1 ? undefined : () => removePages(1)}
      onClose={onClose}
      title={modalPageTitle}>
      <SwiperHorizontal ref={ref}>
        <Button
          button="secondary"
          iconRight="filter"
          onClick={() => {
            addPages([
              <CreateEventRoomFiltersGroup
                onClose={onClose}
                onBack={() => {
                  removePages(1);
                }}
                defaultBuildingId={defaultBuildingId}
                onSelect={applyFilters}
                selectedFloorId={floorId}
                selectedSeatId={seatId}
                selectedBuildingId={buildingId}
              />,
            ]);
          }}>
          {t('workplace:Filter')}
        </Button>
        <Chip
          chip={'tertiary'}
          square>
          {buildingFilterLabel}
        </Chip>
        {seatId && (
          <Chip
            chip={'tertiary'}
            onDelete={() => setSeatId(undefined)}
            square>
            {seatLabel}
          </Chip>
        )}
        {floorId && (
          <Chip
            chip={'tertiary'}
            onDelete={() => setFloorId(undefined)}
            square>
            {floorFilterLabel}
          </Chip>
        )}
      </SwiperHorizontal>
      <FlexCol
        gap={16}
        paddingTop={16}>
        {isLoading ? (
          <FlexCol alignItems="center">
            <Loader />
          </FlexCol>
        ) : selectableRooms.length === 0 ? (
          <FlexCol alignItems="center">
            <Illustration
              illustration="noResults"
              size="100px"
              aria-label={''}
            />
            <P>{t('meeting:CouldntFindMeetingRooms')}</P>
          </FlexCol>
        ) : (
          selectableRooms.map((room) => (
            <CreateEventRoomCellItem
              key={room.id}
              onClose={onClose}
              room={room}
              disabled={isUpdatingEvent}
              onClick={() => handleSelectRoom(room)}
            />
          ))
        )}
      </FlexCol>
    </ModalPage>
  );
};
