import {MapType} from '@lib/store';
import {useEffect, useState} from 'react';

export type MapTypePreference = MapType;

const DEFAULT_PREFERENCE: MapTypePreference = '2d';
const MAP_PREFERENCE_KEY = 'MAPIQ_MAP_TYPE_PREFERENCE';

const getFromStorage = (): MapTypePreference => {
  const storedValue = localStorage.getItem(MAP_PREFERENCE_KEY);

  if (storedValue === '2d') return '2d';
  if (storedValue === '3d') return '3d';

  return DEFAULT_PREFERENCE;
};

const writeToStorage = (pref: MapTypePreference) => {
  localStorage.setItem(MAP_PREFERENCE_KEY, pref);
};

export const useGlobalMapTypePreference = () => {
  const [preference, setPreference] = useState(getFromStorage());

  useEffect(
    function syncToLocalStorage() {
      writeToStorage(preference);
      window.dispatchEvent(new Event('storage'));
    },
    [preference],
  );

  useEffect(function setupSyncFromLocalStorage() {
    const syncFromLocalStorage = () => {
      const value = getFromStorage();
      setPreference(value);
    };

    window.addEventListener('storage', syncFromLocalStorage);

    return function disposeStorageListener() {
      window.removeEventListener('storage', syncFromLocalStorage);
    };
  }, []);

  return [preference, setPreference] as const;
};
