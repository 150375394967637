import {SwiperHorizontal} from '@molecules';
import styled from 'styled-components';

export const StyledSwiperHorizontal = styled(SwiperHorizontal)`
  &.swiper {
    & > div.swiper-wrapper {
      padding: 0;
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
