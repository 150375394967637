import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FilterProps} from './types';

import {Button, Input} from '@molecules';
import {FlexCol, H3, Span} from '@quarks';

import {StyledInputLabel} from './styles';

const MAX_RESULT = 3;

/** Remove this flag for story where we add see more / see less to filters */
const LOCAL_FLAG_SHOW_MORE_ON_FILTERS = false;

export const Filter = ({items, onSelect, selectedId, title, type}: FilterProps) => {
  const {t} = useTranslation();
  const isExpandable = items.length > MAX_RESULT;
  const [isExpanded, setIsExpanded] = useState(false);

  const subset = LOCAL_FLAG_SHOW_MORE_ON_FILTERS && isExpandable && !isExpanded ? items.slice(0, MAX_RESULT) : items;

  if (items.length <= 1) return <></>;

  return (
    <FlexCol
      alignItems="flex-start"
      key={title}
      gap={32}>
      <H3 as="h2">{title}</H3>
      {subset.map(({id, label}) => (
        <StyledInputLabel
          id={title}
          key={id}>
          <Span>{label}</Span>
          <Input
            type={type}
            name={title}
            value={id}
            checked={selectedId === id}
            onChange={() => {
              onSelect(id);
            }}
          />
        </StyledInputLabel>
      ))}
      {LOCAL_FLAG_SHOW_MORE_ON_FILTERS && (
        <Button
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
          button="text">
          {isExpanded ? t('SeeLess') : t('SeeMore')}
        </Button>
      )}
    </FlexCol>
  );
};
