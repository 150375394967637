import {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {startOfToday} from 'date-fns';
import nth from 'lodash/nth';

import {useAppDispatch, useAppSelector, useDestructiveDialog, useFeatureToggle, useWindowSize} from '@hooks';

import {
  type MeetingRoom,
  getBuildingByCheckInOrWorkspaceReservation,
  getHereAndNowRoomsWithFilter,
  getRoomSeatFilterById,
  getFloorsWithMapByBuildingId,
  getUser,
  loadAllMeetingRooms,
  withAsyncThunkErrorHandling,
  getFloorById,
  getShouldShowHereAndNowConnectYourCalendarFullScreen,
  getDisplayCalendarSyncTile,
  type Area,
  getShouldShowHereAndNow,
  getAreasByBuildingId,
  getIsLoadingCalendarState,
  getAllMeetingRoomLoadingStatus,
  getIsLoadingUserCheckIn,
  getIsLoadingWorkspaceReservations,
  getIsOccupancyEnabled,
  loadWorkspaceOccupancy,
  getWorkspaceOccupancyLoadingStatusForBuildingId,
} from '@lib/store';

import {addMinutes, parseISO, startOfDay, startOfMinute, differenceInMinutes} from 'date-fns';

import {BREAKPOINT_SIZES, DEFAULT_NEW_EVENT_DURATION as DURATION, MINUTES_TRESHOLD_FOR_STALE_DATA} from '@constants';
import {Div, FlexCol, H3} from '@quarks';
import {
  HaNControls,
  HaNMaps,
  HaNModalContainer,
  HaNFilters,
  HaNHorizontalListContent,
  HaNVerticalListContent,
  isMeetingRoom,
  HaNCalendarSyncTile,
  HaNCalendarSync,
} from '@organisms';
import {Canvas, Heading, HereAndNowLayout} from '@templates';

import {AreaEventTarget, RoomEventTarget} from '../../../../../../submodules/map/mapiq-map/EventTarget';
import {ControlsRow, HaNPageHeader, MapWrapper, RoomListGridArea, StyledNavLink, StyledTabs, Title} from './styles';
import {SwiperRefType} from '@molecules';
import {Navigate, useParams} from 'react-router-dom';
import {Loader} from '@atoms';

const initialStartDateTime = startOfMinute(new Date()).toISOString();
const initialEndDateTime = addMinutes(parseISO(initialStartDateTime), 30).toISOString();

export const HereAndNow = () => {
  // * UI
  const ref = useRef<SwiperRefType | null>(null);
  const {t} = useTranslation();
  const {width} = useWindowSize();
  const {tab} = useParams();
  const {destructiveDialog} = useDestructiveDialog();

  // * FILTERS
  const [floorId, setFloorId] = useState<string | undefined>();
  const [duration, setDuration] = useState<number>(DURATION);
  const [seatFilterId, setSeatFilterId] = useState<string | undefined>();
  const [{startDateTime, endDateTime}, setRoomDateTimes] = useState({
    startDateTime: initialStartDateTime,
    endDateTime: initialEndDateTime,
  });
  const occupancyLoadedDateTimeRef = useRef<Date | undefined>();

  // * STATE
  const [roomInformationOpen, setRoomInformationOpen] = useState(false);
  const [warnBeforeOpeningNewRoom, setWarnBeforeOpeningNewRoom] = useState(false);
  const [hoverRoomId, setHoverRoomId] = useState<string | null>(null);
  const [selectedObject, setSelectedObject] = useState<MeetingRoom | Area | null>(null);
  const [selectedObjectForMap, setSelectedObjectForMap] = useState<MeetingRoom | Area | null>(null);

  // * REDUX SELECTORS
  const dispatch = useAppDispatch();
  const {id: buildingId = ''} =
    useAppSelector((state) =>
      getBuildingByCheckInOrWorkspaceReservation(state, startOfDay(new Date()).toISOString()),
    ) ?? {};

  const title =
    useAppSelector((state) => getBuildingByCheckInOrWorkspaceReservation(state, startOfToday().toISOString()))?.name ||
    t('screen:HereAndNow');
  const isHereAndNowEnabled = useAppSelector(getShouldShowHereAndNow);
  const minutesThresholdForStaleData = 5;
  const showCalendarConsentMessageNoOccupancy = useAppSelector(getShouldShowHereAndNowConnectYourCalendarFullScreen);
  const displayCalendarSyncTile = useAppSelector(getDisplayCalendarSyncTile);
  const {WorkspacesOnHereAndNow: isWorkspacesOnHereAndNowEnabled} = useFeatureToggle();
  const calendarLoadingStatus = useAppSelector(getIsLoadingCalendarState);
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const loadingCalendar = calendarLoadingStatus === 'Loading' || calendarLoadingStatus === 'NotLoaded';
  const occupancyLoadingStatus = useAppSelector((state) =>
    getWorkspaceOccupancyLoadingStatusForBuildingId(state, buildingId),
  );
  const isLoadingSensors = occupancyLoadingStatus === 'loading' && isOccupancyEnabled;
  const meetingRoomLoadingStatus = useAppSelector(getAllMeetingRoomLoadingStatus);
  const isLoadingUserCheckIn = useAppSelector(getIsLoadingUserCheckIn);
  const isLoadingWorkspaceReservations = useAppSelector(getIsLoadingWorkspaceReservations);
  const isLoadingBuilding = isLoadingUserCheckIn || isLoadingWorkspaceReservations;
  const loadingMeetingRooms = meetingRoomLoadingStatus === 'Loading' || meetingRoomLoadingStatus === 'NotLoaded';
  const isLoading = loadingCalendar || loadingMeetingRooms || isLoadingSensors;
  const selectedSeatFilter = useAppSelector(getRoomSeatFilterById(seatFilterId ?? ''));
  const filtersObject = useMemo(
    () => ({
      floorId: floorId,
      minSeats: selectedSeatFilter?.minSeats,
      maxSeats: selectedSeatFilter?.maxSeats,
    }),
    [floorId, selectedSeatFilter?.maxSeats, selectedSeatFilter?.minSeats],
  );

  const rooms = useAppSelector((state) => getHereAndNowRoomsWithFilter(state, filtersObject));
  const rawAreas = useAppSelector((state) => getAreasByBuildingId(state, buildingId));
  const areas = useMemo(() => rawAreas.filter((area) => area.capacity > 0), [rawAreas]);
  const mapFloorId = selectedObjectForMap?.floorId
    ? selectedObjectForMap.floorId
    : tab === 'rooms'
    ? rooms[0]?.floorId
    : areas[0]?.floorId || null;
  const {id: loggedInUserId} = useAppSelector(getUser);
  const floorsWithMap = useAppSelector((state) => getFloorsWithMapByBuildingId(state, buildingId));
  const mapFloor = useAppSelector((state) => getFloorById(state, mapFloorId || ''));
  const [roomListVerticalOpen, setRoomListVerticalOpen] = useState(floorsWithMap.length ? false : true);

  // * CONSTS

  const displayControlsRow =
    !loadingMeetingRooms && floorsWithMap.length && rooms.length && mapFloor && !showCalendarConsentMessageNoOccupancy;

  const paddingLeftPixels = width >= BREAKPOINT_SIZES.large ? 420 : 0; // Width of a modal
  const paddingBottomPixels = width <= BREAKPOINT_SIZES.small ? 100 : 0; // Height of a horizontal rooms list

  const viewportRestrictions = useMemo(
    () => ({
      paddingTopPixels: 80, // Filters height and padding
      paddingRightPixels: 0,
      paddingBottomPixels: paddingBottomPixels,
      paddingLeftPixels,
    }),
    [paddingLeftPixels, paddingBottomPixels],
  );

  // * FUNCS

  const loadHereAndNowRooms = useCallback(() => {
    const loadRooms = async () => {
      await dispatch(
        withAsyncThunkErrorHandling(() =>
          loadAllMeetingRooms({
            startDateTime: newStartDateTime,
            endDateTime: newEndDateTime,
            buildingId: buildingId,
            internalAttendeeIds: [loggedInUserId],
            totalAttendees: 1,
          }),
        ),
      );
    };

    const newStartDateTime = startOfMinute(new Date()).toISOString();
    const newEndDateTime = addMinutes(parseISO(newStartDateTime), duration).toISOString();

    setRoomDateTimes({startDateTime: newStartDateTime, endDateTime: newEndDateTime});

    if (buildingId && duration && loggedInUserId) {
      loadRooms();
    }
  }, [buildingId, dispatch, duration, loggedInUserId]);

  const fetchOccupancySensorData = useCallback(() => {
    if (!isLoadingBuilding && buildingId) {
      dispatch(withAsyncThunkErrorHandling(() => loadWorkspaceOccupancy(buildingId)));

      occupancyLoadedDateTimeRef.current = startOfMinute(new Date());
    }
  }, [buildingId, dispatch, isLoadingBuilding]);

  const refreshRoomsData = () => {
    if (buildingId && loggedInUserId) {
      const now = startOfMinute(new Date());
      if (differenceInMinutes(now, parseISO(startDateTime)) >= MINUTES_TRESHOLD_FOR_STALE_DATA) {
        loadHereAndNowRooms();
      }
    }
  };

  const toggleListOrMapView = () => {
    if (!roomListVerticalOpen) {
      ref.current?.scrollToSlide(0);
    }
    setRoomListVerticalOpen(!roomListVerticalOpen);
  };

  const openObjectInformation = async (entity: MeetingRoom | Area | undefined) => {
    // Some of the areas have capacity of 0 which do not get fetched
    // because of that they will not be found and we need not handle possible undefined value
    if (!entity) {
      setSelectedObjectForMap(null);
      setSelectedObject(null);
      return;
    } else if (isMeetingRoom(entity)) {
      if (warnBeforeOpeningNewRoom) {
        const confirmation = await destructiveDialog(
          t('meeting:DiscardEventChangesTitle'),
          t('meeting:DiscardEventChangesMessage'),
          'warning',
          t('meeting:DiscardEventChangesConfirm'),
        );
        if (confirmation) {
          setSelectedObjectForMap(entity);
          setSelectedObject(entity);
          setRoomInformationOpen(true);
        }
      } else {
        setSelectedObjectForMap(entity);
        setSelectedObject(entity);
        setRoomInformationOpen(true);
      }
    } else {
      setSelectedObject(entity);
      setSelectedObjectForMap(entity);
      setRoomInformationOpen(true);
    }
  };

  const closeRoomInformation = () => {
    setRoomInformationOpen(false);
    if (width > BREAKPOINT_SIZES.small) {
      setSelectedObject(null);
    }
  };

  const resetListState = () => {
    if (width <= BREAKPOINT_SIZES.small) {
      ref.current?.scrollToSlide(0);
    }
  };

  const resetEntityState = (tabValue?: string) => {
    if (width <= BREAKPOINT_SIZES.small) {
      const objectToSelect = tabValue === 'rooms' ? rooms[0] : areas[0];
      setRoomInformationOpen(false);
      setSelectedObject(objectToSelect);
      setSelectedObjectForMap(objectToSelect);
    } else {
      setRoomInformationOpen(false);
      setSelectedObject(null);
      setSelectedObjectForMap(null);
    }
  };

  const handleRoomClick = (e: RoomEventTarget | AreaEventTarget) => {
    if (width <= BREAKPOINT_SIZES.small) {
      if (e.type === 'room') {
        ref?.current?.scrollToSlide(rooms.findIndex((room) => room.id === e.roomId));
      } else {
        ref?.current?.scrollToSlide(areas.findIndex((area) => area.id === e.areaId));
      }
    } else {
      if (e.roomId) {
        const clickedRoom = rooms.find((room) => room.id === e.roomId);
        openObjectInformation(clickedRoom);
      } else if (e.areaId) {
        const clickedArea = areas.find((area) => area.id === e.areaId);
        openObjectInformation(clickedArea);
      }
    }
  };

  const handleOnSlideChange = (visibleObject: MeetingRoom | Area) => {
    setSelectedObject(visibleObject);
    setSelectedObjectForMap(visibleObject);
  };

  const handleBookNow = () => {
    if (selectedObject !== null) {
      if (isMeetingRoom(selectedObject) && width <= BREAKPOINT_SIZES.small) {
        const currentIndex = rooms.indexOf(selectedObject!);
        const nextRoomValue = nth(rooms, currentIndex + 1) ?? nth(rooms, currentIndex - 1) ?? null;
        setSelectedObjectForMap(nextRoomValue);
        setSelectedObject(nextRoomValue);
      }
    }
  };

  // * SIDEEFFECTS

  useEffect(() => {
    // We are currently fetching sensor data even when on rooms tab,
    // looking to optimise this in the future with reduxQuery.

    if (!isOccupancyEnabled) return;

    const now = startOfMinute(new Date());

    if (
      !occupancyLoadedDateTimeRef.current ||
      differenceInMinutes(now, occupancyLoadedDateTimeRef.current) >= minutesThresholdForStaleData
    ) {
      fetchOccupancySensorData();
    }
  }, [isOccupancyEnabled, fetchOccupancySensorData]);

  useEffect(() => {
    setRoomListVerticalOpen(floorsWithMap.length ? false : true);
  }, [floorsWithMap]);

  useEffect(() => loadHereAndNowRooms(), [loadHereAndNowRooms]);

  useEffect(() => {
    const objectToSelectInitially = tab === 'workspaces' ? areas[0] ?? null : rooms[0] ?? null;
    if (width <= BREAKPOINT_SIZES.small) {
      // On mobile we need to pre-select a room, but not on larger screens
      setSelectedObject(objectToSelectInitially);
    } else {
      setSelectedObject(null);
    }
    setSelectedObjectForMap(objectToSelectInitially);
  }, [rooms, areas, width, tab]);

  // * UI

  if (isLoading) {
    return (
      <Canvas centerContent>
        <Loader />
      </Canvas>
    );
  }

  if (!isHereAndNowEnabled) {
    return <Navigate to="/fourohfour" />;
  }

  if (!tab && isWorkspacesOnHereAndNowEnabled) {
    return <Navigate to="/now/rooms" />;
  }

  if (tab !== 'rooms' && tab !== 'workspaces' && tab) {
    return <Navigate to="/fourohfour" />;
  }

  if (!isWorkspacesOnHereAndNowEnabled && tab) {
    return <Navigate to="/fourohfour" />;
  }

  return (
    <>
      <Heading>
        <HaNPageHeader
          {...(floorsWithMap.length && {buttonIcon: roomListVerticalOpen ? 'map' : 'weekView'})}
          {...(floorsWithMap.length && {
            buttonLabel: roomListVerticalOpen ? t('hereAndNow:Map') : t('hereAndNow:ListOfRooms'),
          })}
          showButton="mobile"
          onClick={toggleListOrMapView}
          title={title}
        />
      </Heading>
      <HereAndNowLayout
        $displayCalendarSyncTile={displayCalendarSyncTile}
        $areWorkspacesEnabled={isWorkspacesOnHereAndNowEnabled}>
        <MapWrapper>
          <HaNMaps
            buildingId={buildingId}
            floorId={mapFloorId}
            floorsWithMap={floorsWithMap}
            hoverObjectId={hoverRoomId}
            onObjectClick={handleRoomClick}
            rooms={rooms}
            workspaces={areas}
            selectedObject={selectedObject}
            viewportRestrictions={viewportRestrictions}
          />
        </MapWrapper>
        {displayCalendarSyncTile && (
          <Div
            display="none"
            sm={{display: 'grid', gridArea: 'calendar-sync-tile', zIndex: 1}}>
            <HaNCalendarSyncTile />
          </Div>
        )}
        {isWorkspacesOnHereAndNowEnabled && (
          <Div
            gridArea="tabs"
            display="none"
            zIndex={10}
            sm={{
              display: roomListVerticalOpen ? 'none' : 'block',
            }}>
            <StyledTabs>
              <StyledNavLink
                onClick={() => {
                  resetEntityState('rooms');
                  refreshRoomsData();
                }}
                $isActive={tab === 'rooms'}
                to={'/now/rooms'}>
                {t('Rooms')}
              </StyledNavLink>
              <StyledNavLink
                onClick={() => resetEntityState('workspaces')}
                $isActive={tab === 'workspaces'}
                to={'/now/workspaces'}>
                {t('Workspaces')}
              </StyledNavLink>
            </StyledTabs>
          </Div>
        )}
        {!showCalendarConsentMessageNoOccupancy && tab !== 'workspaces' ? (
          <Div
            gridArea="filter"
            zIndex={1}
            overflowX="hidden">
            <HaNFilters
              buildingId={buildingId}
              duration={duration}
              setDuration={setDuration}
              floorId={floorId}
              setFloorId={setFloorId}
              seatFilterId={seatFilterId}
              setSeatFilterId={setSeatFilterId}
              areFiltersTransparent={false}
              onRemoveFilter={resetListState}
              onApplyFiltersCallback={() => {
                closeRoomInformation();
                resetListState();
              }}
            />
          </Div>
        ) : null}
        {displayControlsRow ? (
          <ControlsRow
            alignItems="center"
            gridArea="controls"
            justifyContent="flex-end"
            gap={8}
            zIndex={1}>
            <HaNControls floor={mapFloor?.number} />
          </ControlsRow>
        ) : null}
        <Div
          gridArea={
            showCalendarConsentMessageNoOccupancy
              ? 'controls / room-list-horizontal / room-list-horizontal / room-list-horizontal'
              : 'room-list-horizontal'
          }
          display="none"
          sm={{display: 'grid'}}>
          <HaNHorizontalListContent
            showCalendarConsentMessageNoOccupancy={showCalendarConsentMessageNoOccupancy}
            endDateTime={endDateTime}
            onClick={openObjectInformation}
            onSlideChange={handleOnSlideChange}
            rooms={rooms}
            startDateTime={startDateTime}
            ref={ref}
            areas={areas}
          />
        </Div>
        <RoomListGridArea
          gridArea="room-list"
          overflow="hidden"
          zIndex={2}
          height="calc(var(--100vh, 100vh) - 60px)"
          $roomListVerticalOpen={roomListVerticalOpen}>
          <Div
            position="relative"
            top="0"
            zIndex={1}
            left="0">
            {displayCalendarSyncTile && (
              <Div sm={{display: 'grid', gridArea: 'calendar-sync-tile', zIndex: 1}}>
                <HaNCalendarSyncTile />
              </Div>
            )}
            <Div
              sm={{display: 'none'}}
              zIndex={10}
              gridArea="title">
              <Title>
                <H3 as="h1">{title}</H3>
              </Title>
            </Div>
            {isWorkspacesOnHereAndNowEnabled && (
              <StyledTabs
                gridArea="tabs"
                zIndex={10}>
                <StyledNavLink
                  $isActive={tab === 'rooms'}
                  onClick={() => {
                    resetEntityState('rooms');
                    refreshRoomsData();
                  }}
                  to={'/now/rooms'}>
                  {t('Rooms')}
                </StyledNavLink>
                <StyledNavLink
                  $isActive={tab === 'workspaces'}
                  onClick={() => resetEntityState('workspaces')}
                  to={'/now/workspaces'}>
                  {t('Workspaces')}
                </StyledNavLink>
              </StyledTabs>
            )}
          </Div>
          <Div
            display="none"
            sm={{display: 'block', padding: '16px 20px'}}>
            <HaNFilters
              buildingId={buildingId}
              duration={duration}
              setDuration={setDuration}
              floorId={floorId}
              setFloorId={setFloorId}
              seatFilterId={seatFilterId}
              setSeatFilterId={setSeatFilterId}
              areFiltersTransparent={true}
              onRemoveFilter={resetListState}
              onApplyFiltersCallback={() => {
                closeRoomInformation();
                resetListState();
              }}
            />
          </Div>
          <FlexCol
            sm={{display: !roomListVerticalOpen ? 'none' : 'flex'}}
            overflow="hidden auto"
            flex="auto"
            zIndex={2}>
            {showCalendarConsentMessageNoOccupancy ? (
              <HaNCalendarSync />
            ) : (
              <HaNVerticalListContent
                endDateTime={endDateTime}
                onClick={openObjectInformation}
                onHover={setHoverRoomId}
                rooms={rooms}
                selectedObject={selectedObject}
                startDateTime={startDateTime}
                areas={areas}
              />
            )}
          </FlexCol>
        </RoomListGridArea>
      </HereAndNowLayout>

      <HaNModalContainer
        setWarnBeforeOpeningNewRoom={setWarnBeforeOpeningNewRoom}
        onClose={closeRoomInformation}
        entity={selectedObject}
        show={roomInformationOpen}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        duration={duration}
        onBookNowCallback={handleBookNow}
      />
    </>
  );
};
