import {KeyboardEvent} from 'react';

import {FlexRow} from '@quarks';
import {ChipFilterProps} from './types';
import {StyledButton, StyledChip} from './styles';

export const ChipFilter = ({chips, onClick: handleClick, buttonLabel, testIdPrefix = ''}: ChipFilterProps) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <FlexRow
      sm={{flexWrap: 'nowrap'}}
      flexWrap="wrap"
      justifyContent="flex-start"
      width="100%"
      gap={8}>
      <StyledButton
        button={'secondary'}
        iconRight="filter"
        data-testid={testIdPrefix ? `${testIdPrefix}_chip-filter_button` : null}
        onKeyDown={handleKeyDown}
        onClick={handleClick}>
        {buttonLabel}
      </StyledButton>
      {chips.map(
        (chip) =>
          chip && (
            <StyledChip
              chip="tertiary"
              key={`chip-filter_${chip.id}`}
              data-testid={testIdPrefix ? `${testIdPrefix}_chip-filter_chip` : null}
              onDelete={chip.onDelete}
              square={true}>
              {chip.label}
            </StyledChip>
          ),
      )}
    </FlexRow>
  );
};
