import {useEffect} from 'react';

import {Routes, Route} from 'react-router-dom';

import {getIsSignedIn, getUser, loadAppData, loadNotifications, withAsyncThunkErrorHandling} from '@lib/store';
import {useAppSelector, useAppDispatch, useDetectRedirect, useCalculateCssVhVariable, useFeatureToggle} from '@hooks';

import {Layout} from '@templates';

import {Dialog, Footer, Header, Modal, SidePanel} from '@organisms';
import {Calendar, Connections, Notifications, NotFound, Onboarding, PrivateRoute, Profile, HereAndNow} from '@pages';
import {Toaster} from '@molecules';
import {AppInsights} from './config/AppInsights';
import {useMsal} from '@azure/msal-react';
import {useShowErrorToUser} from './hooks/useShowErrorToUser';
import * as IntercomServices from '@utils';

export const App = () => {
  const dispatch = useAppDispatch();
  const isSignedIn = useAppSelector(getIsSignedIn);
  const {instance} = useMsal();
  const signedInUser = instance.getActiveAccount();
  const subId = signedInUser?.idTokenClaims?.subscription_id || 'failed-to-get-sub-id-from-token';
  const userId = signedInUser?.idTokenClaims?.sub || 'failed-to-get-user-id-from-token';
  const {Intercom: isIntercomEnabled} = useFeatureToggle();
  const user = useAppSelector(getUser);
  const tokenClaims = signedInUser?.idTokenClaims;

  useDetectRedirect();
  useCalculateCssVhVariable();
  // Handle errors thrown by api calls
  useShowErrorToUser();

  useEffect(() => {
    if (isSignedIn) {
      AppInsights.setAuthenticatedUserContext(userId, subId as string);
      if (isIntercomEnabled) {
        IntercomServices.login(tokenClaims, user);
      }
    } else {
      AppInsights.clearAuthenticatedUserContext();
      if (isIntercomEnabled) {
        IntercomServices.logout();
      }
    }
  }, [isSignedIn, subId, userId, isIntercomEnabled, tokenClaims, user]);

  useEffect(() => {
    if (isSignedIn) dispatch(withAsyncThunkErrorHandling(() => loadAppData()));
  }, [dispatch, isSignedIn]);

  useEffect(() => {
    // This is the initial load of the notifications to avoid flickering
    // of the notification route when the side panel is openned
    // false is for the isPollMode param
    if (isSignedIn) dispatch(withAsyncThunkErrorHandling(() => loadNotifications()));
  }, [dispatch, isSignedIn]);

  return (
    <Layout>
      <Header />

      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<Onboarding />}>
            <Route
              path="/"
              element={<Calendar />}>
              <Route
                path="/:date"
                element={<Calendar />}
              />
            </Route>
            <Route
              path="now"
              element={<HereAndNow />}
            />
            <Route
              path="now/:tab"
              element={<HereAndNow />}
            />
            <Route
              path="connections"
              element={<Connections />}
            />
            <Route
              path="notifications"
              element={<Notifications />}
            />
            <Route
              path="profile"
              element={<Profile />}
            />
          </Route>
          <Route
            path="fourohfour"
            element={<NotFound />}
          />
        </Route>
      </Routes>

      <Footer />

      <SidePanel />
      <Modal />
      <Dialog />
      <Toaster />
    </Layout>
  );
};
