import {
  compareByMeetingResponse,
  compareByTemplateWithStringFallback,
  MeetingAttendee,
  MeetingAttendeeType,
  MeetingRoom,
} from '@lib/store';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';

export const sortAttendeesByLocation = (
  attendees: MeetingAttendee[],
  currentUser: MeetingAttendee | undefined,
  defaultBuildingId: string,
  rooms: Record<string, MeetingRoom[]>,
) => {
  const attendeesGroupedByLocation = groupBy(attendees, (attendee) => attendee.locationType);

  const localAttendeesGroupedByLocation = map(
    groupBy(attendeesGroupedByLocation.Local, (meetingAttendee) => meetingAttendee.shiftBuildingId),
    (meetingAttendees, key) => ({
      attendees: meetingAttendees.sort(compareByMeetingResponse),
      buildingId: key,
      id: key,
      locationDescription: meetingAttendees[0].shiftBuildingName,
      locationType: MeetingAttendeeType.Local,
    }),
  );

  const generalAttendeesGroupedByLocation = map(
    pickBy(attendeesGroupedByLocation, (_item, key) => key !== 'Local'),

    (meetingAttendees, key) => ({
      attendees: meetingAttendees.sort(compareByMeetingResponse),
      buildingId: undefined,
      id: key,
      locationDescription: key,
      locationType: key as MeetingAttendeeType,
    }),
  );

  const buildingIdsForMeeting = Object.keys(rooms ?? {});

  const buildingsIdsWithoutAttendees = buildingIdsForMeeting?.filter(
    (buildingId) => !localAttendeesGroupedByLocation?.find((group) => group.buildingId === buildingId),
  );

  const roomOnlyGroups = buildingsIdsWithoutAttendees.map((buildingId) => ({
    attendees: [],
    id: buildingId,
    buildingId,
    locationDescription: rooms[buildingId]?.[0]?.buildingName,
    locationType: MeetingAttendeeType.Local,
  }));

  const locationArray = [...localAttendeesGroupedByLocation, ...generalAttendeesGroupedByLocation, ...roomOnlyGroups];

  const defaultBuildingGroup = locationArray.find((location) => location.buildingId === defaultBuildingId);
  const myBuildingGroup = locationArray.find((location) => location.buildingId === currentUser?.shiftBuildingId);

  const sortingOrder =
    currentUser?.locationType === 'Local'
      ? [myBuildingGroup?.buildingId, defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Remote', 'Unknown']
      : currentUser?.locationType === 'Remote'
      ? ['Remote', defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Unknown']
      : [defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Remote', 'Unknown'];

  const otherBuildingsIndex = sortingOrder.indexOf('OtherBuildings');

  const returnedArray = locationArray.sort((a, b) =>
    compareByTemplateWithStringFallback(sortingOrder, otherBuildingsIndex, a, b),
  );

  return returnedArray;
};
