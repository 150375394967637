import {useModal, useFilterGroup, useAppDispatch} from '@hooks';
import {ClickableCellWrapper, Input} from '@molecules';
import {ModalPage, ModalPageBottom} from '@organisms';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FilterCardProps, Sections, SelectedFilters} from './types';
import xor from 'lodash/xor';
import {StyledContainer, StyledFlexCol, StyledLabel, StyledText} from './styles';
import {Icon, Loader} from '@atoms';
import {pxToRem} from '@utils';
import {FlexCol, FlexRow, H3} from '@quarks';
import difference from 'lodash/difference';
import {useTheme} from 'styled-components';
import {trackEvent} from '@lib/store';

export const FilterCard = <TSections extends Sections, TSelectedFilters extends SelectedFilters<TSections>>({
  onClose,
  title,
  canResetState,
  sections,
  onSelect,
  initialSelection = [],
  testIdPrefix = '',
  resetEventName,
}: FilterCardProps<TSections>) => {
  const {removePages, modalPages, closeModal} = useModal();
  const {t} = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {workspaceSections, isLoading} = useFilterGroup();
  const _sections = sections || workspaceSections;
  const [hasCleared, setHasCleared] = useState(false);

  const _emptySelection = generateSelection<TSelectedFilters>(_sections);
  const [selectionObject, setSelectionObject] = useState(
    generateSelection<TSelectedFilters>(_sections, initialSelection),
  );

  const selectedValues = Object.values(selectionObject)
    .flat()
    .filter((x): x is string => Boolean(x));

  const isDirty =
    selectedValues.length !== initialSelection.length ||
    !!difference(selectedValues.sort(), initialSelection.sort()).length;
  const hasActiveSelection = !!selectedValues.length ?? false;

  const handleModalAction = () => {
    modalPages.length > 1 ? removePages(1) : closeModal();
  };

  const getSelectionData = (sectionId: string, id: string) => {
    const currentSelection = selectionObject[sectionId];

    if (Array.isArray(currentSelection)) {
      return {
        isSelected: currentSelection.includes(id),
        valToSet: xor(currentSelection, [id]),
      };
    }

    return {
      isSelected: currentSelection === id,
      valToSet: id,
    };
  };

  return (
    <ModalPage
      bottom={
        canResetState && (
          <ModalPageBottom
            buttonDisabled={!isDirty}
            buttonLabel={t('translation:Apply')}
            cancelButtonDisabled={!hasActiveSelection}
            cancelButtonLabel={t('translation:Clear')}
            onCancel={() => {
              setSelectionObject(_emptySelection);
              setHasCleared(true);
            }}
            onClick={() => {
              onSelect(selectionObject);
              handleModalAction();

              if (hasCleared && resetEventName) {
                dispatch(trackEvent(resetEventName));
                setHasCleared(false);
              }
            }}
          />
        )
      }
      onBack={modalPages.length > 1 ? () => removePages(1) : undefined}
      onClose={onClose}
      title={title}>
      {isLoading ? (
        <FlexRow
          height={'100%'}
          justifyContent="center"
          alignItems="center">
          <Loader size={pxToRem(24)} />
        </FlexRow>
      ) : (
        <FlexCol
          alignItems="flex-start"
          gap={32}>
          {Object.entries(_sections).map(
            ([sectionId, section]) =>
              !!section.items.length && (
                <StyledContainer
                  key={`filter-section-${sectionId}`}
                  tabIndex={0}>
                  <FlexCol
                    alignItems="flex-start"
                    gap={8}>
                    <H3 as="h2">{section.title}</H3>

                    <StyledFlexCol>
                      {section.items.map(({label, id, icon}) => {
                        const {isSelected, valToSet} = getSelectionData(sectionId, id);

                        const testId = testIdPrefix ? `${testIdPrefix}_${section.type}` : null;

                        return (
                          <ClickableCellWrapper
                            key={id}
                            data-testid={testId}
                            onClick={() => {
                              setSelectionObject((prev) => ({
                                ...prev,
                                [sectionId]: valToSet,
                              }));

                              if (!canResetState) {
                                onSelect(selectionObject);
                                handleModalAction();
                              }
                            }}>
                            <StyledLabel>
                              {!!icon && (
                                <Icon
                                  icon={icon}
                                  size={pxToRem(20)}
                                  color={theme.text.color.subtitle}
                                />
                              )}
                              <StyledText>{label}</StyledText>
                              <Input
                                type={section.type}
                                name="filter"
                                readOnly
                                checked={isSelected}
                              />
                            </StyledLabel>
                          </ClickableCellWrapper>
                        );
                      })}
                    </StyledFlexCol>
                  </FlexCol>
                </StyledContainer>
              ),
          )}
        </FlexCol>
      )}
    </ModalPage>
  );
};

const generateSelection = <TState extends Record<string, string | null | string[]>>(
  sections: Sections,
  initialSelection: string[] = [],
) =>
  Object.entries(sections).reduce((acc, [key, val]) => {
    const filteredItemsByInitialSelection = val.items.filter((item) => initialSelection.includes(item.id));
    return {
      ...acc,
      [key]:
        val.type === 'checkbox'
          ? filteredItemsByInitialSelection?.map(({id}) => id) || []
          : filteredItemsByInitialSelection[0]?.id || null,
    };
  }, {} as TState);
