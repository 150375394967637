import {useState} from 'react';

import {getAppLanguage, getCalendar, getIsLoadingCalendar} from '@lib/store';

import {useAppSelector} from '@hooks';
import {FlexRow, H1} from '@quarks';
import {Loader} from '@atoms';
import {
  CalendarDayOverviewCard,
  CalendarHorizontalWeekOverview,
  CalendarOverviewConnectionFilter,
  CalendarWeek,
  CalendarWeekDay,
} from '@organisms';
import {CalendarDayOverview, CalendarLayout, CalendarWeekOverview, Canvas, Heading} from '@templates';
import {Navigate, useParams} from 'react-router-dom';
import {isValid, parseISO, startOfToday} from 'date-fns';
import {formatDate, isBeforeToday} from '@lib/utils';
import {CalendarHorizontalWeekOverviewWrapper, CalendarPageHeader, OverviewWrapper} from './styles';
import {useTranslation} from 'react-i18next';

export const Calendar = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const isLoading = useAppSelector(getIsLoadingCalendar);
  const calendarWeeks = useAppSelector(getCalendar);
  const {t} = useTranslation();

  const {date} = useParams();
  const today = startOfToday();
  const dateAsString = date ? date : today.toISOString();
  const appLanguage = useAppSelector(getAppLanguage);

  const toggleDayAndWeekView = () => {
    window.scrollTo(0, 0);
    setShowCalendar((prev) => !prev);
  };

  /**
   * Our router needs some help parsing the routes
   * so if we don't get the root URL or a valid
   * date we'll send them to our 404 page
   */
  if (date !== undefined && !isValid(new Date(dateAsString!))) {
    return <Navigate to="/fourohfour" />;
  }

  if (!date || isBeforeToday(parseISO(dateAsString), today)) {
    return <Navigate to={`/${formatDate(today, 'yyyy-MM-dd', 'en')}`} />;
  }
  /**
   * Temporary solution until we implement
   * separate loading states for each part of the
   * calendar
   * */
  if (isLoading) {
    return (
      <>
        <Heading>
          <CalendarPageHeader
            showButton="none"
            title={
              showCalendar ? t('translation:Calendar') : formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)
            }
          />
        </Heading>
        <Canvas centerContent>
          <Loader />
        </Canvas>
      </>
    );
  }

  return (
    <>
      <Heading>
        <CalendarPageHeader
          buttonIcon={!showCalendar ? 'weekView' : 'dayView'}
          buttonLabel="Week view"
          showButton="mobile"
          onClick={toggleDayAndWeekView}
          title={
            showCalendar ? t('translation:Calendar') : formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)
          }
        />
      </Heading>

      <CalendarLayout>
        <CalendarDayOverview $showCalendar={showCalendar}>
          <CalendarHorizontalWeekOverviewWrapper>
            <CalendarHorizontalWeekOverview calendarWeeks={calendarWeeks} />
          </CalendarHorizontalWeekOverviewWrapper>
          <OverviewWrapper>
            <FlexRow
              alignItems="center"
              justifyContent="center"
              sm={{display: 'none'}}>
              <H1 data-testid="pages-Calendar-Calendar_calendarHeader">
                {formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)}
              </H1>
            </FlexRow>
            <CalendarDayOverviewCard date={dateAsString} />
          </OverviewWrapper>
        </CalendarDayOverview>

        <CalendarWeekOverview $showCalendar={showCalendar}>
          <CalendarOverviewConnectionFilter />

          {calendarWeeks.map((data) => (
            <CalendarWeek
              key={`calendarCard-${data.weekNumber}`}
              weekNumber={data.weekNumber}
              weekType={data.weekType}>
              {data.data.map((day) => (
                <CalendarWeekDay
                  date={day.date}
                  key={day.date}
                  isToday={day.isToday}
                  toggleDayAndWeekView={toggleDayAndWeekView}
                />
              ))}
            </CalendarWeek>
          ))}
        </CalendarWeekOverview>
      </CalendarLayout>
    </>
  );
};
