import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {isToday, parseISO} from 'date-fns';
import {ValidationType} from 'mapiq-molecules';

import {CalendarWorkdayCheckInTileProps} from './types';
import {CheckInEventType, CheckInSourceType} from '@lib/infrastructure';

import {
  getCanShowCheckInTile,
  getUserCheckInStatus,
  getWorkdayByDate,
  getBuildingById,
  withAsyncThunkErrorHandling,
  updateCheckInStatusNew,
  trackCheckInManuallyEvent,
  trackEditManualCheckInEvent,
  getStartOfTodayISOString,
  getIsLoadingUserCheckIn,
} from '@lib/store';

import {useAppDispatch, useAppSelector} from '@hooks';
import {Badge, Button, SquareButton} from '@molecules';
import {Grid, P} from '@quarks';
import {pxToRem} from '@utils';

export const CalendarWorkdayCheckInTile = ({date}: CalendarWorkdayCheckInTileProps) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const todayISOString = useAppSelector(getStartOfTodayISOString);
  const isLoadingCheckInStatus = useAppSelector(getIsLoadingUserCheckIn);
  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const canShowCheckInTile = useAppSelector((state) => getCanShowCheckInTile(state, date));
  const {eventType: checkInEventType, buildingId: checkInBuildingId} = useAppSelector(getUserCheckInStatus);
  const checkInBuilding = useAppSelector((state) => getBuildingById(state, checkInBuildingId!));

  const isWorkspaceReservationToday = isToday(parseISO(todayISOString));

  const checkInDisabled =
    checkInEventType === CheckInEventType.NotAtTheOffice && (!workday || workday.status !== 'OfficeDay');

  const isNotCheckedInYet = checkInEventType === CheckInEventType.NotCheckedInYet;
  const isNotAtTheOffice = checkInEventType === CheckInEventType.NotAtTheOffice;
  const isAtTheOffice = checkInEventType === CheckInEventType.AtTheOffice && checkInBuilding?.id === workday?.nodeId;

  const isAtAnotherOffice =
    checkInEventType === CheckInEventType.AtTheOffice &&
    !!checkInBuilding?.name &&
    checkInBuilding?.id !== workday?.nodeId;

  const isAtUnknownOffice =
    checkInEventType === CheckInEventType.AtTheOffice &&
    !checkInBuilding?.name &&
    checkInBuilding?.id !== workday?.nodeId;

  const handleUpdateCheckInStatus = (status: CheckInEventType) => {
    dispatch(
      withAsyncThunkErrorHandling(() =>
        updateCheckInStatusNew({
          buildingId: status === CheckInEventType.AtTheOffice ? workday?.nodeId : checkInBuildingId,
          eventType: status,
          sourceType: CheckInSourceType.Manual,
        }),
      ),
    );
  };

  const updateToAtTheOffice = () => {
    if (isLoadingCheckInStatus || checkInDisabled) return;
    checkInEventType === CheckInEventType.NotCheckedInYet
      ? dispatch(trackCheckInManuallyEvent(isWorkspaceReservationToday))
      : dispatch(trackEditManualCheckInEvent());

    handleUpdateCheckInStatus(CheckInEventType.AtTheOffice);
  };

  const updateToNotAtTheOffice = () => {
    if (isLoadingCheckInStatus || checkInDisabled) return;
    dispatch(trackEditManualCheckInEvent());
    handleUpdateCheckInStatus(CheckInEventType.NotAtTheOffice);
  };

  const getTitleAndBadgeType = (): {title: string; badgeType: ValidationType} =>
    isNotAtTheOffice
      ? {
          title: t('workplace:NotAtTheOffice'),
          badgeType: 'error',
        }
      : isAtTheOffice
      ? {
          title: t('workplace:AtTheOffice'),
          badgeType: 'success',
        }
      : isAtAnotherOffice
      ? {
          title: t('workplace:AtAnotherOfficeCheckIn', {checkInOffice: checkInBuilding.name}),
          badgeType: 'warning',
        }
      : isAtUnknownOffice
      ? {
          title: t('workplace:AtUnknownOffice'),
          badgeType: 'warning',
        }
      : {
          title: t('workplace:NotCheckedIn'),
          badgeType: 'unknown',
        };

  const buttonTitle = isNotCheckedInYet || isNotAtTheOffice ? t('workplace:CheckIn') : t('workplace:CheckOut');

  if (!canShowCheckInTile) return <></>;

  return (
    <SquareButton
      as="div"
      onClick={checkInEventType === CheckInEventType.AtTheOffice ? updateToNotAtTheOffice : updateToAtTheOffice}
      squareButton="safeMauve"
      disabled={checkInDisabled}
      left={
        <Grid
          as="span"
          justifyItems="center"
          width={pxToRem(24)}>
          <Badge
            badge={getTitleAndBadgeType().badgeType}
            border={`1px solid ${theme.divider.white}`}
          />
        </Grid>
      }
      right={
        <Button
          button={isNotCheckedInYet ? 'primary light' : 'tertiary light'}
          disabled={checkInDisabled}
          loading={isLoadingCheckInStatus}>
          {buttonTitle}
        </Button>
      }>
      <P fontWeight="regular">{getTitleAndBadgeType().title}</P>
    </SquareButton>
  );
};
