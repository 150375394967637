import {HaNRoomsMap, HaNWorkspacesMap, isMeetingRoom} from '@organisms';
import {HereAndNowMapsProps} from './types';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '@hooks';
import {getAllMeetingRoomLoadingStatus, getIsWorkspacesOnHereAndNowEnabled} from '@lib/store';
import {Loader} from '@atoms';
import {Div} from '@quarks';

export const HaNMaps = ({
  rooms,
  floorId,
  floorsWithMap,
  buildingId,
  onObjectClick,
  viewportRestrictions,
  selectedObject,
  hoverObjectId,
  workspaces,
}: HereAndNowMapsProps) => {
  const {tab} = useParams();
  const isWorkspacesOnHereAndNowEnabled = useAppSelector(getIsWorkspacesOnHereAndNowEnabled);

  const meetingRoomLoadingStatus = useAppSelector(getAllMeetingRoomLoadingStatus);
  const loadingMeetingRooms = meetingRoomLoadingStatus === 'Loading' || meetingRoomLoadingStatus === 'NotLoaded';

  if (loadingMeetingRooms) {
    return (
      <Div>
        <Loader />
      </Div>
    );
  }

  if (
    (tab === 'rooms' || (!isWorkspacesOnHereAndNowEnabled && !tab)) &&
    (selectedObject === null || isMeetingRoom(selectedObject))
  ) {
    return (
      <HaNRoomsMap
        rooms={rooms!}
        floorId={floorId}
        floorsWithMap={floorsWithMap}
        buildingId={buildingId}
        onRoomClick={onObjectClick}
        viewportRestrictions={viewportRestrictions}
        selectedRoom={selectedObject}
        hoverRoom={rooms!.find((room) => room.id === hoverObjectId) || null}
      />
    );
  } else if (tab === 'workspaces' && (selectedObject === null || !isMeetingRoom(selectedObject))) {
    return (
      <HaNWorkspacesMap
        floorId={floorId}
        floorsWithMap={floorsWithMap}
        workspaces={workspaces}
        buildingId={buildingId}
        hoverArea={workspaces.find((area) => area.id === hoverObjectId) || null}
        onAreaClick={onObjectClick}
        selectedArea={selectedObject}
        viewportRestrictions={viewportRestrictions}
      />
    );
  }
};
