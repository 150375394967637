import {ModalPage, ModalPageBottom, FilterType} from '@organisms';
import {CreateEventManualRoomSelectionCardFilterType, CreateEventRoomFiltersGroupProps} from './types';
import {FlexCol} from '@quarks';
import {Filter} from '../../Filter/Filter';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '@hooks';
import {getAllBuildings, getFloorsByBuildingIdSorted, getRoomSeatFilters} from '@lib/store';

export const CreateEventRoomFiltersGroup = ({
  onSelect,
  onBack,
  onClose,
  selectedFloorId,
  selectedSeatId,
  defaultBuildingId,
  selectedBuildingId,
}: CreateEventRoomFiltersGroupProps) => {
  const {t} = useTranslation();
  const [localFilters, setLocalFilters] = useState(
    new Map<CreateEventManualRoomSelectionCardFilterType, string | undefined>(),
  );
  const [floorId, setFloorId] = useState<string | undefined>(selectedFloorId);
  const [seatId, setSeatId] = useState<string | undefined>(selectedSeatId);

  const [buildingId, setBuildingId] = useState<string>(selectedBuildingId);

  const buildings = useAppSelector(getAllBuildings);

  const floorsMapped = useAppSelector(getFloorsByBuildingIdSorted(buildingId)).map((floor) => {
    return {label: floor.name, id: floor.id};
  });

  const offices = buildings.map((building) => {
    return {label: building.name, id: building.id};
  });

  const seatsMapped = useAppSelector(getRoomSeatFilters).map((seat) => {
    return {label: `${seat.name} seats`, id: seat.id};
  });

  const filters: FilterType<CreateEventManualRoomSelectionCardFilterType>[] = useMemo(
    () => [
      {
        label: t('workplace:Office'),
        items: offices,
        selectedId: buildingId,
        name: 'building',
        onChange: (value: string) => {
          setBuildingId(value);
          resetFloorAndSeat();
        },
        type: 'radio',
        isRequired: true,
        defaultValue: defaultBuildingId,
      },
      {
        label: t('Seats'),
        items: seatsMapped,
        selectedId: seatId,
        name: 'seat',
        type: 'radio',
        isRequired: false,
      },
      {
        label: t('Floor'),
        items: floorsMapped,
        selectedId: floorId,
        name: 'floor',
        type: 'radio',
        isRequired: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [floorId, seatId, buildingId],
  );

  const handleResetFilters = () => {
    setBuildingId(defaultBuildingId);
    resetFloorAndSeat();
  };

  const resetFloorAndSeat = () => {
    const map = new Map(localFilters);
    map.set('floor', undefined);
    map.set('seat', undefined);
    setFloorId(undefined);
    setSeatId(undefined);
    setLocalFilters(map);
  };

  const handleSubmitFilters = () => {
    onSelect(localFilters);
  };

  useEffect(() => {
    const map = new Map();
    filters.forEach(({name, selectedId}) => map.set(name, selectedId));
    setLocalFilters(map);
  }, [filters]);

  const handleSelect = (
    value: string,
    name: CreateEventManualRoomSelectionCardFilterType,
    onChange: ((value: string) => void) | undefined,
  ) => {
    const map = new Map(localFilters);
    map.set(name, value);
    setLocalFilters(map);
    onChange && onChange(value);
  };

  const hasChanges =
    localFilters.get('building') !== selectedBuildingId ||
    localFilters.get('floor') !== selectedFloorId ||
    localFilters.get('seat') !== selectedSeatId;

  return (
    <ModalPage
      bottom={
        <ModalPageBottom
          buttonDisabled={!hasChanges}
          buttonLabel={t('translation:ApplyFilter')}
          cancelButtonLabel={t('translation:ClearFilter')}
          onCancel={() => {
            handleResetFilters();
          }}
          onClick={() => {
            handleSubmitFilters();
          }}
        />
      }
      onClose={onClose}
      onBack={onBack}
      title={t('Filter')}>
      <FlexCol gap={64}>
        {filters.map(({items, label, name, onChange, type}) => (
          <Filter
            items={items}
            onSelect={(value) => handleSelect(value, name, onChange)}
            selectedId={localFilters.get(name)}
            title={label}
            type={type}
            key={name}
          />
        ))}
      </FlexCol>
    </ModalPage>
  );
};
