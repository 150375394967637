import {Button} from '@molecules';
import styled from 'styled-components';
import {StyledButtonProps} from './types';

export const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${({theme}) => theme.button['primary'].color};
  box-shadow: ${({$hasSolidBackground, theme}) =>
    $hasSolidBackground ? `0px 2px 5px ${theme.button['tertiary'].background}` : undefined};

  @media (hover) {
    &:hover:not(:disabled) {
      background: ${({theme}) => theme.button['primary'].color};
    }
  }
`;
