import {config, useTransition} from 'react-spring';

import {HaNBookingDetailsProps} from './types';

import {HaNModalHeader} from '../HaNModalHeader';
import {BookingDetailsWrapper, Caret, CellButton, StyledCell, StyledForm} from './styles';
import {useTranslation} from 'react-i18next';
import {FlexCol, FlexRow, H4, P, Subtitle} from '@quarks';
import {ModalPageBottom} from '@organisms';
import {
  useAppDispatch,
  useAppSelector,
  useCreateEvent,
  useDestructiveDialog,
  useFormatDate,
  useRoomImage,
} from '@hooks';
import {removeRoomFromAllRooms, getDynamicFreeTimeslot} from '@lib/store';
import {isSameDay, parseISO} from 'date-fns';
import {pickUsingStringAsIndex} from '@lib/utils';
import {useState, useCallback, useEffect} from 'react';
import {useTheme} from 'styled-components';
import {Chip, ModalCellItemIcon, ToggleCell} from '@molecules';
import {pxToRem} from '@utils';
import {Icon, Textarea} from '@atoms';

/**
 * Displayed in modal.
 * Allows user to modify details to room booking.
 */

export const HaNBookingDetails = ({
  onBack,
  onClose,
  page,
  room,
  duration,
  startDateTime: initialStartDateTime,
  prevPage,
  resetAttendees,
  setPage,
  setHasUnsavedChanges,
  attendees,
  onBookSuccessCallback,
}: HaNBookingDetailsProps) => {
  const {t} = useTranslation();
  const transition = useTransition(page === 'bookingDetails', {
    from: {transform: prevPage === 'addInvitees' ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)'},
    enter: {transform: 'translate3d(0%, 0, 0)'},
    leave: {transform: page === 'addInvitees' ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)'},
    config: {...config.stiff, clamp: true},
  });

  const theme = useTheme();
  const [title, setTitle] = useState<string>(room.displayName);
  const [message, setMessage] = useState<string>('');
  const [isOnlineMeeting, setIsOnlineMeeting] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const {destructiveDialog} = useDestructiveDialog();

  const {roomPictureHash, buildingId, email, capacity, floorName, buildingName, displayName} = room;
  const color = pickUsingStringAsIndex(email, theme.avatar.colors);
  const imageURL = useRoomImage(email || displayName, roomPictureHash, buildingId, 48);
  const subtitle = t('meeting:CreateMeetingRoomSummary', {
    count: capacity,
    floorName: floorName,
    buildingName: buildingName,
  });

  const {start: dynamicStart, end: dynamicEnd} =
    useAppSelector((state) => getDynamicFreeTimeslot(state, room.id, initialStartDateTime, {minutes: duration})) ?? {};

  const dynamicStartString = dynamicStart && dynamicStart.toISOString();
  const dynamicEndString = dynamicEnd && dynamicEnd.toISOString();

  const startDateTime = dynamicStartString ?? '';
  const endDateTime = dynamicEndString ?? '';

  const start = useFormatDate(startDateTime, 'EEEE d MMMM HH:mm');
  const end = useFormatDate(endDateTime, 'HH:mm');

  const endDateFormat = isSameDay(parseISO(startDateTime), parseISO(endDateTime)) ? 'HH:mm' : 'dd MMMM HH:mm';

  const confirmationMessageStartDateTime = useFormatDate(startDateTime, 'dd MMMM HH:mm');
  const confirmationMessageEndDateTime = useFormatDate(endDateTime, endDateFormat);

  const handleOnSuccess = () => {
    dispatch(removeRoomFromAllRooms(room.id));
    onBookSuccessCallback && onBookSuccessCallback();
    onClose();
  };

  const {createEvent, isCreatingEvent} = useCreateEvent(handleOnSuccess);

  const hasUnsavedChanges = title !== room.displayName || message !== '' || attendees.length > 0 || !isOnlineMeeting;

  const handleClose = useCallback(async () => {
    if (!hasUnsavedChanges) {
      onClose();
    } else {
      const confirmation = await destructiveDialog(
        t('meeting:DiscardEventChangesTitle'),
        t('meeting:DiscardEventChangesMessage'),
        'warning',
        t('meeting:DiscardEventChangesConfirm'),
      );
      if (confirmation) onClose();
    }
  }, [destructiveDialog, t, hasUnsavedChanges, onClose]);

  useEffect(() => {
    setHasUnsavedChanges(!!hasUnsavedChanges);
  }, [hasUnsavedChanges, setHasUnsavedChanges]);

  const handleBackClick = useCallback(async () => {
    if (!hasUnsavedChanges) {
      onBack();
    } else {
      const confirmation = await destructiveDialog(
        t('meeting:DiscardEventChangesTitle'),
        t('meeting:DiscardEventChangesMessage'),
        'warning',
        t('meeting:DiscardEventChangesConfirm'),
      );
      if (confirmation) {
        setMessage('');
        setTitle(room.displayName);
        setIsOnlineMeeting(true);
        resetAttendees();
        onBack();
      }
    }
  }, [destructiveDialog, t, hasUnsavedChanges, onBack, room, resetAttendees]);

  return transition(
    (style, item) =>
      item && (
        <BookingDetailsWrapper style={style}>
          <HaNModalHeader
            onBack={handleBackClick}
            onClose={handleClose}
            title={t('screen:MeetingRoomDetails')}
          />
          <FlexCol
            overflow="auto"
            flex="1">
            <StyledForm
              as="form"
              onSubmit={(e) => e.preventDefault()}>
              <StyledCell>
                <FlexCol gap={16}>
                  <H4 as="h2">{t('hereAndNow:BookRoomNowSectionTitle')}</H4>
                  <FlexRow
                    justifyContent="flex-start"
                    gap={16}>
                    <ModalCellItemIcon
                      imageURL={imageURL}
                      backgroundColor={color}
                      icon="room"
                    />
                    <FlexCol justifyContent="center">
                      <P fontWeight="bold">{displayName}</P>
                      <P>{subtitle}</P>
                    </FlexCol>
                  </FlexRow>
                  <FlexRow
                    justifyContent="center"
                    alignSelf="flex-start"
                    gap={8}>
                    <Icon
                      icon="clock"
                      size={pxToRem(20)}
                      color={theme.icon.inactive}
                    />
                    <Subtitle>
                      {start} - {end}
                    </Subtitle>
                  </FlexRow>
                </FlexCol>
              </StyledCell>
              <FlexCol gap={2}>
                <StyledCell fontWeight="bold">
                  <Textarea
                    maxLength={255}
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={t('meeting:CreateMeetingTitlePlaceholder')}
                    value={title}
                  />
                </StyledCell>
                <StyledCell>
                  <Textarea
                    maxLength={600}
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={t('meeting:CreateMeetingMessagePlaceholder')}
                    value={message}
                  />
                </StyledCell>
              </FlexCol>
              <FlexCol gap={2}>
                <StyledCell>
                  <CellButton
                    as="button"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    onClick={() => setPage('addInvitees')}>
                    <H4 as="h2">{t('Invitees')}</H4>
                    <Caret
                      icon="caretRight"
                      size={pxToRem(24)}
                    />
                  </CellButton>
                </StyledCell>
                {attendees.length > 0 && (
                  <StyledCell>
                    <FlexRow
                      columnGap={8}
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      rowGap={4}>
                      {attendees.map((user) => (
                        <Chip
                          chip={user.id ? 'colored' : 'tertiary'}
                          email={user.email}
                          key={user.email}>
                          {user.name}
                        </Chip>
                      ))}
                    </FlexRow>
                  </StyledCell>
                )}
              </FlexCol>
              <FlexCol gap={2}>
                <StyledCell>
                  <H4 as="h2">{t('meeting:OnlineEvent')}</H4>
                </StyledCell>
                <StyledCell>
                  <ToggleCell
                    label={t('meeting:CreateMeetingOnlineMeetingToggleLabel')}
                    checked={isOnlineMeeting}
                    name="isOnlineMeeting"
                    onToggle={(e) => setIsOnlineMeeting(e.target.checked)}
                  />
                </StyledCell>
              </FlexCol>
            </StyledForm>
          </FlexCol>
          <FlexCol paddingBottom={16}>
            <ModalPageBottom
              buttonLabel={t('meeting:CreateMeetingCreateEventButton')}
              buttonDisabled={!title || !startDateTime || !endDateTime}
              buttonLoading={isCreatingEvent}
              onClick={() =>
                createEvent(
                  {
                    title: title!,
                    message: message,
                    attendees: attendees.map((a) => a.email),
                    startDateTime,
                    endDateTime,
                    isOnlineMeeting,
                    rooms: [room.email],
                    isAllDay: false,
                    organizerTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    origin: 'BookRoomNow',
                  },
                  t('meeting:CreateMeetingSuccessMessage', {
                    startDate: confirmationMessageStartDateTime,
                    endDate: confirmationMessageEndDateTime,
                  }),
                )
              }
            />
          </FlexCol>
        </BookingDetailsWrapper>
      ),
  );
};
