import {useGlobalImageCache} from '@hooks';
import {apiBaseURL} from '@infrastructure';
import {getImage} from '@utils';

// Note: if the size queries in our API were consistent we could add size as a parameter and append
//       it to the endpoint here. This would allow us to return cached images that are larger to the
//       requested size. E.g. if I first request a 256x256 avatar and then the same avatar at 64x64,
//       we can skip a network request and return the oversized image blob.

export const useImage = (token: string, endpoint: string | null, apiVersion: '1.0' | '2.0' = '1.0') => {
  const {setGlobalImageCachePromise, getGlobalImageCachePromise, getGlobalImageCacheUrl} = useGlobalImageCache();

  const cacheKey = `${endpoint}v${apiVersion}`;
  const isInCache = !!getGlobalImageCachePromise(cacheKey);

  if (!isInCache && endpoint && token) {
    setGlobalImageCachePromise(cacheKey, getImage(`${apiBaseURL}/${endpoint}`, token, apiVersion));
  }

  return getGlobalImageCacheUrl(cacheKey);
};
