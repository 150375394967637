import {config, useTransition} from 'react-spring';

import {HaNSensorInformationProps} from './types';
import {useAppSelector, useFeatureToggle} from '@hooks';
import {getIsMeetingsCalendarEnabled, getIsOccupancyEnabled} from '@lib/store';
import {FlexCol, H2, P} from '@quarks';
import {MeetingRoomScheduleData, HaNModalHeader} from '@organisms';
import {useTranslation} from 'react-i18next';
import {SensorInformationWrapper, StyledContainer} from './styles';

/**
 * Lists available rooms (horizontally) in Here And Now
 */

export const HaNSensorInformation = ({onBack, onClose, page}: HaNSensorInformationProps) => {
  const {t} = useTranslation();
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const isMeetingCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);
  const {HybridMeetingsCalendarView} = useFeatureToggle();
  const transition = useTransition(page === 'sensorInformation', {
    from: {transform: 'translate3d(100%, 0, 0)'},
    enter: {transform: 'translate3d(0%, 0, 0)'},
    leave: {transform: 'translate3d(100%, 0, 0)'},
    config: {...config.stiff, clamp: true},
  });

  return transition(
    (style, item) =>
      item && (
        <SensorInformationWrapper style={style}>
          <HaNModalHeader
            onBack={onBack}
            onClose={onClose}
            title={t('screen:MeetingRoomDetails')}
          />
          <StyledContainer>
            {isOccupancyEnabled && (
              <FlexCol gap={24}>
                <H2>{t('hereAndNow:SensorInformation')}</H2>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationSensorIconAvailable"
                    status="available"
                    title={t('hereAndNow:RoomSeemsToBeAvailableTitle')}
                    icon="broadcast"
                  />
                  <P>{t('hereAndNow:EmptySensorDescription')}</P>
                </FlexCol>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationSensorIconUnavailable"
                    status="unavailable"
                    title={t('hereAndNow:RoomSeemsToBeInUseTitle')}
                    icon="broadcast"
                  />
                  <P>{t('hereAndNow:SensorInUseDescription')}</P>
                </FlexCol>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationSensorIconUnknown"
                    status="unknown"
                    title={t('hereAndNow:RoomLiveDataUnavailableTitle')}
                    icon="broadcast"
                  />
                  <P>{t('hereAndNow:NoLiveDataDescription')}</P>
                </FlexCol>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationSensorIconNoSensor"
                    status={undefined}
                    title={t('hereAndNow:NoSensor')}
                    icon="noBroadcast"
                  />
                  <P>{t('hereAndNow:NoSensorDescription')}</P>
                </FlexCol>
              </FlexCol>
            )}
            {isMeetingCalendarEnabled && HybridMeetingsCalendarView && (
              <FlexCol gap={24}>
                <H2>{t('hereAndNow:CalendarInformation')}</H2>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationScheduleIconAvailable"
                    status="available"
                    title={t('NotBooked')}
                    icon="calendar"
                  />
                  <P>{t('hereAndNow:NotBookedDescription')}</P>
                </FlexCol>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationScheduleIconUnavailable"
                    status="unavailable"
                    title={t('Booked')}
                    icon="calendar"
                  />
                  <P>{t('hereAndNow:BookedDescription')}</P>
                </FlexCol>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationScheduleIconAvailableInXMinutes"
                    status="pending"
                    title={t('hereAndNow:AvailableInXMinutes')}
                    icon="calendar"
                  />
                  <P>{t('hereAndNow:AvailableDescription')}</P>
                </FlexCol>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationScheduleIconNextBookingX"
                    status="pending"
                    title={t('hereAndNow:NextBookingX')}
                    icon="calendar"
                  />
                  <P>{t('hereAndNow:NextBookingDescription')}</P>
                </FlexCol>
                <FlexCol gap={8}>
                  <MeetingRoomScheduleData
                    data-testid="SensorInformationScheduleIconNotBookable"
                    status={undefined}
                    title={t('hereAndNow:NotBookable')}
                    icon="noCalendar"
                  />
                  <P>{t('hereAndNow:NotBookableDescription')}</P>
                </FlexCol>
              </FlexCol>
            )}
          </StyledContainer>
        </SensorInformationWrapper>
      ),
  );
};
