import {createContext, ReactNode, useEffect, useState} from 'react';
import {SidePanelProviderProps, SidePanelContextProps, EventData} from './types';
import {useAppDispatch} from '@hooks';

const initSidePanel = {
  closeSidePanel: () => void 0,
  sidePanelComponent: null,
  openSidePanel: () => void 0,
  setSidePanelContent: () => void 0,
  isSidePanelOpen: false,
  toggleSidePanel: () => void 0,
};

export const SidePanelContext = createContext<SidePanelContextProps>(initSidePanel);

export const SidePanelProvider = ({children}: SidePanelProviderProps) => {
  const dispatch = useAppDispatch();

  const [sidePanelEventData, setSidePanelEventData] = useState<Partial<EventData>>({});
  const [sidePanelComponent, setSidePanelComponent] = useState<ReactNode>(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false);

  const openSidePanel = (options?: {silent?: boolean}) => {
    setIsSidePanelOpen(true);

    if (!options?.silent && sidePanelEventData.onOpen) {
      dispatch(sidePanelEventData.onOpen);
    }
  };

  const closeSidePanel = (options?: {silent?: boolean}) => {
    setIsSidePanelOpen(false);

    if (!options?.silent && sidePanelEventData.onClose) {
      dispatch(sidePanelEventData.onClose);
    }
  };

  const setSidePanelContent = (node: ReactNode, options?: {eventData: Partial<EventData>}) => {
    setSidePanelComponent(node);

    setSidePanelEventData(options?.eventData ?? {});
  };

  const toggleSidePanel = () => setIsSidePanelOpen(!isSidePanelOpen);

  useEffect(() => {
    if (isSidePanelOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isSidePanelOpen]);

  return (
    <SidePanelContext.Provider
      value={{
        closeSidePanel,
        sidePanelComponent,
        openSidePanel,
        setSidePanelContent,
        isSidePanelOpen,
        toggleSidePanel,
      }}>
      {children}
    </SidePanelContext.Provider>
  );
};
