import {type MouseEvent} from 'react';
import {CalendarWeekDayProps} from './types';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
  getSortedConnectedUsersWithOfficeDayOnDate,
  getWorkspaceReservationByDate,
  getStartOfTodayISOString,
  getParkingReservationByDate,
  getWorkdayByDate,
  getAreAllNodesClosed,
  getTotalBuildings,
  getSelectedCalendarConnectionFilterIds,
} from '@lib/store';
import {formatDate, isBeforeToday} from '@lib/utils';

import {useAppSelector, useModal} from '@hooks';
import {isSameDay, parseISO, startOfToday} from 'date-fns';

import {FlexRow, P, Span} from '@quarks';
import {Date, Icon} from '@atoms';
import {AvatarStack, IconButton} from '@molecules';
import {useTheme} from 'styled-components';
import {BookingLocationStatusCard} from '@organisms';
import {resolveWorkdayStatusIcon} from '@utils';
import {CalendarCardCell, DayWrapper} from './styles';
import {generateTestIdCoreString} from '@utils';

export const CalendarWeekDay = ({
  date: dateAsString,
  isToday,
  toggleDayAndWeekView,
  ...props
}: CalendarWeekDayProps) => {
  const TEST_ID = generateTestIdCoreString(import.meta.url);
  const theme = useTheme();
  const {addPages, openModal} = useModal();

  const {t} = useTranslation();
  const dateAsDate = parseISO(dateAsString);
  const todayISOString = useAppSelector(getStartOfTodayISOString);
  const today = todayISOString ? parseISO(todayISOString) : startOfToday();
  const isPast = isBeforeToday(parseISO(dateAsString), today);
  const colleagues = useAppSelector((state) => getSortedConnectedUsersWithOfficeDayOnDate(state, dateAsString));
  const selectedCalendarConnectionFilterIds = useAppSelector(getSelectedCalendarConnectionFilterIds);

  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, dateAsDate));
  const workday = useAppSelector((state) => getWorkdayByDate(state, dateAsString));
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, dateAsString));

  const params = useParams();
  const activeDate = params.date ? params.date : startOfToday().toISOString();
  const isActive = isSameDay(dateAsDate, parseISO(activeDate));

  const buildingCount = useAppSelector(getTotalBuildings);
  const areAllNodesClosed = useAppSelector((state) => getAreAllNodesClosed(state, dateAsDate));

  const dayOverviewLink = `/${formatDate(parseISO(dateAsString), 'yyyy-MM-dd', 'en')}`;

  const isInPastOrAllNodesClosed = isPast || areAllNodesClosed;

  const filteredColleagues = colleagues.filter(({id}) =>
    selectedCalendarConnectionFilterIds.length ? selectedCalendarConnectionFilterIds.includes(id) : true,
  );

  const handleOpenWorkdayModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPast) return;

    addPages([<BookingLocationStatusCard date={dateAsString} />]);
    openModal();
  };

  return (
    <CalendarCardCell
      $isDisabled={isInPastOrAllNodesClosed}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => (isPast ? e.preventDefault() : toggleDayAndWeekView())}
      to={dayOverviewLink}
      aria-disabled={isPast}
      tabIndex={isPast ? -1 : 0}
      data-testid={`${TEST_ID}-card-cell-${dateAsString.split('T')[0]}`}
      {...props}>
      <FlexRow
        alignItems="center"
        gap={16}>
        <DayWrapper
          $isActive={isActive}
          $isToday={isToday}>
          <Span
            color={
              isToday
                ? theme.card.beige500.color
                : !isInPastOrAllNodesClosed
                ? theme.text.color.subtitle
                : theme.disabled.color
            }>
            <Date
              date={dateAsString}
              format="EEEEEE"
            />
          </Span>
          <Span
            color={
              isToday
                ? theme.card.beige500.color
                : !isInPastOrAllNodesClosed
                ? theme.text.color.body
                : theme.disabled.color
            }
            fontWeight="bold"
            fontSize="lg">
            <Date
              date={dateAsString}
              format="dd"
            />
          </Span>
        </DayWrapper>
        {areAllNodesClosed && !workday ? (
          <P fontWeight="regular">{t('workplace:OfficeClosed', {count: buildingCount})}</P>
        ) : (
          <AvatarStack
            date={dateAsString}
            disabled={isPast}
            users={filteredColleagues}
            tooltipId={`CalendarWeekDay-${dateAsString}`}
            overlap={true}
            max={3}
          />
        )}
      </FlexRow>

      <FlexRow
        alignItems="center"
        justifyContent="space-between">
        <FlexRow
          alignItems="center"
          gap={8}>
          {parkingReservation !== undefined && (
            <Icon
              color={
                isToday
                  ? theme.text.color.subtitle
                  : !isInPastOrAllNodesClosed
                  ? theme.text.color.subtitle
                  : theme.disabled.color
              }
              icon="car"
              size="1.25rem"
            />
          )}
          {workspaceReservation && (
            <Icon
              color={
                isToday
                  ? theme.text.color.subtitle
                  : !isInPastOrAllNodesClosed
                  ? theme.text.color.subtitle
                  : theme.disabled.color
              }
              icon="desk"
              size="1.25rem"
            />
          )}
          <IconButton
            disabled={isPast}
            aria-label={t('workplace:WhereWillYouBe')}
            iconButton="tertiary"
            icon={resolveWorkdayStatusIcon(false, workday)}
            onClick={handleOpenWorkdayModal}
          />
        </FlexRow>
      </FlexRow>
    </CalendarCardCell>
  );
};
