import {IAuthManager} from '@lib/infrastructure';
import {
  AccountInfo,
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';

export const authManager = (instance: PublicClientApplication): IAuthManager => {
  const scopes: string = import.meta.env.MAPIQ_AUTH_LOGIN_SCOPES ?? '';

  const fromScopesString = (str: string) => str.split(',').map((scope: string) => scope.trim());

  const getLoginHint = (account: AccountInfo | null) => {
    if (!account) return;

    const claims = account.idTokenClaims;

    if (!claims) {
      return;
    }

    // As we don't have the login_hint claim, we need to provide our custom login hint
    const loginHint = claims['sign_in_name'] || claims['email'];
    return loginHint as string;
  };

  const loginRequest = (loginHint?: string): RedirectRequest => {
    return {
      scopes: fromScopesString(scopes),
      loginHint: loginHint,
      redirectUri: window.location.origin,
    };
  };

  const silentRequest = (): SilentRequest => {
    // TODO: Check redirect URIs for silent requests
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
    // const redirectUri = new URL('blank.html', window.location.href).toString();

    return {
      scopes: fromScopesString(scopes),
      forceRefresh: false,
      //redirectUri,
    };
  };

  const logoutRequest = (logoutHint?: string): EndSessionRequest => {
    return {
      logoutHint: logoutHint,
      // Since the auth server doesn't accept loging-in from any route, it's convenient
      // to set this to be the origin, so that on the next attempt it will be
      // possible to login directly
      postLogoutRedirectUri: window.location.origin,
    };
  };

  return {
    signInRedirect: async () => {
      await instance.loginRedirect(loginRequest());
    },
    signOut: async () => {
      const account = instance.getActiveAccount();
      const logoutHint = getLoginHint(account);
      await instance.logoutRedirect(logoutRequest(logoutHint));
    },
    getAccessToken: async () => {
      const account = instance.getActiveAccount();

      if (!account) return;

      try {
        const silentResponse = await instance.acquireTokenSilent(silentRequest());
        return silentResponse.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            const loginHint = getLoginHint(account);
            await instance.acquireTokenRedirect(loginRequest(loginHint));
            return;
          } catch {
            // REMARK: For now we don't do anything.
            return;
          }
        }
      }
    },
  };
};
