import styled from 'styled-components';

import {pxToRem} from '@utils';

import {Card} from '@atoms';
import {Badge} from '@molecules';
import {Div} from '@quarks';

export const StyledNotificationCardCell = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: [icon-start] auto [icon-end content-start] 1fr [content-end button-start] auto [button-end];
  isolation: isolate;
  position: relative;
`;

export const StyledIconWrapper = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(48)};
  width: ${pxToRem(48)};
  position: relative;
`;

export const StyledBadge = styled(Badge)`
  position: relative;
  left: ${pxToRem(28)};
`;

export const StyledWrapper = styled(Div)<{$isPast: boolean}>`
  color: ${({$isPast, theme}) => ($isPast ? theme.disabled.colorHighContrast : theme.text.color.body)};
`;
