import {useTranslation} from 'react-i18next';

import {P} from '@quarks';
import {Toggle, TwoColumnCell} from '@atoms';
import {useAppSelector, useAppDispatch, useModal} from '@hooks';
import {
  getIsEmailNotificationEnabled,
  getIsReservationReminderEnabled,
  getNotificationCategoryData,
  loadNotificationCategories,
  updateNotificationPreference,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {pxToRem} from '@utils';
import {ProfileCell} from '../styles';
import {NotificationSettingsModalPage} from '@organisms';
import {StyledClickableCellWrapper, StyledIcon} from './styles';
import {useEffect} from 'react';

export const EmailNotificationCell = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const isEmailNotificationEnabled = useAppSelector(getIsEmailNotificationEnabled);
  const {setModalPages, openModal} = useModal();
  const isReservationReminderEnabled = useAppSelector(getIsReservationReminderEnabled);

  const {status} = useAppSelector(getNotificationCategoryData);

  useEffect(() => {
    if (status === 'NotLoaded') dispatch(loadNotificationCategories());
  }, [dispatch, status]);

  const handleToggle = () => {
    dispatch(
      withAsyncThunkErrorHandling(() =>
        updateNotificationPreference({
          category: 'Updates',
          type: 'UserEmail',
          enabled: !isEmailNotificationEnabled,
        }),
      ),
    );
  };

  const openSettings = () => {
    setModalPages([<NotificationSettingsModalPage />]);
    openModal();
  };

  return (
    <ProfileCell>
      {isReservationReminderEnabled ? (
        <StyledClickableCellWrapper
          data-testid="profilepage_notification-settings-modalpage-button"
          onClick={openSettings}>
          <TwoColumnCell
            padding="small"
            left={<P>{t('profile:Notifications')}</P>}
            right={
              <StyledIcon
                icon="caretRight"
                size={pxToRem(24)}
              />
            }
          />
        </StyledClickableCellWrapper>
      ) : (
        <>
          <P id="emailNotifications">{t('screen:EmailNotifications')}</P>
          <Toggle
            aria-labelledby="emailNotifications"
            checked={isEmailNotificationEnabled}
            onChange={handleToggle}
          />
        </>
      )}
    </ProfileCell>
  );
};
