import {MapViewHighlight, MapViewUser} from '@molecules';
import zip from 'lodash/zip';
import {BuildingNodeState} from '../../../../submodules/map/mapiq-map/MapState';

export const createState = (nodeIds: string[], state: BuildingNodeState) => new Map(nodeIds.map((id) => [id, state]));
export const mergeState = (s1: Map<string, BuildingNodeState>, s2: Map<string, BuildingNodeState>) => {
  return new Map([...s1, ...s2]);
};

// TODO - Wait for Mickey to update types in map submodule and import instead of typing them out like this.
const createAnonymousHighlightedDesk = (
  deskId: string,
  available: boolean,
): {type: 'desk'; nodeId: string} & ({available: false; connections: []} | {available: true; connections: []}) => ({
  type: 'desk',
  nodeId: deskId,
  connections: [],
  available: available,
});

export const createHighlightedDesk = (deskId: string, user: MapViewUser): MapViewHighlight => ({
  type: 'desk',
  nodeId: deskId,
  connections: [user],
  available: false,
});

const createAnonymousHighlightedArea = (areaId: string, count: number): MapViewHighlight => ({
  type: 'area',
  nodeId: areaId,
  connections: [],
  available: count,
});

const createHighlightedArea = (areaId: string, group: MapViewUser[]): MapViewHighlight => ({
  type: 'area',
  nodeId: areaId,
  connections: group,
  available: group.length,
});

const createHighlightedRoom = (roomId: string): MapViewHighlight => ({
  type: 'room',
  nodeId: roomId,
});

export const anonymousSelectedDeskState = (deskIds: string[]) => {
  return {
    state: createState(deskIds, 'selected'),
    markers: deskIds.map((id) => createAnonymousHighlightedDesk(id, true)),
  };
};

export const desksState = (
  deskIds: string[],
  group: MapViewUser[],
  state: BuildingNodeState = 'selected',
  noUserMeansAvailable = true,
) => {
  return {
    state: createState(deskIds, state),
    markers: zip(deskIds, group).flatMap(([deskId, user]) => {
      // Skip users without a desk
      if (!deskId) return [];

      // Desks without a user get an anonymous marker
      if (!user) return [createAnonymousHighlightedDesk(deskId, noUserMeansAvailable)];

      return [createHighlightedDesk(deskId, user)];
    }),
  };
};

export const anonymousSelectedAreaState = (areaId: string, count: number) => {
  return {
    state: createState([areaId], 'selected'),
    markers: [createAnonymousHighlightedArea(areaId, count)],
  };
};

export const selectedAreaState = (areaId: string, group: MapViewUser[]) => {
  return {
    state: createState([areaId], 'selected'),
    markers: [createHighlightedArea(areaId, group)],
  };
};

export const selectedRoomState = (roomId: string) => {
  return {
    state: createState([roomId], 'selected'),
    markers: [createHighlightedRoom(roomId)],
  };
};
