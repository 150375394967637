import {useTranslation} from 'react-i18next';
import {H2} from '@quarks';
import {CardProps} from '@atoms';
import {CellWrapper, ProfileCard} from '../styles';
import {ExportsCell} from '@organisms';
import {useFeatureToggle} from '@hooks';

export const ExportsCard = ({className, style, ...props}: CardProps) => {
  const {t} = useTranslation();

  const {HistoricBookingOverview: isFeatureEnabled} = useFeatureToggle();

  if (!isFeatureEnabled) return <></>;

  return (
    <ProfileCard
      as="section"
      {...props}>
      <H2>{t('profile:ExportsSectionTitle')}</H2>
      <CellWrapper>
        <ExportsCell />
      </CellWrapper>
    </ProfileCard>
  );
};
