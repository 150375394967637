import {SensorAvailabilityStatus} from '@lib/infrastructure';
import {formatDuration, getMeetingRoomSuggestionDataById, MeetingRoom} from '@lib/store';
import {roundDownDurationIfMoreThanDay, stripSecondsAndMilliseconds} from '@lib/utils';
import {differenceInDays, differenceInMinutes, intervalToDuration, parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from './useAppSelector';

export type SensorStatus = {
  sensorStatus: 'available' | 'unavailable' | 'unknown' | 'pending';
  sensorTitle: string;
  sensorSubtitle?: string;
};

export const useRoomSensorStatus = ({suggestionData: suggestionDataId = ''}: MeetingRoom): SensorStatus | undefined => {
  const {t} = useTranslation();
  const {sensorStateTimestamp, sensorAvailability} =
    useAppSelector((state) => getMeetingRoomSuggestionDataById(state, suggestionDataId)) ?? {};

  const now = stripSecondsAndMilliseconds(new Date());
  const parsedTimestamp = sensorStateTimestamp
    ? stripSecondsAndMilliseconds(parseISO(sensorStateTimestamp))
    : undefined;

  const sensorLastUpdatedAgo = parsedTimestamp && intervalToDuration({start: parsedTimestamp, end: now});
  const roundedDuration = sensorLastUpdatedAgo && roundDownDurationIfMoreThanDay(sensorLastUpdatedAgo);
  const isUpdatedLessThanMinuteAgo = parsedTimestamp && differenceInMinutes(now, parsedTimestamp) < 1;
  const isUpdatedMoreThanDayAgo = parsedTimestamp && differenceInDays(now, parsedTimestamp) > 0;

  const formattedDuration = formatDuration(t, roundedDuration, isUpdatedMoreThanDayAgo);
  const updateTimeLabelText = isUpdatedLessThanMinuteAgo
    ? t('hereAndNow:RoomSensorAvailabilityUpdatedJustNow')
    : parsedTimestamp &&
      t('hereAndNow:RoomSensorAvailabilityUpdateTime', {
        countWithMinutesSuffix: formattedDuration,
      });

  switch (sensorAvailability) {
    case SensorAvailabilityStatus.Available: {
      return {
        sensorStatus: 'available',
        sensorTitle: t('hereAndNow:RoomSeemsToBeAvailableTitle'),
        sensorSubtitle: updateTimeLabelText,
      };
    }
    case SensorAvailabilityStatus.Occupied: {
      return {
        sensorStatus: 'unavailable',
        sensorTitle: t('hereAndNow:RoomSeemsToBeInUseTitle'),
        sensorSubtitle: updateTimeLabelText,
      };
    }
    case SensorAvailabilityStatus.Away: {
      return {
        sensorStatus: 'unknown',
        sensorTitle: t('hereAndNow:RoomLiveDataUnavailableTitle'),
        sensorSubtitle: updateTimeLabelText,
      };
    }
    case SensorAvailabilityStatus.Unknown:
    default: {
      return;
    }
  }
};
