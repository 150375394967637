import {useState, useEffect} from 'react';
import {useAppDispatch, useAppSelector, useFilterGroup, useModal} from '@hooks';
import {
  getListOfSelectableWorkingSpacesByType,
  getSelectedBuildingNodeId,
  getFloorsByBuildingIdSorted,
  getInitialSelectedWorkspace,
  getAvailableDeskAmenityDataByBuildingId,
  trackEvent,
  ApplicationInsightsEvents,
  getAvailableActivityTypeDataByBuildingId,
} from '@lib/store';
import {ChipFilter, ChipFilterItem} from '@molecules';
import {WorkspaceSelectionCell, FilterCard, ModalPage} from '@organisms';
import {Div, FlexCol, FlexRow, P, Subtitle} from '@quarks';
import {useTranslation} from 'react-i18next';
import isEqual from 'lodash/isEqual';

import {WorkspaceSelectionCardProps} from './types';
import {OnSelectFunction, Sections} from '../../Filter/types';
import {getDeskAmenityIcon} from '@utils';
import {Illustration} from '@atoms';
import xor from 'lodash/xor';

export const WorkspaceSelectionCard = ({date, inviteeCount = 0}: WorkspaceSelectionCardProps) => {
  const dispatch = useAppDispatch();
  const {closeModal, removePages, addPages} = useModal();
  const [filteredFloorId, setFilteredFloorId] = useState<null | string>();
  const [filteredDeskAmenities, setFilteredDeskAmenities] = useState<string[]>([]);
  const [filteredActivityTypes, setFilteredActivityTypes] = useState<string[]>([]);
  const {t} = useTranslation();

  const baseLocation = useAppSelector((state) => getInitialSelectedWorkspace(state, {selectedDate: date}));
  const selectedBuildingId = (useAppSelector(getSelectedBuildingNodeId) || baseLocation?.buildingId) ?? '';

  const floorsMapped = useAppSelector(getFloorsByBuildingIdSorted(selectedBuildingId)).map((floor) => {
    return {label: floor.name, id: floor.id};
  });

  const listOfSelectableWorkingSpaces = useAppSelector((state) =>
    getListOfSelectableWorkingSpacesByType(state, {
      buildingId: selectedBuildingId,
      date: date,
      selectedFloorId: filteredFloorId,
      inviteeCount: inviteeCount,
      selectedDeskAmenities: filteredDeskAmenities,
      selectedActivityTypes: filteredActivityTypes,
    }),
  );

  const resultCount = listOfSelectableWorkingSpaces.flatMap((s) => s.data).length;
  const hasNoResults = !resultCount;

  const availableDeskAmenityData = useAppSelector(getAvailableDeskAmenityDataByBuildingId(selectedBuildingId));
  const availableActivityTypeData = useAppSelector(getAvailableActivityTypeDataByBuildingId(selectedBuildingId));
  const isLoadingAvailableDeskAmenities = availableDeskAmenityData.status === 'Loading';
  const isLoadingAvailableActivityTypes = availableActivityTypeData.status === 'Loading';
  const {availableDeskAmenities} = availableDeskAmenityData;
  const {availableActivityTypes} = availableActivityTypeData;

  const {setWorkspaceSections, setIsLoading} = useFilterGroup();

  const initialSelection = [filteredFloorId, ...filteredDeskAmenities, ...filteredActivityTypes].filter(
    (id): id is string => Boolean(id),
  );
  const sections = {
    floors: {
      title: t('workplace:Floors'),
      type: 'radio' as const,
      items: floorsMapped,
    },
    deskAmenities: {
      title: t('deskAmenities:deskAmenity', {count: availableDeskAmenities.length}),
      type: 'checkbox' as const,
      items: availableDeskAmenities.map((id: string) => ({
        id,
        label: t(`deskAmenities:deskAmenity`, {context: id}),
        icon: getDeskAmenityIcon(id),
      })),
    },
    activityTypes: {
      title: t('workplace:ActivityTypesFilterSectionTitle'),
      type: 'checkbox' as const,
      items: availableActivityTypes.map((id: string) => ({
        id,
        label: t(`activityTypes:activityType`, {context: id}),
      })),
    },
  };

  const handleFilterOnRemove = (
    next:
      | {floorId?: never; deskAmenties: string[]; activityTypes?: never}
      | {floorId: string | null; deskAmenties?: never; activityTypes?: never}
      | {floorId?: never; deskAmenties?: never; activityTypes: string[]},
  ) => {
    const eventData: ApplicationInsightsEvents['CalendarOverview_WorkplaceBooking__DeleteFilter'] = {
      selectedDeskAmenities: filteredDeskAmenities,
      selectedActivityTypes: filteredActivityTypes,
      selectedFloorId: filteredFloorId || null,
    };

    if (next.floorId !== undefined) {
      setFilteredFloorId(next.floorId);
      eventData.selectedFloorId = next.floorId;
    }

    if (next.deskAmenties !== undefined) {
      setFilteredDeskAmenities(next.deskAmenties);
      eventData.selectedDeskAmenities = next.deskAmenties;
    }

    if (next.activityTypes !== undefined) {
      setFilteredActivityTypes(next.activityTypes);
      eventData.selectedActivityTypes = next.activityTypes;
    }

    dispatch(trackEvent('CalendarOverview_WorkplaceBooking__DeleteFilter', eventData));
  };

  const onSelect: OnSelectFunction<typeof sections> = ({floors: floorId, deskAmenities, activityTypes}) => {
    setFilteredFloorId(floorId);

    if (!isEqual(filteredDeskAmenities, deskAmenities)) {
      setFilteredDeskAmenities(deskAmenities);
    }

    if (!isEqual(filteredActivityTypes, activityTypes)) {
      setFilteredActivityTypes(activityTypes);
    }

    if (floorId || deskAmenities.length || activityTypes.length) {
      dispatch(
        trackEvent('CalendarOverview_WorkplaceBooking__ApplyFilters', {
          selectedDeskAmenities: deskAmenities,
          selectedActivityTypes: activityTypes,
          selectedFloorId: floorId,
        }),
      );
    }
  };

  useEffect(() => {
    setWorkspaceSections(sections as Sections);
    // it is intentional to only check for availableDeskAmenities
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDeskAmenityData.status, availableActivityTypeData.status, setWorkspaceSections]);

  useEffect(() => {
    setIsLoading(isLoadingAvailableDeskAmenities || isLoadingAvailableActivityTypes);
  }, [isLoadingAvailableDeskAmenities, isLoadingAvailableActivityTypes, setIsLoading]);

  useEffect(() => {
    if (filteredFloorId || !!filteredDeskAmenities.length || !!filteredActivityTypes.length) {
      dispatch(
        trackEvent('CalendarOverview_WorkplaceBooking__FilterResult', {
          resultCount,
        }),
      );
    }
  }, [dispatch, resultCount, filteredFloorId, filteredDeskAmenities.length, filteredActivityTypes.length]);

  const handleOnClick = () => {
    addPages([
      <FilterCard
        onClose={closeModal}
        initialSelection={initialSelection}
        onSelect={onSelect}
        title={t('screen:WorkplaceFilter')}
        canResetState={true}
        testIdPrefix="workplace-filter"
        resetEventName="CalendarOverview_WorkplaceBooking__FilterReset"
      />,
    ]);
  };

  const chipData = {
    ...floorsMapped.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: {
          id: curr.id,
          label: curr.label || t('meeting:FilterRoomsFloor'),
          onDelete: () => {
            handleFilterOnRemove({floorId: null});
          },
        },
      }),
      {} as Record<string, ChipFilterItem>,
    ),
    ...availableDeskAmenities.reduce(
      (acc, id) => ({
        ...acc,
        [id]: {
          id,
          label: t('deskAmenities:deskAmenity', {context: id}),
          onDelete: () => {
            const nextDeskAmenities = xor(filteredDeskAmenities, [id]);
            handleFilterOnRemove({deskAmenties: nextDeskAmenities});
          },
        },
      }),
      {} as Record<string, ChipFilterItem>,
    ),
    ...availableActivityTypes.reduce(
      (acc, id) => ({
        ...acc,
        [id]: {
          id,
          label: t('activityTypes:activityType', {context: id}),
          onDelete: () => {
            const nextActivityTypes = xor(filteredActivityTypes, [id]);
            handleFilterOnRemove({activityTypes: nextActivityTypes});
          },
        },
      }),
      {} as Record<string, ChipFilterItem>,
    ),
  };

  return (
    <ModalPage
      title={t('workplace:SelectWorkspace')}
      onBack={() => removePages(1)}
      onClose={closeModal}>
      <FlexCol gap={32}>
        <FlexRow
          justifyContent="flex-start"
          gap={8}>
          <ChipFilter
            chips={[filteredFloorId, ...filteredDeskAmenities, ...filteredActivityTypes].map(
              (id) => chipData[id || ''],
            )}
            onClick={handleOnClick}
            testIdPrefix="workplace-filter"
            buttonLabel={t('workplace:Filter')}
          />
        </FlexRow>

        {hasNoResults ? (
          <FlexCol
            alignItems="center"
            padding={32}
            gap={16}>
            <Illustration
              size="100px"
              illustration="noResults"
            />
            <P
              color="currentColor"
              textAlign="center">
              {t('workplace:CouldNotFindWorkspace')}
            </P>
          </FlexCol>
        ) : (
          <FlexCol gap={32}>
            {listOfSelectableWorkingSpaces
              .filter(({data}) => data.length)
              .map((section) => (
                <FlexCol
                  gap={8}
                  key={section.key}>
                  {section.key === 'Unavailable' && (
                    <P
                      fontWeight="bold"
                      color="currentColor">
                      {t('workplace:WorkspaceGroupUnavailable')}
                    </P>
                  )}
                  <Div>
                    {section.data.map((workspace, index) => (
                      <WorkspaceSelectionCell
                        date={date}
                        workspace={workspace}
                        inviteeCount={inviteeCount}
                        key={`workspace-${index}`}
                      />
                    ))}
                  </Div>
                </FlexCol>
              ))}
            <Subtitle fontSize="sm">{t('workplace:NoWorkplaceInTheList')}</Subtitle>
          </FlexCol>
        )}
      </FlexCol>
    </ModalPage>
  );
};
