import {Card, Icon} from '@atoms';
import {Div} from '@quarks';
import {pxToRem} from '@utils';
import styled from 'styled-components';
import {StyledIconProps, StyledWrapperProps, StyledContainerProps} from './types';

export const StyledIcon = styled(Icon).attrs<StyledIconProps>(({icon, theme, $disabled, ...attrs}) => ({
  color: $disabled ? theme.button.text.disabled.color : theme.text.color.body,
  ...attrs,
}))<StyledIconProps>``;

export const StyledWrapper = styled(Div)<StyledWrapperProps>`
  filter: ${({$disabled}) => `grayscale(${+Boolean($disabled)})`};
  border-radius: 8px;
  overflow: hidden;

  outline: ${({$selected, theme}) => ($selected ? `2px solid ${theme.text.color.body}` : 'none')};
  outline-offset: 2px;
`;

export const StyledContainer = styled(Card).attrs((attrs) => ({
  ...attrs,
  card: 'beige500',
}))<StyledContainerProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${pxToRem(48)};
  width: ${pxToRem(48)};
  background: ${({$imageURL, $disabled, $background, theme}) =>
    $imageURL && $imageURL !== ''
      ? `scroll center/cover no-repeat url('${$imageURL}')`
      : $disabled
      ? theme.button.primary.disabled.background
      : $background === null
      ? 'transparent'
      : $background};
  border-radius: 0;
`;
