import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';

export const reactPlugin = new ReactPlugin();
export const AppInsights = new ApplicationInsights({
  config: {
    // Additional docs: https://github.com/microsoft/ApplicationInsights-JS#configuration
    connectionString: import.meta.env.MAPIQ_APP_INSIGHTS,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    disableFetchTracking: false,
    enableAjaxErrorStatusText: true,
    enableUnhandledPromiseRejectionTracking: true,
    extensions: [reactPlugin],
  },
});
