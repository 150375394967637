import {FlexCol} from '@quarks';
import {ConnectionFilterListProps} from './types';

import {ClickableCellWrapper, Input, UserCell} from '@molecules';

export const ConnectionFilterList = ({connections, selectedIds, onItemClick}: ConnectionFilterListProps) => {
  return (
    <FlexCol gap={4}>
      {connections.map((connection) => {
        const selected = selectedIds.includes(connection.id);
        return (
          <ClickableCellWrapper
            key={`connectionFilterUser_${connection.id}`}
            onClick={() => onItemClick(connection.id, selected)}>
            <UserCell
              padding="small"
              right={
                <Input
                  tabIndex={-1}
                  type="checkbox"
                  readOnly
                  checked={selected}
                />
              }
              user={connection}
              separator={false}
            />
          </ClickableCellWrapper>
        );
      })}
    </FlexCol>
  );
};
