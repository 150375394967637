import {Tooltip} from 'react-tooltip';
import {AvatarStackProps} from './types';

import {isToday, parseISO} from 'date-fns';

import {StyledAvatar, StyledAvatarStack, StyledDiv, TooltipName} from './styles';
import {FlexCol} from '@quarks';
import {useMemo} from 'react';

export const AvatarStack = ({
  className,
  date,
  disabled,
  max = 6,
  overlap = false,
  style,
  tooltipId,
  users,
  ...props
}: AvatarStackProps) => {
  const length = users.length;
  // Set how much the avatars are covered by each other, bigger number
  // shows more of the avatar
  const span = overlap ? 3 : 1;
  const columns = overlap ? max * span + 1 : max + 1;

  const avatars =
    length > max
      ? users
          .slice(0, max)
          .concat([
            {
              id: '000',
              name: '',
              initials: `+${length - max}`,
              email: '',
            },
          ])
          .reverse()
      : users.reverse();

  const avatarNames = useMemo(() => {
    return users.filter((user) => user.name || user.displayName).reverse();
  }, [users]);

  return (
    <FlexCol alignItems="flex-start">
      <StyledAvatarStack
        data-tooltip-id={tooltipId}
        $columns={columns}
        $length={length}
        $max={max}
        $overlap={overlap}
        $span={span}
        className={className}
        style={style}
        {...props}>
        {avatars.map((user, i) => (
          <StyledAvatar
            $avatarsLength={avatars.length}
            $columns={columns}
            $count={i}
            $length={length}
            $max={max}
            $overlap={overlap}
            $span={span}
            key={`${date}-${user.email}-${i}`}
            shouldShowCheckIn={isToday(parseISO(date))}
            user={user}
            disabled={disabled}
          />
        ))}
      </StyledAvatarStack>
      <Tooltip
        id={tooltipId}
        clickable={true}
        hidden={true}
        style={{
          maxWidth: 'calc(100% - 10px)',
          zIndex: 99,
          padding: 0,
        }}>
        <StyledDiv>
          {avatarNames.map((user, index) => (
            <TooltipName key={`${user.id}${index}`}>{user.displayName ?? user.name}</TooltipName>
          ))}
        </StyledDiv>
      </Tooltip>
    </FlexCol>
  );
};
