import {useAppSelector, useDialog, useModal} from '@hooks';
import {useTranslation} from 'react-i18next';
import {StyledModalPage, CardWrapperEmpty} from './styles';
import {ModalCell, Button} from '@molecules';
import {FlexCol, P} from '@quarks';
import {Illustration, Loader} from '@atoms';
import {LicensePlatesFormModalPage} from '../LicensePlateFormModalPage/LicensePlatesFormModalPage';
import {getLicensePlates, getIsUpdatingParkingPreferences} from '@lib/store';
import {pxToRem} from '@utils';
import {LicensePlateItem} from './LicensePlateItem';
import {HTMLAttributes} from 'react';

export type Props = HTMLAttributes<HTMLDivElement> & {
  date?: string;
};

export const LicensePlatesModalPage = ({date}: Props) => {
  const {t} = useTranslation();
  const {closeModal, addPages} = useModal();

  const licensePlates = useAppSelector(getLicensePlates);
  const isLicensePlatesLoading = useAppSelector(getIsUpdatingParkingPreferences);

  const hasLicensePlates = !!licensePlates?.length;

  const illustration = hasLicensePlates ? 'error' : 'car';

  const ariaLabel = hasLicensePlates ? 'error' : t('profile:LicencePlate:emptyPageAriaLabel');

  const emptyStateTitle = t('profile:LicencePlate:emptyPageTitle');
  const emptyStateLabel = t('profile:LicencePlate:emptyPageDescription');
  const buttonLabel = t('profile:LicencePlate:addLicensePlate');

  const MAX_LICENSE_PLATE_COUNT = 10;
  const reachedLimit = licensePlates.length >= MAX_LICENSE_PLATE_COUNT;

  const addLicensePlate = () => {
    addPages([<LicensePlatesFormModalPage date={date} />]);
  };

  return (
    <StyledModalPage
      title={t('profile:LicensePlates')}
      onClose={closeModal}>
      <ModalCell>
        {isLicensePlatesLoading && !hasLicensePlates ? (
          <CardWrapperEmpty>
            <Loader></Loader>
          </CardWrapperEmpty>
        ) : hasLicensePlates ? (
          <>
            <FlexCol rowGap={16}>
              <span>
                {licensePlates.map((licensePlate) => (
                  <LicensePlateItem
                    key={licensePlate}
                    licensePlate={licensePlate}
                  />
                ))}
              </span>
              {reachedLimit && (
                <P>{t('profile:LicencePlate:maxLicensePlateReached', {limit: MAX_LICENSE_PLATE_COUNT})}</P>
              )}
              <div>
                <Button
                  disabled={reachedLimit}
                  data-testid="organisms-profile-LicensePlatePage-Add-LicencePlate-button-fs"
                  button="tertiary"
                  onClick={addLicensePlate}
                  iconRight="plus"
                  size="small">
                  {buttonLabel}
                </Button>
              </div>
            </FlexCol>
          </>
        ) : (
          <CardWrapperEmpty>
            <Illustration
              illustration={illustration}
              size="100px"
              aria-label={ariaLabel}
            />
            <FlexCol rowGap={16}>
              <FlexCol
                rowGap={8}
                maxWidth={pxToRem(320)}>
                {emptyStateTitle && <b>{emptyStateTitle}</b>}
                <P>{emptyStateLabel}</P>
              </FlexCol>
              <Button
                data-testid="organisms-profile-LicensePlatePage-Add-LicencePlate-button-fs"
                button="primary"
                onClick={addLicensePlate}
                size="small">
                {buttonLabel}
              </Button>
            </FlexCol>
          </CardWrapperEmpty>
        )}
      </ModalCell>
    </StyledModalPage>
  );
};
