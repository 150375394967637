import {FeatureToggle, getEnabledFeatures} from '@lib/store';
import {useAppSelector} from './useAppSelector';

type FeatureName = keyof typeof FeatureToggle;

export const useFeatureToggle = () => {
  const enabledFeatures = useAppSelector(getEnabledFeatures);
  const features = Object.keys(FeatureToggle) as Array<FeatureName>;

  const featureObject: Partial<Record<FeatureName, boolean>> = features.reduce(
    (prevFeature, featureName) => ({
      ...prevFeature,
      [featureName]: enabledFeatures.includes(FeatureToggle[featureName]),
    }),
    {},
  );

  return featureObject;
};
