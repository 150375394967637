import {Button, Chip} from '@molecules';
import styled from 'styled-components';

export type StyledChipProps = {
  $hasSolidBackground: boolean;
};

export type StyledButtonProps = {
  $hasSolidBackground: boolean;
};

export const StyledChip = styled(Chip)<StyledChipProps>`
  background: ${({theme, $hasSolidBackground}) =>
    $hasSolidBackground ? theme.button['primary'].color : theme.button['tertiary'].background};
  box-shadow: ${({$hasSolidBackground, theme}) =>
    $hasSolidBackground ? `0px 2px 5px ${theme.button['tertiary'].background}` : undefined};
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  background: ${({theme}) => theme.button['primary'].color};
  box-shadow: ${({$hasSolidBackground, theme}) =>
    $hasSolidBackground ? `0px 2px 5px ${theme.button['tertiary'].background}` : undefined};

  @media (hover) {
    &:hover:not(:disabled) {
      background: ${({theme}) => theme.button['primary'].color};
    }
  }
`;
