import {FilterType, ModalPage, ModalPageBottom, Filter} from '@organisms';
import {useTranslation} from 'react-i18next';
import {useAppSelector, useDuration, useFilterGroup, useModal} from '@hooks';
import {useEffect, useMemo, useState} from 'react';
import {HaNFiltersGroupProps, HaNRoomsCardFiltersType} from './types';
import {DEFAULT_NEW_EVENT_DURATION as DURATION} from '@constants';
import {FlexCol} from '@quarks';
import {getIsOccupancyBasedHereAndNow} from '@lib/store';
import {getMeetingDurations, getFloorsByBuildingIdSorted, getRoomSeatFilters} from '@lib/store';

export const HaNFiltersGroup = ({
  onSelect,
  buildingId,
  selectedDuration,
  selectedFloorId,
  selectedSeatId,
}: HaNFiltersGroupProps) => {
  const {t} = useTranslation();
  const [localFilters, setLocalFilters] = useState(new Map<HaNRoomsCardFiltersType, string | undefined>());
  const {closeModal} = useModal();

  const {getFormattedDuration} = useDuration();
  const isOccupancyBased = useAppSelector(getIsOccupancyBasedHereAndNow);
  const {setFilters} = useFilterGroup();

  const shouldDisplayDuration = !isOccupancyBased;

  const durationsMapped = useAppSelector((state) => getMeetingDurations(state, 'bookRoomNow')).map((minute) => ({
    label: getFormattedDuration({number: minute, timeUnit: 'minutes'}),
    id: minute.toString(),
  }));

  const floorsMapped = useAppSelector(getFloorsByBuildingIdSorted(buildingId)).map((floor) => {
    return {label: floor.name, id: floor.id};
  });

  const seatsMapped = useAppSelector(getRoomSeatFilters).map((seat) => {
    return {label: `${seat.name} seats`, id: seat.id};
  });

  const filters: FilterType<HaNRoomsCardFiltersType>[] = useMemo(() => {
    const filtersArray: FilterType<HaNRoomsCardFiltersType>[] = [
      {
        label: t('screen:DurationSelection'),
        items: durationsMapped,
        selectedId: selectedDuration.toString(),
        name: 'duration',
        type: 'radio',
        isRequired: true,
        defaultValue: DURATION.toString(),
      },
      {
        label: t('Seats'),
        items: seatsMapped,
        selectedId: selectedSeatId,
        name: 'seat',
        type: 'radio',
        isRequired: false,
      },
      {
        label: t('Floor'),
        items: floorsMapped,
        selectedId: selectedFloorId,
        name: 'floor',
        type: 'radio',
        isRequired: false,
      },
    ];
    return shouldDisplayDuration ? filtersArray : filtersArray.splice(1, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, selectedDuration, selectedSeatId, selectedFloorId, shouldDisplayDuration]);

  useEffect(() => {
    setFilters(filters);
  }, [filters, setFilters]);

  useEffect(() => {
    const map = new Map();
    filters.forEach(({name, selectedId}) => map.set(name, selectedId));
    setLocalFilters(map);
  }, [filters]);

  const handleResetFilters = () => {
    setLocalFilters(
      filters.reduce<Map<HaNRoomsCardFiltersType, string | undefined>>((map, {name, isRequired, defaultValue}) => {
        map.set(name, isRequired && defaultValue ? defaultValue : undefined);
        return map;
      }, new Map()),
    );
  };

  const handleSubmitFilters = () => {
    onSelect(localFilters);
  };

  const handleSelect = (value: string, name: HaNRoomsCardFiltersType) => {
    const map = new Map(localFilters);
    map.set(name, value);
    setLocalFilters(map);
  };

  const hasChanges = filters.some(({name, selectedId}) => localFilters.get(name) !== selectedId);

  return (
    <ModalPage
      bottom={
        <ModalPageBottom
          buttonDisabled={!hasChanges}
          buttonLabel={t('translation:ApplyFilter')}
          cancelButtonLabel={t('translation:ClearFilter')}
          onCancel={() => {
            handleResetFilters();
          }}
          onClick={() => {
            handleSubmitFilters();
          }}
        />
      }
      onClose={closeModal}
      title={t('Filter')}>
      <FlexCol gap={64}>
        {filters.map(({items, label, name, type}) => (
          <Filter
            items={items}
            onSelect={(value) => handleSelect(value, name)}
            selectedId={localFilters.get(name)}
            title={label}
            type={type}
            key={name}
          />
        ))}
      </FlexCol>
    </ModalPage>
  );
};
