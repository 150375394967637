import {FlexCol, FlexRow, H4, P, Span} from '@quarks';
import {AddInviteesCard, ModalPageBottom} from '@organisms';
import {BookARoomHaNProps} from './types';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useCreateEvent, useDestructiveDialog, useFormatDate, useModal, useRoomImage} from '@hooks';
import {Chip, ModalCell, ModalCellButton, ModalCellItemIcon, ToggleCell} from '@molecules';
import {pickUsingStringAsIndex} from '@lib/utils';
import {useTheme} from 'styled-components';

import {pxToRem} from '@utils';
import {Icon, Textarea} from '@atoms';
import {useCallback, useEffect, useState} from 'react';
import {MeetingInviteeSearchResult, removeRoomFromAllRooms} from '@lib/store';
import {isSameDay, parseISO} from 'date-fns';
import {StyledModalPage} from './styles';

export const HaNBookARoom = ({room, endDateTime, startDateTime}: BookARoomHaNProps) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [title, setTitle] = useState<string>(room.displayName);
  const [message, setMessage] = useState<string | undefined>();
  const [attendees, setAttendees] = useState<MeetingInviteeSearchResult[]>([]);
  const [isOnlineMeeting, setIsOnlineMeeting] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const {destructiveDialog} = useDestructiveDialog();

  const {roomPictureHash, buildingId, email, capacity, floorName, buildingName, displayName} = room;
  const color = pickUsingStringAsIndex(email, theme.avatar.colors);
  const {removePages, closeModal, addPages, setOnCloseCallback} = useModal();
  const imageURL = useRoomImage(email || displayName, roomPictureHash, buildingId, 48);
  const subtitle = t('meeting:CreateMeetingRoomSummary', {
    count: capacity,
    floorName: floorName,
    buildingName: buildingName,
  });
  const start = useFormatDate(startDateTime, 'EEEE d MMMM HH:mm');
  const end = useFormatDate(endDateTime, 'HH:mm');

  const endDateFormat = isSameDay(parseISO(startDateTime), parseISO(endDateTime)) ? 'HH:mm' : 'dd MMMM HH:mm';

  const confirmationMessageStartDateTime = useFormatDate(startDateTime, 'dd MMMM HH:mm');
  const confirmationMessageEndDateTime = useFormatDate(endDateTime, endDateFormat);

  const handleOnSuccess = () => {
    // This timeout is here because of the Modal animation.
    // Closing modal will take some time before it closes
    // and in that timeframe an item (room) will be removed
    // from the array thus it will break the app.
    setTimeout(() => {
      dispatch(removeRoomFromAllRooms(room.id));
    }, 1000);
    closeModal();
  };

  const {createEvent, isCreatingEvent} = useCreateEvent(handleOnSuccess);

  const hasUnsavedChanges = title !== room.displayName || message || attendees.length > 0 || !isOnlineMeeting;

  const handleClose = useCallback(
    async (callback: () => void) => {
      if (!hasUnsavedChanges) {
        callback();
      } else {
        const confirmation = await destructiveDialog(
          t('meeting:DiscardEventChangesTitle'),
          t('meeting:DiscardEventChangesMessage'),
          'warning',
          t('meeting:DiscardEventChangesConfirm'),
        );
        if (confirmation) callback();
      }
    },
    [destructiveDialog, t, hasUnsavedChanges],
  );

  useEffect(() => {
    setOnCloseCallback(() => () => handleClose(closeModal));
  }, [setOnCloseCallback, handleClose, closeModal]);

  return (
    <StyledModalPage
      title={t('screen:BookRoomNow')}
      onBack={() => handleClose(() => removePages(1))}
      onClose={() => handleClose(closeModal)}
      bottom={
        <ModalPageBottom
          buttonLabel={t('meeting:CreateMeetingCreateEventButton')}
          buttonDisabled={!title || !startDateTime || !endDateTime}
          buttonLoading={isCreatingEvent}
          onClick={() =>
            createEvent(
              {
                title: title!,
                message: message ?? '',
                attendees: attendees.map((a) => a.email),
                startDateTime,
                endDateTime,
                isOnlineMeeting,
                rooms: [room.email],
                isAllDay: false,
                organizerTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                origin: 'BookRoomNow',
              },
              t('meeting:CreateMeetingSuccessMessage', {
                startDate: confirmationMessageStartDateTime,
                endDate: confirmationMessageEndDateTime,
              }),
            )
          }
        />
      }>
      <FlexCol
        as="form"
        background="var(--color-body)"
        gap={8}
        onSubmit={(e) => e.preventDefault()}>
        <ModalCell>
          <FlexCol gap={16}>
            <H4>{t('hereAndNow:BookRoomNowSectionTitle')}</H4>
            <FlexRow
              justifyContent="flex-start"
              gap={16}>
              <ModalCellItemIcon
                imageURL={imageURL}
                backgroundColor={color}
                icon="room"
              />
              <FlexCol justifyContent="center">
                <P fontWeight="bold">{displayName}</P>
                <P>{subtitle}</P>
              </FlexCol>
            </FlexRow>
            <FlexRow
              justifyContent="center"
              alignSelf="flex-start"
              gap={8}>
              <Icon
                icon="clock"
                size={pxToRem(20)}
                color={theme.icon.inactive}
              />
              <Span color={theme.text.color.subtitle}>
                {start} - {end}
              </Span>
            </FlexRow>
          </FlexCol>
        </ModalCell>
        <FlexCol gap={2}>
          <ModalCell fontWeight="bold">
            <Textarea
              maxLength={255}
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t('meeting:CreateMeetingTitlePlaceholder')}
              value={title}
            />
          </ModalCell>
          <ModalCell>
            <Textarea
              maxLength={600}
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t('meeting:CreateMeetingMessagePlaceholder')}
              value={message}
            />
          </ModalCell>
        </FlexCol>
        <FlexCol gap={2}>
          <ModalCellButton
            onClick={() =>
              addPages([
                <AddInviteesCard
                  invitees={attendees}
                  onClose={closeModal}
                  onSave={setAttendees}
                />,
              ])
            }>
            <H4>{t('Invitees')}</H4>
          </ModalCellButton>
          {attendees.length > 0 && (
            <ModalCell>
              <FlexRow
                columnGap={8}
                justifyContent="flex-start"
                flexWrap="wrap"
                rowGap={4}>
                {attendees.map((user) => (
                  <Chip
                    chip={user.id ? 'colored' : 'tertiary'}
                    email={user.email}
                    key={user.email}>
                    {user.name}
                  </Chip>
                ))}
              </FlexRow>
            </ModalCell>
          )}
        </FlexCol>
        <FlexCol gap={2}>
          <ModalCell>
            <H4>{t('meeting:OnlineEvent')}</H4>
          </ModalCell>
          <ModalCell>
            <ToggleCell
              label={t('meeting:CreateMeetingOnlineMeetingToggleLabel')}
              checked={isOnlineMeeting}
              name="isOnlineMeeting"
              onToggle={(e) => setIsOnlineMeeting(e.target.checked)}
            />
          </ModalCell>
        </FlexCol>
      </FlexCol>
    </StyledModalPage>
  );
};
