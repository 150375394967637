import {useTransition} from 'react-spring';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';

import FocusTrap from 'focus-trap-react';
import {useSidePanel} from '@hooks';

import {Div} from '@quarks';
import {IconButton} from '@molecules';
import {ModalBackground} from '@organisms';
import {StyledSidePanelLayout} from './styles';

const sidePanelRoot = document.getElementById('sidePanel-portal')!;

export const SidePanel = () => {
  const {sidePanelComponent, closeSidePanel, isSidePanelOpen} = useSidePanel();
  const {t} = useTranslation();
  const transition = useTransition(isSidePanelOpen, {
    from: {transform: 'translate3d(100%, 0, 0)'},
    enter: {transform: 'translate3d(0%, 0, 0)'},
    leave: {transform: 'translate3d(100%, 0, 0)'},
  });

  return createPortal(
    <>
      <ModalBackground
        clickable={true}
        hideOnSmallScreen={true}
        open={isSidePanelOpen}
      />
      {transition(
        (style, item) =>
          item && (
            <FocusTrap
              focusTrapOptions={{
                clickOutsideDeactivates: () => {
                  closeSidePanel();
                  return true;
                },
                escapeDeactivates: () => {
                  closeSidePanel();
                  return true;
                },
              }}>
              <StyledSidePanelLayout
                open={true}
                style={style}>
                <Div
                  position="absolute"
                  top="15px"
                  right="15px">
                  <IconButton
                    aria-label={t('translation:Close')}
                    icon="close"
                    iconButton="tertiary"
                    onClick={closeSidePanel}
                  />
                </Div>
                {sidePanelComponent}
              </StyledSidePanelLayout>
            </FocusTrap>
          ),
      )}
    </>,
    sidePanelRoot,
  );
};
