import {AreaDetailsType} from './types';
import {useTranslation} from 'react-i18next';
import {MapDetailsModalPage, MapPreviewCard, ModalPage} from '@organisms';
import {FlexCol, P} from '@quarks';

import {
  getAreaById,
  getFloorById,
  getSelectedBuildingName,
  getConnectedUsersByDate,
  getSelectedBuildingNodeId,
  getFloorHasMap,
} from '@lib/store';

import {getNodeInformationByNodeIdInEditMode} from '@lib/store';

import {useAppSelector, useModal} from '@hooks';
import {CapacityIndicator, ConnectionList, ModalCellItemIcon} from '@molecules';
import {parseISO} from 'date-fns';
import {IconNames} from 'mapiq-atoms';
import {Date, TwoColumnCell} from '@atoms';
import {pxToRem} from '@utils';

export const AreaDetailsModalPage = ({date, workspace, inviteeCount}: AreaDetailsType) => {
  const {hasBookableDesks, areaId = '', floorId = ''} = workspace;
  const {t} = useTranslation();
  const {addPages, closeModal, removePages} = useModal();

  const nodeId = areaId || floorId;
  const icon: IconNames = hasBookableDesks ? 'desk' : areaId ? 'area' : 'floor';

  const groupSize = inviteeCount + 1;
  const area = useAppSelector((state) => areaId && getAreaById(state, areaId)) || null;
  const floor = useAppSelector((state) => floorId && getFloorById(state, floorId)) || null;
  const selectedBuildingId = useAppSelector(getSelectedBuildingNodeId);
  const buildingName = useAppSelector(getSelectedBuildingName);
  const connections = useAppSelector((state) => getConnectedUsersByDate(state, {date, nodeId}));
  const floorHasMap = useAppSelector(getFloorHasMap(floorId));

  const {occupation, capacity} = useAppSelector((state) =>
    getNodeInformationByNodeIdInEditMode(state, {
      date: parseISO(date),
      nodeId,
      inviteeCount,
    }),
  );

  const title = area?.name || floor?.name || buildingName;
  const getSubtitle = () => {
    const _subtitle = area ? `${buildingName}, ${floor?.name}` : floor ? buildingName : '';

    if (hasBookableDesks) {
      return `${_subtitle}, ${t('workplace:DeskWillBeAssigned', {
        count: groupSize,
      }).toLowerCase()}`;
    }

    const selectAvailableDesk = t('workplace:AnyAvailableDesk', {count: groupSize});
    return `${_subtitle}, ${selectAvailableDesk.toLowerCase()}`;
  };

  return (
    <ModalPage
      onBack={() => removePages(1)}
      onClose={closeModal}
      title={t('screen:DeskMap')}>
      <FlexCol gap={16}>
        {floorHasMap && (
          <MapPreviewCard
            buildingId={selectedBuildingId}
            floorId={floorId}
            areaId={areaId}
            groupSize={groupSize}
            onClick={() => {
              addPages([
                <MapDetailsModalPage
                  date={date}
                  areaId={areaId}
                  buildingId={selectedBuildingId}
                  floorId={floorId}
                  groupSize={groupSize}
                />,
              ]);
            }}
          />
        )}
        <TwoColumnCell
          padding="small"
          alignItems="flex-start"
          separator={false}
          left={<ModalCellItemIcon icon={icon} />}
          right={
            <FlexCol minHeight={pxToRem(48)}>
              <P fontWeight="bold">{title}</P>
              <P paddingBottom={8}>
                {getSubtitle()}
                <br />
                <Date
                  date={date}
                  format="EEEE d MMMM"
                />
              </P>
              <CapacityIndicator
                occupation={occupation}
                capacity={capacity}
              />
            </FlexCol>
          }
        />

        <ConnectionList
          connections={connections}
          separator={true}
          title={t('connection:ConnectionsWorkingHere')}
        />
      </FlexCol>
    </ModalPage>
  );
};
