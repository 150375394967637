import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ParkingSelectionCardProps} from './types';

import {
  getFilteredAndSortedParkingLots,
  getParkingNodesGroupedByAvailability,
  getParkingReservationByDate,
  removeParkingSpot,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useDialog, useModal} from '@hooks';

import {FlexCol, FlexRow, Span, Subtitle} from '@quarks';
import {ChipFilter, ChipFilterItem} from '@molecules';
import {ModalPage, ModalPageBottom, FilterCard, ParkingSelectionCell} from '@organisms';

export const ParkingSelectionCard = ({date}: ParkingSelectionCardProps) => {
  const {t} = useTranslation();
  const {addPages, closeModal} = useModal();
  const {destructiveDialog} = useDialog();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [filterByParkingLotId, setFilterByParkingLotId] = useState<string | null>();

  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));
  const parkingNodesGroupedByAvailability = useAppSelector((state) =>
    getParkingNodesGroupedByAvailability(state, {date, filterByParkingLotId: filterByParkingLotId || undefined}),
  );
  const allParkingLots = useAppSelector((state) => getFilteredAndSortedParkingLots(state)).map((parkingLot) => ({
    label: parkingLot.name,
    id: parkingLot.id,
  }));

  const handleShowDeleteParkingDialog = async () => {
    if (!parkingReservation) return;

    const confirmation = await destructiveDialog(
      t('parking:DeleteParkingAlertTitle'),
      t('parking:DeleteParkingAlertMessage'),
      'car',
    );

    if (confirmation) {
      dispatch(withAsyncThunkErrorHandling(() => removeParkingSpot(parkingReservation.id)));
      closeModal();
    }
  };

  const handleOnFilterClick = () => {
    addPages([
      <FilterCard
        onClose={closeModal}
        title={t('screen:FilterParkingLot')}
        canResetState={true}
        sections={{
          parkingLot: {
            type: 'radio',
            items: allParkingLots,
          },
        }}
        onSelect={({parkingLot}) => setFilterByParkingLotId(parkingLot)}
        initialSelection={filterByParkingLotId ? [filterByParkingLotId] : []}
      />,
    ]);
  };

  const chipData = {
    ...allParkingLots.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: {
          id: curr.id,
          label: curr.label || t('meeting:FilterRoomsFloor'),
          onDelete: () => setFilterByParkingLotId(null),
        },
      }),
      {} as Record<string, ChipFilterItem>,
    ),
  };

  return (
    <ModalPage
      title={t('parking:BookParkingSpot')}
      onClose={closeModal}
      bottom={
        <ModalPageBottom
          destructive={true}
          buttonLabel={t('workplace:DeleteBooking')}
          buttonDisabled={!parkingReservation}
          onClick={handleShowDeleteParkingDialog}
          buttonLoading={loading}
        />
      }>
      <FlexCol gap={16}>
        <FlexRow
          justifyContent="flex-start"
          gap={8}>
          <ChipFilter
            chips={[filterByParkingLotId].map((id) => chipData[id || ''])}
            onClick={handleOnFilterClick}
            buttonLabel={t('workplace:Filter')}
          />
        </FlexRow>
        {parkingNodesGroupedByAvailability.map((availabilityGroup) => (
          <FlexCol
            gap={8}
            key={availabilityGroup.key}>
            {availabilityGroup.key === 'Unavailable' && (
              <Span fontWeight="bold">{t('workplace:WorkspaceGroupUnavailable')}</Span>
            )}
            {availabilityGroup.data.map((parkingNode, index) => (
              <ParkingSelectionCell
                key={`parkingNode-${availabilityGroup.key}-${index}`}
                parkingNode={parkingNode}
                date={date}
                loading={loading}
                setLoading={(value) => setLoading(value)}
              />
            ))}
          </FlexCol>
        ))}
        <Subtitle fontSize="sm">{t('parking:NoParkingInTheList')}</Subtitle>
      </FlexCol>
    </ModalPage>
  );
};
