import {KeyboardEvent, MouseEvent} from 'react';
import {MeetingDetailsCardProps} from './types';

import {
  deleteMeeting,
  getIsDeleting,
  getUserDirectoryObjectId,
  getDefaultBuildingId,
  MeetingRoom,
  updateMeeting,
  withAsyncThunkErrorHandling,
  getMeetingRoomsByMeetingId,
  ExtrasTypes,
} from '@lib/store';
import {format, parseISO} from 'date-fns';

import {useAppDispatch, useAppSelector, useDialog, useModal} from '@hooks';
import {
  CreateEventManualRoomSelectionCard,
  MeetingDetailsCardDateTime,
  MeetingDetailsCardMeetingLink,
  MeetingDetailsCardMessage,
  MeetingDetailsCardOrganizer,
  MeetingDetailsCardRSVP,
  MeetingDetailsCardLocationsList,
  ExtrasTile,
  ModalPage,
  ModalPageBottom,
} from '@organisms';
import {Icon, ThreeColumnCell} from '@atoms';
import {useTranslation} from 'react-i18next';
import {pxToRem} from '@utils';
import {Div, FlexCol, H1, P} from '@quarks';
import {StyledAnimatedButton, StyledWrapper} from './styles';
import toast from 'react-hot-toast';

export const MeetingDetailsCard = ({date, meeting}: MeetingDetailsCardProps) => {
  const {closeModal, addPages, removePages, openModal} = useModal();
  const {destructiveDialog} = useDialog();
  const {t} = useTranslation();
  const defaultBuildingId = useAppSelector(getDefaultBuildingId);

  const dispatch = useAppDispatch();

  const {
    attendees,
    id: meetingId,
    isAllDay,
    message: messageAsHTML,
    messagePreview,
    startDateTime,
    onlineMeetingUrl,
    endDateTime,
    meetingRooms,
    title,
  } = meeting;

  const currentMeetingRooms = useAppSelector((state) => getMeetingRoomsByMeetingId(state, meetingId));
  const isLoading = useAppSelector(getIsDeleting);
  const currentUserDirectoryObjectId = useAppSelector(getUserDirectoryObjectId);
  const currentUser = attendees.find((attendee) => attendee.directoryObjectId === currentUserDirectoryObjectId)!;

  const organizer = attendees.find((attendee) => attendee.responseStatus === 'Organizer')!;

  const handleDeleteMeeting = async () => {
    const confirmation = await destructiveDialog(
      t('meeting:DeleteEventDialogTitle'),
      t('meeting:DeleteEventDialogText'),
      'warning',
    );

    if (confirmation) {
      const {success} = await dispatch(withAsyncThunkErrorHandling(() => deleteMeeting(meeting)));

      if (success) closeModal();
    }
  };

  const handleRoomSelect = async (value: MeetingRoom) => {
    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() =>
        updateMeeting({
          origin: 'AddRoomToExistingMeeting',
          meetingId: meetingId,
          systemTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          rooms: [...currentMeetingRooms.map((room) => room.email), value.email],
        }),
      ),
    );
    if (success) {
      removePages(1);
      toast.success(t('meeting:AddMeetingRoomToMeetingSuccessMessage', {roomName: value.displayName}));
    }
  };

  const openRoomSelectionModal = (e: KeyboardEvent<HTMLElement> | MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    addPages([
      <CreateEventManualRoomSelectionCard
        defaultBuildingId={defaultBuildingId}
        defaultRooms={[]}
        endDateTime={endDateTime}
        onClose={closeModal}
        onSelect={handleRoomSelect}
        selectedRooms={meetingRooms}
        startDateTime={startDateTime}
      />,
    ]);
    openModal();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      openRoomSelectionModal(e);
    }
  };

  return (
    <ModalPage
      bottom={
        currentUser?.responseStatus === 'Organizer' && (
          <ModalPageBottom
            buttonType="secondary destructive"
            buttonLabel={t('meeting:DeleteEventButtonLabel')}
            buttonLoading={isLoading}
            onClick={handleDeleteMeeting}
          />
        )
      }
      title={format(parseISO(date), 'dd MMMM')}
      onClose={closeModal}>
      <FlexCol
        data-testid="oraganisms-eventDetails-MeetingDetailsCard"
        as="section"
        gap={16}>
        <H1>{title}</H1>

        <FlexCol gap={16}>
          <MeetingDetailsCardMessage
            messageAsHTML={messageAsHTML}
            messagePreview={messagePreview}
          />
          <MeetingDetailsCardDateTime
            endDateTime={endDateTime}
            isAllDay={isAllDay}
            startDateTime={startDateTime}
          />
          <MeetingDetailsCardOrganizer
            currentUserResponseStatus={currentUser?.responseStatus}
            organizer={organizer}
          />
          <MeetingDetailsCardRSVP currentUserResponseStatus={currentUser?.responseStatus} />
        </FlexCol>
        <Div>
          <MeetingDetailsCardMeetingLink onlineMeetingUrl={onlineMeetingUrl} />

          {currentUser?.responseStatus === 'Organizer' && (
            <StyledWrapper>
              <StyledAnimatedButton
                role="button"
                data-testid="organisms-eventDetails-MeetingDetailsCard_add-room"
                onKeyDown={(e) => handleKeyDown(e)}
                tabIndex={0}
                onClick={openRoomSelectionModal}>
                <ThreeColumnCell
                  left={
                    <Icon
                      icon="location"
                      size={pxToRem(24)}
                    />
                  }
                  middle={<P>{t('screen:AdHocRoomSelection')}</P>}
                  padding="small"
                  right={
                    <Icon
                      icon="caretRight"
                      size={pxToRem(24)}
                    />
                  }
                  separator={false}
                />
              </StyledAnimatedButton>
            </StyledWrapper>
          )}

          <ExtrasTile extrasType={ExtrasTypes.HYBRID_MEETINGS} />
        </Div>

        <MeetingDetailsCardLocationsList
          attendees={attendees}
          currentUser={currentUser}
          date={date}
          meetingId={meetingId}
        />
      </FlexCol>
    </ModalPage>
  );
};
