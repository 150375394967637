import {useEffect, useState} from 'react';
import mapValues from 'lodash/mapValues';
import transform from 'lodash/transform';
import {useGlobalImageCache} from './useGlobalImageCache';
import {getImage, ImageApiVersion} from '@utils';
import {apiBaseURL} from '@infrastructure';

// Note: if the size queries in our API were consistent we could add size as a parameter and append
//       it to the endpoint here. This would allow us to return cached images that are larger to the
//       requested size. E.g. if I first request a 256x256 avatar and then the same avatar at 64x64,
//       we can skip a network request and return the oversized image blob.

export const useImages = (
  token: string,
  keyedEndpoints: Record<string, string> | undefined = {},
  apiVersion: ImageApiVersion = '1.0',
) => {
  const {getGlobalImageCacheUrl} = useGlobalImageCache();

  const _generatePromises = usePromisesGenerator({token, apiVersion});
  const keyedSources = generateKeyedSources(keyedEndpoints);

  const [imageURLs, setImageURLs] = useState(mapValues(keyedSources, (cacheKey) => getGlobalImageCacheUrl(cacheKey)));

  useEffect(() => {
    Promise.all(_generatePromises(keyedSources)).then((keyedBlobUrls) => {
      if (!keyedBlobUrls.length) return;

      const keyedBlobUrlEntries = keyedBlobUrls.flatMap((keyedBlobUrlObj) => Object.entries(keyedBlobUrlObj));

      const newKeyedImageUrls = keyedBlobUrlEntries.reduce((acc, [_key, _blobURL]) => {
        return _blobURL ? {...acc, [_key]: _blobURL} : {...acc};
      }, {});

      setImageURLs((prev) => ({
        ...prev,
        ...newKeyedImageUrls,
      }));
    });
  }, [_generatePromises, keyedSources]);

  return imageURLs;
};

const generateKeyedSources = (keyedEndpoints: Record<string, string>) => {
  return transform(
    keyedEndpoints,
    (result: Record<string, string>, endpoint, key) => {
      if (endpoint) {
        result[key] = `${apiBaseURL}/${endpoint}`;
      }
    },
    {},
  );
};

type RunningPromise = Promise<Record<string, string>>;

const usePromisesGenerator = ({token, apiVersion}: {token: string; apiVersion: ImageApiVersion}) => {
  const {getGlobalImageCachePromise, setGlobalImageCachePromise} = useGlobalImageCache();

  return (keyedSources: Record<string, string>) =>
    transform(
      keyedSources,
      (result: RunningPromise[], source, key) => {
        const cacheKey = source;

        if (cacheKey && token && !getGlobalImageCachePromise(cacheKey)) {
          result.push(
            setGlobalImageCachePromise(cacheKey, getImage(source, token, apiVersion)).then(
              (blobURL: string | undefined) => {
                return {
                  [key]: blobURL ?? '',
                };
              },
            ),
          );
        }
      },
      [],
    );
};
