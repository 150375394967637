import styled from 'styled-components';
import {DuotonePathProps, PathProps, StyledIconProps} from './types';

export const StyledIcon = styled.svg<StyledIconProps>`
  fill: none;
  height: ${({$size}) => $size || '3rem'};
  stroke-linecap: round;
  stroke-linejoin: initial;
  width: ${({$size}) => $size || '3rem'};
`;

export const Path = styled.path<PathProps>`
  fill: ${({$duotone, theme}) => $duotone && theme.icon.duotone.color};
  stroke: ${({$color, theme}) => $color || theme.text.color.body};
  stroke-width: ${({$weight}) => ($weight === 'regular' ? '2px' : $weight === 'light' ? '1.5px' : '1px')};
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const FilledPath = styled.path<PathProps>`
  fill: ${({$color, theme}) => $color || theme.text.color.body};
  stroke: ${({$color, theme}) => $color || theme.text.color.body};
  stroke-width: ${({$weight}) => ($weight === 'regular' ? '1px' : $weight === 'light' ? '.5px' : '0px')};
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const DuotonePath = styled.path<DuotonePathProps>`
  fill: ${({$duotone, theme}) => $duotone && theme.icon.duotone.color};
  stroke: ${({$color}) => $color || 'none'};
  stroke-width: ${({$weight}) => ($weight === 'regular' ? '2px' : $weight === 'light' ? '1.5px' : '1px')};
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const Rect = styled.rect<{$color?: string}>`
  fill: ${({$color}) => $color || 'none'};
`;
