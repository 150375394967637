import {FlexCol, P, Span} from '@quarks';
import {RoomListWrapper} from './styles';
import {Illustration, Loader} from '@atoms';
import {useTranslation} from 'react-i18next';
import {useAppSelector, useScrollParentForChild} from '@hooks';
import {HaNRoomCell} from '../HaNRoomCell';
import {MeetingRoom, getAllMeetingRoomLoadingStatus} from '@lib/store';

export type HaNRoomListProps = {
  rooms: MeetingRoom[];
  onClick: (room: MeetingRoom) => void;
  onHover: (spotlightId: string | null) => void;
  startDateTime: string;
  endDateTime: string;
  selectedRoom: MeetingRoom | null;
};

/**
 * Lists available rooms in Here And Now
 */

export const HaNRoomList = ({rooms, onClick, onHover, startDateTime, endDateTime, selectedRoom}: HaNRoomListProps) => {
  const {t} = useTranslation();
  const meetingRoomLoadingStatus = useAppSelector(getAllMeetingRoomLoadingStatus);
  const loadingMeetingRooms = meetingRoomLoadingStatus === 'Loading' || meetingRoomLoadingStatus === 'NotLoaded';
  const indexOfElement = selectedRoom ? rooms.indexOf(selectedRoom) : undefined;

  const [scrollRef, childWrapperRef] = useScrollParentForChild(indexOfElement);

  return (
    <RoomListWrapper ref={scrollRef}>
      {loadingMeetingRooms ? (
        <FlexCol
          flex="auto"
          alignItems="center"
          justifyContent="center">
          <Loader />
        </FlexCol>
      ) : rooms.length === 0 ? (
        <FlexCol
          alignItems="center"
          justifyContent="center"
          flex="auto"
          padding={32}
          gap={16}>
          <Illustration illustration="noResults" />
          <P
            color="currentColor"
            textAlign="center">
            {t('hereAndNow:NoRoomsAvailable')}
          </P>
        </FlexCol>
      ) : (
        <FlexCol
          rowGap={8}
          ref={childWrapperRef}
          padding={8}>
          {rooms.map((room, index) => (
            <Span
              key={room.id}
              onMouseEnter={() => onHover(room.id)}
              onMouseLeave={() => onHover(null)}>
              <HaNRoomCell
                room={room}
                index={index}
                onClick={onClick}
                startDateTime={startDateTime}
                endDateTime={endDateTime}
              />
            </Span>
          ))}
        </FlexCol>
      )}
    </RoomListWrapper>
  );
};
