import {useParams} from 'react-router-dom';
import {HaNRoomFilters, HaNWorkspacesFilters} from '@organisms';
import {HaNRoomFiltersProps} from '../HaNRoomFilters';
import {useAppSelector} from '@hooks';
import {getIsWorkspacesOnHereAndNowEnabled} from '@lib/store';

export type HaNFiltersProps = HaNRoomFiltersProps & {};

export const HaNFilters = ({
  areFiltersTransparent,
  buildingId,
  duration,
  setDuration,
  floorId,
  setFloorId,
  seatFilterId,
  setSeatFilterId,
  onRemoveFilter,
  onApplyFiltersCallback,
}: HaNFiltersProps) => {
  const {tab} = useParams();
  const isWorkspacesOnHereAndNowEnabled = useAppSelector(getIsWorkspacesOnHereAndNowEnabled);
  return tab === 'rooms' || (!isWorkspacesOnHereAndNowEnabled && !tab) ? (
    <HaNRoomFilters
      areFiltersTransparent={areFiltersTransparent}
      buildingId={buildingId}
      duration={duration}
      setDuration={setDuration}
      floorId={floorId}
      setFloorId={setFloorId}
      seatFilterId={seatFilterId}
      setSeatFilterId={setSeatFilterId}
      onRemoveFilter={onRemoveFilter}
      onApplyFiltersCallback={onApplyFiltersCallback}
    />
  ) : (
    <HaNWorkspacesFilters areFiltersTransparent={areFiltersTransparent} />
  );
};
