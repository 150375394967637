import {Icon, ThreeColumnCell} from '@atoms';
import {useAppDispatch, useAppSelector} from '@hooks';
import {getBookingOverviewExportData, getBookingRetentionPeriod} from '@lib/store';
import {ClickableCellWrapper} from '@molecules';
import {FlexCol, P, Subtitle} from '@quarks';
import {generateTestIdCoreString, pxToRem} from '@utils';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {useRef} from 'react';
import {toast} from 'react-hot-toast';
import {subDays, startOfToday} from 'date-fns';

export const ExportsCell = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const anchorRef = useRef<HTMLAnchorElement>(null);
  const TEST_ID = generateTestIdCoreString(import.meta.url);

  const bookingRetentionPeriod = useAppSelector(getBookingRetentionPeriod);
  const today = startOfToday();
  // hard cap at 365 days in the past. We should not export more data.
  const past = subDays(today, Math.min(365, bookingRetentionPeriod));

  const exportBookingOverview = async () => {
    try {
      if (!anchorRef.current) {
        throw new Error('No anchor element found');
      }

      const {data, filename} = await dispatch(
        getBookingOverviewExportData({from: past.toISOString(), to: today.toISOString()}),
      ).unwrap();

      const blob = new Blob([data]);
      const blobURL = URL.createObjectURL(blob);

      anchorRef.current.setAttribute('download', filename || 'booking-history');
      anchorRef.current.setAttribute('href', blobURL);
      anchorRef.current.click();
    } catch (e) {
      console.error('Error: ', e);
      toast.error(t('profile:ExportsError'));
    }
  };

  return (
    <FlexCol background={useTheme().background.white}>
      <ClickableCellWrapper
        data-testid={`${TEST_ID}_booking-overview-export-button`}
        onClick={exportBookingOverview}>
        <ThreeColumnCell
          padding="small"
          alignItems="flex-start"
          truncate={false}
          left={
            <Icon
              icon="clockCounterClockwise"
              size={pxToRem(24)}
            />
          }
          middle={
            <>
              <P>{t('profile:ExportsTitle')}</P>
              <Subtitle
                data-testid={`${TEST_ID}_booking-overview-subtitle`}
                lineHeight="body short">
                {t('profile:ExportsSubtitle')}
              </Subtitle>
            </>
          }
          right={
            <Icon
              icon="download"
              size={pxToRem(24)}
            />
          }
        />
      </ClickableCellWrapper>

      <a
        download
        data-testid={`${TEST_ID}_booking-overview-hidden-anchor`}
        hidden
        ref={anchorRef}
        target="_blank"
      />
    </FlexCol>
  );
};
