import {HighlightedTextProps} from './types';

import {Link, Span} from '@quarks';

export const HighlightedText = ({text, highlight, highlightTo, onClick}: HighlightedTextProps) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <>
      {parts.map((part, i) => {
        const isHighlight = part.toLowerCase() === highlight.toLowerCase();

        return isHighlight && highlightTo ? (
          <Link
            onClick={onClick}
            to={highlightTo}
            key={i}
            fontWeight={isHighlight ? 'bold' : 'regular'}>
            {part}
          </Link>
        ) : (
          <Span
            key={i}
            fontWeight={isHighlight ? 'bold' : 'regular'}>
            {part}
          </Span>
        );
      })}
    </>
  );
};
