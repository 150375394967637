import {FlexCol, Span} from '@quarks';
import {truncateString} from '@utils';
import styled from 'styled-components';

export const StyledText = styled(Span)`
  ${truncateString}
  flex-grow: 1;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  pointer-events: none;
`;

export const StyledContainer = styled.div`
  width: 100%;

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 4px;
  }
`;

export const StyledFlexCol = styled(FlexCol)`
  width: 100%;
`;

export const StyledInputLabel = styled.label`
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  width: 100%;
`;
