import {Div} from '@quarks';
import styled from 'styled-components';
import {SensorIcon} from '../../../../../../../submodules/map/logic/iconForSensorData';

export const StyledContainer = styled(Div)<{$iconType: SensorIcon | 'desk'}>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${({$iconType, theme}) =>
    $iconType === 'full'
      ? theme.occupancy.red
      : $iconType === 'busy'
      ? theme.occupancy.orange
      : $iconType === 'lively'
      ? theme.occupancy.yellow
      : $iconType === 'quiet'
      ? theme.occupancy.green
      : $iconType === 'desk'
      ? theme.card.beige500.background
      : theme.occupancy.sensorAway};
`;
