import {CalendarWorkspaceTileProps} from './types';

import {
  getIsWorkdayLoading,
  getIsWorkspaceReservationButtonEnabled,
  getWorkspaceReservationByDate,
  trackEvent,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {parseISO} from 'date-fns';

import {SquareButton} from '@molecules';
import {BookWorkspaceCard, CalendarWorkspaceTileBookingInfo, BookingDetailsCard} from '@organisms';

export const CalendarWorkspaceTile = ({date}: CalendarWorkspaceTileProps) => {
  const dispatch = useAppDispatch();

  const {addPages, openModal} = useModal();
  const dateAsDate = parseISO(date);
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, dateAsDate));
  const workDayPending = useAppSelector(getIsWorkdayLoading);
  // TODO: add this && case to the UI selector
  const isEnabled =
    useAppSelector((state) => getIsWorkspaceReservationButtonEnabled(state, dateAsDate)) && !workDayPending;

  const isGroupBooking = !!workspaceReservation?.groupReservationId;

  const handleOnClick = () => {
    if (workspaceReservation) {
      addPages([
        <BookingDetailsCard
          date={date}
          workspaceReservation={workspaceReservation}
        />,
      ]);
      openModal();

      dispatch(
        trackEvent('CalendarOverview_BookingDetails__Open', {
          date,
          isGroupBooking,
        }),
      );
    } else {
      if (!isEnabled) return;

      addPages([<BookWorkspaceCard date={date} />]);
      openModal();
    }
  };

  const getIconName = () => {
    if (!workspaceReservation || workspaceReservation.deskId) {
      return 'desk';
    }

    if (workspaceReservation.areaId) {
      return 'area';
    }

    if (workspaceReservation.floorId) {
      return 'floor';
    }

    return 'office';
  };

  return (
    <SquareButton
      iconLeft={getIconName()}
      onClick={handleOnClick}
      iconRight="caretRight"
      disabled={!isEnabled && !workspaceReservation}
      squareButton="energizingYellow">
      <CalendarWorkspaceTileBookingInfo date={date} />
    </SquareButton>
  );
};
