import {useTranslation} from 'react-i18next';

import {getFloorById, getIsOccupancyBasedHereAndNow, getRoomSeatFilterById} from '@lib/store';
import {useAppSelector, useModal, useDuration} from '@hooks';

import {HaNFiltersGroup} from '@organisms';
import {StyledButton, StyledChip} from './styles';
import {StyledSwiperHorizontal} from '../HaNRoomListHorizontal/styles';
import {Dispatch, SetStateAction} from 'react';

export type HaNRoomFiltersProps = {
  areFiltersTransparent: boolean;
  buildingId: string;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  floorId: string | undefined;
  setFloorId: Dispatch<SetStateAction<string | undefined>>;
  seatFilterId: string | undefined;
  setSeatFilterId: Dispatch<SetStateAction<string | undefined>>;

  onApplyFiltersCallback?: () => void;
  onRemoveFilter?: () => void;
};

export type HaNRoomFiltersType = 'floor' | 'duration' | 'seat';

export const HaNRoomFilters = ({
  buildingId,
  duration,
  setDuration,
  floorId,
  setFloorId,
  seatFilterId,
  onApplyFiltersCallback,
  onRemoveFilter,
  areFiltersTransparent,
  setSeatFilterId,
}: HaNRoomFiltersProps) => {
  const {t} = useTranslation();
  const {addPages, closeModal, openModal} = useModal();
  const {getFormattedDuration} = useDuration();
  const isOccupancyBased = useAppSelector(getIsOccupancyBasedHereAndNow);

  const durationLabel = getFormattedDuration({number: duration, timeUnit: 'minutes'});

  const {name: floorLabel = t('meeting:FilterRoomsFloor')} =
    useAppSelector((state) => getFloorById(state, floorId ?? '')) ?? {};

  const {name: activeFilterSeats} = useAppSelector(getRoomSeatFilterById(seatFilterId ?? '')) ?? {};

  const seatLabel = t('meeting:SeatFilterLabel', {rangeOfSeats: activeFilterSeats});

  const applyFilters = (value: Map<HaNRoomFiltersType, string | undefined>) => {
    closeModal();
    setFloorId(value.get('floor'));
    setSeatFilterId(value.get('seat'));
    setDuration(Number(value.get('duration') ?? duration));
    onApplyFiltersCallback && onApplyFiltersCallback();
  };

  return (
    <StyledSwiperHorizontal>
      <StyledButton
        $hasSolidBackground={!areFiltersTransparent}
        size="small"
        button="secondary"
        iconRight="filter"
        onClick={() => {
          addPages([
            <HaNFiltersGroup
              onSelect={applyFilters}
              buildingId={buildingId}
              selectedDuration={duration}
              selectedFloorId={floorId}
              selectedSeatId={seatFilterId}
            />,
          ]);
          openModal();
        }}>
        {t('workplace:Filter')}
      </StyledButton>
      {!isOccupancyBased && (
        <StyledChip
          $hasSolidBackground={!areFiltersTransparent}
          chip={'tertiary'}
          square={true}>
          {durationLabel}
        </StyledChip>
      )}
      {seatFilterId && (
        <StyledChip
          $hasSolidBackground={!areFiltersTransparent}
          chip={'tertiary'}
          onDelete={() => {
            setSeatFilterId(undefined);
            onRemoveFilter?.();
          }}
          square={true}>
          {seatLabel}
        </StyledChip>
      )}
      {floorId && (
        <StyledChip
          $hasSolidBackground={!areFiltersTransparent}
          chip={'tertiary'}
          onDelete={() => {
            setFloorId(undefined);
            onRemoveFilter?.();
          }}
          square={true}>
          {floorLabel}
        </StyledChip>
      )}
    </StyledSwiperHorizontal>
  );
};
